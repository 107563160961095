import { useState, useEffect } from "react";
import {
  List,
  Datagrid,
  TextField,
  DateField,
  ReferenceField,
  NumberField,
  Filter,
  Pagination,
  ReferenceInput,
  AutocompleteInput,
  BooleanField,
  useRecordContext,
  BooleanInput,
  EditBase,
  SimpleForm,
  Toolbar,
  SaveButton,
  DateInput,
  useListContext,
} from "react-admin";
import { Typography, Grid, Card, CardContent, Button } from "@material-ui/core";
import { unit } from "~/helpers/util";
import { app } from "~/App";
import { getFirestore, collection, doc, getDoc } from "firebase/firestore";
import { encrytion } from "~/helpers/util";

const InvoiceShow = props => {
  const record = useRecordContext();
  const [state, setState] = useState({
    invoicePrice: 0,
    accountLoading: true,
    account: null,
  });

  const calcInvoicePrice = record => {
    const calcPrice =
      record.salePrice - unit.percentUnit(13.3, record.salePrice); // 13.3% 뺀 금액 계산
    const invoicePrice = unit.priceCutting(calcPrice, "F", 100); // 100원 단위 이하 버림
    setState(prev => ({ ...prev, invoicePrice }));
  };

  const getMentorAccount = async record => {
    try {
      console.log(record.uid);
      const firestore = getFirestore(app[0]);
      const mentorAccount = await getDoc(
        doc(collection(firestore, "mentorAccount"), record.uid)
      );
      if (!mentorAccount.exists()) {
        window.alert("멘토 계좌 정보를 찾을 수 없습니다.");
        return;
      }
      const mentorAccountData = mentorAccount.data();
      setState(prev => ({ ...prev, account: mentorAccountData }));
    } catch (e) {
      console.log("e: ", e?.message || e);
    } finally {
      setState(prev => ({ ...prev, accountLoading: false }));
    }
  };

  useEffect(() => {
    if (record) {
      calcInvoicePrice(record);
      getMentorAccount(record);
    }
    return () => {};
  }, [record]);

  if (!record) return null;
  return (
    <Card style={{ maxWidth: 600, margin: "auto" }}>
      <CardContent>
        <EditBase {...props}>
          <SimpleForm sx={{ pt: 0, pb: 0 }} toolbar={<InvoiceToolbar />}>
            <Grid container>
              {state.accountLoading && (
                <Grid item xs={12} sm={12}>
                  <Typography component="p" variant="subtitle2" color="primary">
                    멘토 계좌 정보 불러오는 중...
                  </Typography>
                </Grid>
              )}
              {!state.accountLoading && !state.account && (
                <Grid item xs={12} sm={12}>
                  <Typography component="p" variant="subtitle2" color="primary">
                    멘토 계좌 정보를 가져올 수 없습니다.
                  </Typography>
                </Grid>
              )}
              {!state.accountLoading && state.account && (
                <Grid item xs={12} sm={12}>
                  <Typography component="p" variant="subtitle1" color="primary">
                    예금주:{" "}
                    <Typography
                      component="span"
                      variant="h6"
                      color="primary"
                      style={{ fontWeight: 700 }}
                    >
                      {encrytion.decryptionText(state?.account?.owner || "")}
                    </Typography>
                  </Typography>
                  <Typography component="p" variant="subtitle1" color="primary">
                    은행명:{" "}
                    <Typography
                      component="span"
                      variant="h6"
                      color="primary"
                      style={{ fontWeight: 700 }}
                    >
                      {encrytion.decryptionText(state?.account?.bankName || "")}
                    </Typography>
                  </Typography>
                  <Typography component="p" variant="subtitle1" color="primary">
                    계좌번호:{" "}
                    <Typography
                      component="span"
                      variant="h6"
                      color="primary"
                      style={{ fontWeight: 700 }}
                    >
                      {encrytion.decryptionText(
                        state?.account?.bankNumber || ""
                      )}
                    </Typography>
                  </Typography>
                </Grid>
              )}
            </Grid>
            <Grid container style={{ paddingTop: 5, paddingBottom: 5 }}>
              <Grid item xs={12} sm={12}>
                <Typography component="p" variant="h6" color="primary">
                  결제금액:{" "}
                  <Typography component="span" variant="h5" color="error">
                    {unit.addComma(record.salePrice || 0)}
                  </Typography>
                </Typography>
                <Typography component="p" variant="h6" color="primary">
                  지급금액:{" "}
                  <Typography component="span" variant="h5" color="error">
                    {unit.addComma(state.invoicePrice || 0)}
                  </Typography>
                </Typography>
                <Typography
                  component="span"
                  variant="caption"
                  color="primary"
                  style={{ fontWeight: 700, paddingLeft: 5, paddingRight: 5 }}
                >
                  유저 결제금액의 13.3%를 제한 후, 100원 단위 이하 버림 후 실제
                  정산 금액입니다.
                </Typography>
              </Grid>
              <Grid item xs={12} sm={5} style={{ paddingTop: 10 }}>
                <BooleanInput
                  label="지급여부"
                  source="isPay"
                  helperText={`지급 완료 후 지급 완료 처리해주세요.`}
                />
              </Grid>
            </Grid>
          </SimpleForm>
        </EditBase>
      </CardContent>
    </Card>
  );
};

const InvoiceToolbar = () => (
  <Toolbar>
    <SaveButton label="Save" />
  </Toolbar>
);

const InvoiceFilter = props => {
  const { setFilters } = useListContext();
  const resetFilter = () => {
    setFilters({}, []);
  };

  return (
    <Filter {...props}>
      <ReferenceInput
        label="이메일로 검색"
        source="uid"
        reference="user"
        sort={{ field: "name", order: "ASC" }}
        filterToQuery={searchText => ({ email: searchText })}
        allowEmpty={true}
        variant="outlined"
        alwaysOn
      >
        <AutocompleteInput
          label="이메일로 검색"
          variant="outlined"
          optionText="email"
        />
      </ReferenceInput>
      <DateInput
        variant="outlined"
        label="정산 지급일로 검색"
        source="invoiceDate"
        alwaysOn
        helperText="정산 지급일은 익월 15일입니다."
      />
      <Button
        alwaysOn
        color="primary"
        variant="outlined"
        onClick={() => resetFilter()}
      >
        Reset 필터
      </Button>
    </Filter>
  );
};

export const MentoringInvoiceList = props => {
  return (
    <List
      {...props}
      optimized
      title="멘토링 인보이스 관리"
      perPage={20}
      sort={{ field: "created", order: "desc" }}
      pagination={<Pagination rowsPerPageOptions={[20, 40]} />}
      filters={<InvoiceFilter />}
    >
      <Datagrid
        bulkActionButtons={false}
        rowClick="expand"
        expand={<InvoiceShow />}
        sx={{ "& *": { textAlign: "center !important" } }}
      >
        <TextField source="id" label="고유번호" />
        <BooleanField source="isPay" label="지급여부" />
        <DateField source="invoiceDate" label="정산 지급일" />
        <DateField source="month" label="멘토링 진행 일자" />
        <NumberField label="멘토링 진행 횟수" source="each" />
        <NumberField label="판매금액" source="salePrice" />
        <ReferenceField label="멘토명" source="uid" reference="user">
          <TextField source="displayName" />
        </ReferenceField>
        <ReferenceField label="멘토 이메일" source="uid" reference="user">
          <TextField source="email" />
        </ReferenceField>
      </Datagrid>
    </List>
  );
};
