import {
  List,
  Create,
  Edit,
  SimpleForm,
  required,
  Datagrid,
  DateField,
  EditButton,
  Pagination,
  BooleanInput,
  BooleanField,
  TextInput,
  SelectInput,
  TextField,
  Filter,
  SelectField,
} from "react-admin";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { RichTextInput } from "ra-input-rich-text";

const useStyles = makeStyles(theme => ({
  wrapper: {
    position: "relative",
    width: "100%",
    "& > div": { padding: theme.spacing(2) },
  },
  none: { display: "none" },
  textWrap: {
    "& img": {
      maxWidth: 300,
      height: "auto",
    },
  },
}));

// Storage > design > faq 밑에 img 저장

export const FAQCreate = props => {
  const classes = useStyles();
  return (
    <Create title="FAQ 등록" {...props}>
      <SimpleForm redirect="list">
        <Grid container className={classes.wrapper}>
          <Grid item xs={12} sm={6}>
            <BooleanInput
              source="isShow"
              label="노출여부"
              defaultValue={true}
              validate={[required()]}
            />
            <SelectInput
              variant="outlined"
              source="type"
              label="항목"
              choices={[
                { id: 0, name: "공통" },
                { id: 1, name: "결제" },
                { id: 2, name: "클래스" },
                { id: 3, name: "멘토링" },
                { id: 4, name: "회원" },
                { id: 5, name: "쿠폰" },
                { id: 6, name: "커뮤니티" },
              ]}
            />
            <TextInput
              variant="outlined"
              source="title"
              label="제목"
              validate={[required()]}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={8} className={classes.textWrap}>
            <RichTextInput
              variant="outlined"
              source="content"
              label="내용"
              validate={[required()]}
              fullWidth
            />
          </Grid>
        </Grid>
      </SimpleForm>
    </Create>
  );
};

export const FAQEdit = props => {
  const classes = useStyles();
  return (
    <Edit title="FAQ 수정" {...props}>
      <SimpleForm redirect="list">
        <Grid container className={classes.wrapper}>
          <Grid item xs={12} sm={12}>
            <DateField source="createdate" label="등록" showTime />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextInput
              label="고유번호"
              variant="outlined"
              source="id"
              fullWidth
              disabled
            />
            <SelectInput
              variant="outlined"
              source="type"
              label="항목"
              choices={[
                { id: 0, name: "공통" },
                { id: 1, name: "결제" },
                { id: 2, name: "클래스" },
                { id: 3, name: "멘토링" },
                { id: 4, name: "회원" },
                { id: 5, name: "쿠폰" },
                { id: 6, name: "커뮤니티" },
              ]}
            />
            <BooleanInput
              source="isShow"
              label="노출여부"
              defaultValue={true}
              validate={[required()]}
            />
            <TextInput
              variant="outlined"
              source="title"
              label="제목"
              validate={[required()]}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={8} className={classes.textWrap}>
            <RichTextInput
              variant="outlined"
              source="content"
              label="내용"
              validate={[required()]}
              fullWidth
            />
          </Grid>
        </Grid>
      </SimpleForm>
    </Edit>
  );
};

const FAQFilter = props => {
  return (
    <Filter {...props}>
      <TextInput
        alwaysOn
        variant="outlined"
        source="title"
        label="제목으로 검색"
      />
      <SelectInput
        alwaysOn
        variant="outlined"
        source="type"
        label="항목으로 검색"
        choices={[
          { id: 0, name: "공통" },
          { id: 1, name: "결제" },
          { id: 2, name: "클래스" },
          { id: 3, name: "멘토링" },
          { id: 4, name: "회원" },
          { id: 5, name: "쿠폰" },
          { id: 6, name: "커뮤니티" },
        ]}
      />
    </Filter>
  );
};

export const FAQList = props => {
  return (
    <List
      {...props}
      title="FAQ 관리"
      perPage={20}
      sort={{ field: "createdate", order: "DESC" }}
      pagination={<Pagination rowsPerPageOptions={[20, 40]} />}
      filters={<FAQFilter />}
    >
      <Datagrid
        bulkActionButtons={false}
        sx={{
          "& *": {
            textAlign: "center !important",
          },
        }}
      >
        <DateField source="createdate" label="등록시간" showTime />
        <TextField source="title" label="제목" />
        <BooleanField source="isShow" label="노출여부" />
        <SelectField
          source="type"
          label="항목"
          choices={[
            { id: 0, name: "공통" },
            { id: 1, name: "결제" },
            { id: 2, name: "클래스" },
            { id: 3, name: "멘토링" },
            { id: 4, name: "회원" },
            { id: 5, name: "쿠폰" },
            { id: 6, name: "커뮤니티" },
          ]}
        />
        <EditButton basePath={"/faq"} label="수정" />
      </Datagrid>
    </List>
  );
};
