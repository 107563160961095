import { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Create,
  List,
  Edit,
  SimpleForm,
  TextInput,
  required,
  Datagrid,
  DateField,
  EditButton,
  TextField,
  Pagination,
  ImageInput,
  ImageField,
  Filter,
  useNotify,
  useRefresh,
  useRedirect,
  ReferenceField,
  useEditController,
  ReferenceInput,
  SelectInput,
  useInput,
} from "react-admin";
import { Typography, Grid, TextField as MuiTextField } from "@material-ui/core";
import { encrytion } from "~/helpers/util";
import useEffectOnce from "react-use/lib/useEffectOnce";

// TODO:
// > 보안을 위해서 강연자 은행명, 계좌번호는 별도 DB에 암호화해서 저장하도록 구현함
// > 은행 계좌정보 조회 API 구현 필요 (ref. https://ws-pace.tistory.com/231.)
// > 은행 계좌자동이체 API 구현 필요

export const BANK_CODE = {
  "002": "KDB산업은행",
  "023": "SC제일은행",
  "037": "전북은행",
  "003": "IBK기업은행",
  "027": "한국씨티은행",
  "039": "경남은행",
  "004": "KB국민은행",
  "031": "대구은행",
  "081": "하나은행",
  "007": "수협은행",
  "032": "부산은행",
  "088": "신한은행",
  "011": "NH농협은행",
  "034": "광주은행",
  "089": "케이뱅크",
  "020": "우리은행",
  "035": "제주은행",
  "090": "카카오뱅크",
  "092": "토스뱅크",
};

const useStyles = makeStyles((theme) => ({
  wrapper: {
    position: "relative",
    width: "100%",
    "&>div": {
      padding: theme.spacing(2),
    },
  },
  none: {
    display: "none",
    visibility: "hidden",
    width: 0,
    height: 0,
  },
  input: {
    "& *": {
      marginTop: theme.spacing(0.5),
      padding: "12.5px !important",
    },
  },
}));

export const AccountCreate = (props) => {
  const classes = useStyles();
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();

  useEffect(() => {
    return () => {};
  }, []);

  const onSuccess = (data) => {
    notify(`저장 완료`);
    redirect("/account");
    refresh();
  };

  const transform = (data) => ({
    ...data,
    created: new Date().getTime(),
    bankImage:
      data.bankImage && data.bankImage !== undefined
        ? { ...data.bankImage }
        : {
            src: "",
            title: "",
          },
  });

  return (
    <Create
      title="강연자 계좌정보 등록"
      {...props}
      mutationOptions={{ onSuccess }}
      transform={transform}
    >
      <SimpleForm warnWhenUnsavedChanges redirect="list">
        <Grid container className={classes.wrapper}>
          <Grid item xs={12} sm={12}>
            <Typography component="span" variant="caption">
              *계좌정보가 없거나 유효하지 않은 계좌인 경우 정상적으로 지급처리
              되지 않습니다.
              <br />
              *보안에 각별히 신경써주세요.
            </Typography>
          </Grid>
          <Grid container>
            <Grid item xs={12} sm={4}>
              <ReferenceInput
                variant="outlined"
                label="강연자"
                source="lecturer"
                reference="lecturer"
                sort={{ field: "createdate", order: "DESC" }}
              >
                <SelectInput
                  fullWidth
                  variant="outlined"
                  label="강연자"
                  optionText="displayName"
                  validate={[required()]}
                />
              </ReferenceInput>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={4}>
            <ImageInput
              source="bankImage"
              label="통장사본"
              accept="image/*"
              fullWidth
              defaultValue={""}
            >
              <ImageField source="src" title="title" defaultValue={""} />
            </ImageInput>
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextInput
              id="bankName"
              source="bankName"
              label="은행명"
              variant="outlined"
              fullWidth
              required={[required()]}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextInput
              id="bankNumber"
              source="bankNumber"
              label="계좌번호"
              variant="outlined"
              fullWidth
              required={[required()]}
            />
          </Grid>
        </Grid>
      </SimpleForm>
    </Create>
  );
};

const DecryptTextField = (props) => {
  const { onChange, onBlur, ...rest } = props;
  const {
    field,
    fieldState: { isTouched, error },
    formState: { isSubmitted },
  } = useInput({
    onChange,
    onBlur,
    ...props,
  });

  useEffectOnce(() => {
    setTimeout(() => {
      field.onChange(encrytion.decryptionText(field.value));
    }, 500);
  });

  return (
    <MuiTextField
      {...field}
      {...rest}
      label={props.label}
      error={isTouched || isSubmitted}
      helperText={isTouched || isSubmitted ? error : ""}
      required={true}
      fullWidth
    />
  );
};

export const AccountEdit = (props) => {
  const classes = useStyles();
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();

  const controllerProps = useEditController(props);
  const { record, save, isLoading } = controllerProps;

  const onSuccess = (data) => {
    notify(`저장 완료`);
    redirect("/account");
    refresh();
  };

  return (
    <Edit
      title="강연자 계좌정보 수정"
      {...props}
      mutationOptions={{ onSuccess }}
    >
      <SimpleForm
        redirect="list"
        record={record}
        onSubmit={save}
        // warnWhenUnsavedChanges
      >
        <Grid container className={classes.wrapper}>
          <Grid item xs={12} sm={12}>
            <Typography component="span" variant="caption">
              *계좌정보가 없거나 유효하지 않은 계좌인 경우 정상적으로 지급처리
              되지 않습니다.
              <br />
              *보안에 각별히 신경써주세요.
            </Typography>
          </Grid>
          <Grid container>
            <Grid item xs={12} sm={4}>
              <ReferenceInput
                variant="outlined"
                label="강연자"
                source="lecturer"
                reference="lecturer"
                sort={{ field: "createdate", order: "DESC" }}
              >
                <SelectInput
                  fullWidth
                  variant="outlined"
                  label="강연자"
                  optionText="displayName"
                  validate={[required()]}
                />
              </ReferenceInput>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={4}>
            <ImageInput
              source="bankImage"
              label="통장사본"
              accept="image/*"
              fullWidth
              defaultValue={""}
            >
              <ImageField source="src" title="title" defaultValue={""} />
            </ImageInput>
          </Grid>
          <Grid item xs={12} sm={4}>
            <DecryptTextField
              id="bankName"
              source="bankName"
              label="은행명"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <DecryptTextField
              id="bankNumber"
              source="bankNumber"
              label="계좌번호"
              variant="outlined"
            />
          </Grid>
        </Grid>
      </SimpleForm>
    </Edit>
  );
};

const AccountFilter = (props) => {
  return (
    <Filter {...props}>
      <TextInput
        alwaysOn
        variant="outlined"
        source="displayName"
        label="이름으로 검색"
      />
    </Filter>
  );
};

export const AccountList = (props) => {
  return (
    <List
      {...props}
      title="강연자 계좌정보 관리"
      perPage={20}
      sort={{ field: "created", order: "DESC" }}
      pagination={<Pagination rowsPerPageOptions={[20, 40]} />}
      filters={<AccountFilter />}
    >
      <Datagrid bulkActionButtons={false}>
        <ReferenceField
          label="강연자 섬네일"
          source="lecturer"
          reference="lecturer"
        >
          <ImageField label="섬네일" source="thumbnail.src" title="섬네일" />
        </ReferenceField>
        <DateField source="created" label="등록시간" showTime />
        <ReferenceField
          label="강연자 이름"
          source="lecturer"
          reference="lecturer"
        >
          <TextField source="displayName" />
        </ReferenceField>
        <ReferenceField
          label="강연자 이메일"
          source="lecturer"
          reference="lecturer"
        >
          <TextField label="이메일" variant="outlined" source="email" />
        </ReferenceField>
        <EditButton basePath={"/account"} label="수정" />
      </Datagrid>
    </List>
  );
};
