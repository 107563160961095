import { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Create,
  List,
  Edit,
  SimpleForm,
  TextInput,
  required,
  Datagrid,
  DateField,
  EditButton,
  TextField,
  ArrayInput,
  SimpleFormIterator,
  DateInput,
  Pagination,
  ImageInput,
  ImageField,
  BooleanInput,
  Filter,
  useNotify,
  useRefresh,
  useRedirect,
  useEditController,
} from "react-admin";
import { Typography, Grid } from "@material-ui/core";
import { time } from "~/helpers/util";

// const validateLecturerCreate = async (values) => {
//   // TODO: 1. email vaildation, 2. bankNumber validation
//   const errors = {};
//   // const isEmailUnique = await checkEmailIsUnique(values.email);
//   // if (!isEmailUnique) {
//   //     errors.email = 'Email already used';
//   // }
//   return errors;
// };

// const validateLecturerEdit = async (values) => {
//   const errors = {};
//   return errors;
// };

const useStyles = makeStyles(theme => ({
  wrapper: {
    position: "relative",
    width: "100%",
    "&>div": {
      padding: theme.spacing(2),
    },
  },
  none: {
    display: "none",
    visibility: "hidden",
    width: 0,
    height: 0,
  },
  input: {
    "& *": {
      marginTop: theme.spacing(0.5),
      padding: "12.5px !important",
    },
  },
}));

// TODO:
// > 이메일 계정 이미 존재하는지 여부 확인
export const LecturerCreate = props => {
  const classes = useStyles();
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();

  useEffect(() => {
    return () => {};
  }, []);

  const onSuccess = data => {
    notify(`저장 완료`);
    redirect("/lecturer");
    refresh();
  };

  const transform = data => ({
    ...data,
    created: new Date().getTime(),
    fullBirthday: time.transform(new Date(data.fullBirthday).getTime()),
    career: data.career.map(item => {
      return {
        ...item,
        startDate: new Date(data.startDate).getTime(),
      };
    }),
  });

  // const [, cancel] = useDebounce(
  //   async () => {
  //     // step 1 email check
  //     const emailValidation = validation.emailValidation(state.emailChanged);
  //     if (!emailValidation) {
  //       onHandleButtonState(0, false);
  //       return;
  //     }
  //     try {
  //       // joined 체크, 로딩은 사용자 UX 편의성을 위해 띄우지 않음
  //       const result = await getClients(
  //         where("email", "==", `${state.emailChanged}`),
  //         "first"
  //       );
  //       const joined = result === undefined ? false : true;
  //       setState((prev) => ({
  //         ...prev,
  //         joined,
  //       }));
  //     } catch (error) {
  //       console.log("joined check error: ", error);
  //     }
  //   },
  //   500,
  //   [state.emailChanged]
  // );

  return (
    <Create
      title="강연자 등록"
      {...props}
      mutationOptions={{ onSuccess }}
      transform={transform}
    >
      <SimpleForm warnWhenUnsavedChanges redirect="list">
        <Grid container className={classes.wrapper}>
          <Grid item xs={12} sm={12}>
            <Typography component="span" variant="caption">
              (*)는 필수 입력란입니다.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <BooleanInput
              source="first"
              defaultValue={true}
              className={classes.none}
            />
            <ImageInput
              source="thumbnail"
              label="썸네일"
              accept="image/*"
              validate={[required()]}
              fullWidth
            >
              <ImageField source="src" title="title" />
            </ImageInput>
            <TextInput
              label="강연자 이름"
              variant="outlined"
              source="displayName"
              validate={[required()]}
              fullWidth
            />
            <TextInput
              label="이메일"
              variant="outlined"
              source="email"
              validate={[required()]}
              defaultValue=""
              fullWidth
            />
            <Grid container>
              <Grid item xs={12} sm={3}>
                <DateInput
                  variant="outlined"
                  source="fullBirtday"
                  label="생년월일"
                  validate={[required()]}
                  defaultValue={new Date(9999, 0, 1)}
                  helperText="*9999-01-01은 기본값"
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <BooleanInput
                  validate={[required()]}
                  defaultValue={true}
                  label="멘토링 진행 가능"
                  source="isMentoring"
                />
              </Grid>
            </Grid>
            <TextInput
              label="한줄 소개"
              variant="outlined"
              source="title"
              validate={[required()]}
              defaultValue=""
              fullWidth
            />
            <TextInput
              multiline
              label="상세 소개"
              variant="outlined"
              source="intro"
              defaultValue=""
              fullWidth
            />
            <TextInput
              multiline
              label="메모"
              variant="outlined"
              source="memo"
              defaultValue=""
              fullWidth
              helperText="*강연자 및 유저에게 보이지 않습니다."
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <ArrayInput source="career" label="경력 정보">
              <SimpleFormIterator>
                <TextInput variant="outlined" source="name" label="경력명" />
                <DateInput
                  variant="outlined"
                  source="startDate"
                  label="근무시작일"
                  required={[required()]}
                />
              </SimpleFormIterator>
            </ArrayInput>
          </Grid>
        </Grid>
      </SimpleForm>
    </Create>
  );
};

export const LecturerEdit = props => {
  const classes = useStyles();
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();
  const controllerProps = useEditController(props);
  const { record, save, isLoading } = controllerProps;

  const onSuccess = data => {
    notify(`저장 완료`);
    redirect("/lecturer");
    refresh();
  };

  const transform = data => ({
    ...data,
    fullBirthday: time.transform(new Date(data.fullBirthday).getTime()),
    career: data.career.map(item => {
      return {
        ...item,
        startDate: new Date(data.startDate).getTime(),
      };
    }),
  });

  // const [, cancel] = useDebounce(
  //   async () => {
  //     // step 1 email check
  //     const emailValidation = validation.emailValidation(state.emailChanged);
  //     if (!emailValidation) {
  //       onHandleButtonState(0, false);
  //       return;
  //     }
  //     try {
  //       // joined 체크, 로딩은 사용자 UX 편의성을 위해 띄우지 않음
  //       const result = await getClients(
  //         where("email", "==", `${state.emailChanged}`),
  //         "first"
  //       );
  //       const joined = result === undefined ? false : true;
  //       setState((prev) => ({
  //         ...prev,
  //         joined,
  //       }));
  //     } catch (error) {
  //       console.log("joined check error: ", error);
  //     }
  //   },
  //   500,
  //   [state.emailChanged]
  // );

  return (
    <Edit
      title="강연자 수정"
      {...props}
      mutationOptions={{ onSuccess }}
      transform={transform}
    >
      <SimpleForm
        redirect="list"
        warnWhenUnsavedChanges
        record={record}
        onSubmit={save}
      >
        <Grid container className={classes.wrapper}>
          <Grid item xs={12} sm={12}>
            <Typography component="span" variant="caption">
              (*)는 필수 입력란입니다.
            </Typography>
            <Typography>
              {"등록시간: "}
              <DateField source="created" showTime />
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextInput
              label="고유번호"
              variant="outlined"
              source="id"
              fullWidth
              disabled
            />
            <ImageInput
              source="thumbnail"
              label="썸네일"
              accept="image/*"
              validate={[required()]}
              fullWidth
            >
              <ImageField source="src" title="title" />
            </ImageInput>
            <TextInput
              label="강연자 이름"
              variant="outlined"
              source="displayName"
              validate={[required()]}
              fullWidth
            />
            <TextInput
              label="이메일"
              variant="outlined"
              source="email"
              fullWidth
              defaultValue=""
            />
            <Grid container>
              <Grid item xs={12} sm={3}>
                <DateInput
                  variant="outlined"
                  source="fullBirtday"
                  label="생년월일"
                  validate={[required()]}
                  defaultValue={new Date(1900, 0, 1)}
                  helperText="*1900-01-01은 기본값"
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <BooleanInput
                  validate={[required()]}
                  defaultValue={true}
                  label="멘토링 진행 가능"
                  source="isMentoring"
                />
              </Grid>
            </Grid>
            <TextInput
              label="한줄 소개"
              variant="outlined"
              source="title"
              validate={[required()]}
              fullWidth
              defaultValue=""
            />
            <TextInput
              multiline
              label="상세 소개"
              variant="outlined"
              source="intro"
              fullWidth
              defaultValue=""
            />
            <TextInput
              multiline
              label="메모"
              variant="outlined"
              source="memo"
              fullWidth
              defaultValue=""
              helperText="*강연자 및 유저에게 보이지 않습니다."
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <ArrayInput source="career" label="경력 정보">
              <SimpleFormIterator>
                <TextInput variant="outlined" source="name" label="경력명" />
                <DateInput
                  variant="outlined"
                  source="startDate"
                  label="경력연도"
                  required={[required()]}
                />
              </SimpleFormIterator>
            </ArrayInput>
          </Grid>
          <Grid item xs={12} sm={6}></Grid>
        </Grid>
      </SimpleForm>
    </Edit>
  );
};

const LecturerFilter = props => {
  return (
    <Filter {...props}>
      <TextInput
        alwaysOn
        variant="outlined"
        source="displayName"
        label="이름으로 검색"
      />
      <TextInput
        alwaysOn
        variant="outlined"
        source="title"
        label="한줄 소개로 검색"
      />
      <TextInput
        alwaysOn
        variant="outlined"
        source="email"
        label="이메일 검색"
      />
    </Filter>
  );
};

export const LecturerList = props => {
  return (
    <List
      {...props}
      title="강연자 관리"
      perPage={20}
      sort={{ field: "created", order: "DESC" }}
      pagination={<Pagination rowsPerPageOptions={[20, 40]} />}
      filters={<LecturerFilter />}
    >
      <Datagrid bulkActionButtons={false}>
        <ImageField label="섬네일" source="thumbnail.src" title="title" />
        <DateField source="created" label="등록시간" showTime />
        <TextField label="이름" variant="outlined" source="displayName" />
        <TextField label="이메일" variant="outlined" source="email" />
        <TextField label="강연자 한줄 소개" variant="outlined" source="title" />
        <EditButton basePath={"/lecturer"} label="수정" />
      </Datagrid>
    </List>
  );
};
