import { makeStyles } from "@material-ui/core/styles";
import {
  Create,
  List,
  Edit,
  SimpleForm,
  TextInput,
  required,
  Datagrid,
  DateField,
  EditButton,
  TextField,
  Pagination,
  Filter,
  SelectInput,
  SelectField,
} from "react-admin";
import Grid from "@material-ui/core/Grid";
import { RichTextInput } from "ra-input-rich-text";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    position: "relative",
    width: "100%",
    "&>div": {
      padding: theme.spacing(2),
    },
  },
  none: {
    display: "none",
    visibility: "hidden",
    width: 0,
    height: 0,
  },
  darkInput: {
    "& *": {
      color: "rgba(0, 0, 0, 0.6) !important",
      textFillColor: "#141414 !important",
    },
  },
}));

const NoticeFilter = (props) => {
  return (
    <Filter {...props}>
      <TextInput alwaysOn variant="outlined" source="title" label="제목" />
      <SelectInput
        label="공지타입"
        source="isShow"
        alwaysOn
        choices={[
          { id: 0, name: "노출" },
          { id: 1, name: "비노출" },
        ]}
        variant="outlined"
      />
      <SelectInput
        label="공지타입"
        source="type"
        alwaysOn
        choices={[
          { id: 0, name: "일반" },
          { id: 1, name: "이벤트" },
          { id: 2, name: "중요공지" },
        ]}
        variant="outlined"
      />
    </Filter>
  );
};

export const NoticeCreate = (props) => {
  const classes = useStyles();

  const transform = (data) => ({
    ...data,
    rating: JSON.parse(data.rating),
  });

  return (
    <Create
      title="공지사항 등록"
      {...props}
      redirect="list"
      transform={transform}
    >
      <SimpleForm redirect="list">
        <Grid container className={classes.wrapper}>
          <Grid item xs={12} sm={8}>
            {/* <NumberInput source="view" className={classes.none} /> */}
            <TextInput
              source="rating"
              className={classes.none}
              defaultValue={'{"view": 0, "review": 0, "like": 0, "share": 0}'}
            />
            <SelectInput
              label="공지타입"
              source="isShow"
              defaultValue={0}
              choices={[
                { id: 0, name: "노출" },
                { id: 1, name: "비노출" },
              ]}
              variant="outlined"
              validate={[required()]}
            />
            <SelectInput
              source="type"
              label="공지타입"
              defaultValue={0}
              choices={[
                { id: 0, name: "일반" },
                { id: 1, name: "이벤트" },
                { id: 2, name: "중요공지" },
              ]}
              variant="outlined"
              validate={[required()]}
            />
            <TextInput
              label="제목"
              variant="outlined"
              source="title"
              validate={[required()]}
              fullWidth
            />
            <RichTextInput
              variant="outlined"
              source="content"
              label="내용"
              validate={[required()]}
              fullWidth
            />
          </Grid>
        </Grid>
      </SimpleForm>
    </Create>
  );
};

export const NoticeEdit = (props) => {
  const classes = useStyles();
  return (
    <Edit title="공지사항 수정" {...props}>
      <SimpleForm redirect="list">
        <Grid container className={classes.wrapper}>
          <Grid item xs={12} sm={8}>
            <TextInput
              label="고유번호"
              variant="outlined"
              source="id"
              fullWidth
              disabled
              className={classes.darkInput}
            />
            <SelectInput
              label="공지타입"
              source="isShow"
              defaultValue={0}
              choices={[
                { id: 0, name: "노출" },
                { id: 1, name: "비노출" },
              ]}
              variant="outlined"
              validate={[required()]}
            />
            <SelectInput
              source="type"
              choices={[
                { id: 0, name: "일반" },
                { id: 1, name: "이벤트" },
                { id: 2, name: "중요공지" },
              ]}
              variant="outlined"
            />
            <TextInput
              label="제목"
              variant="outlined"
              source="title"
              validate={[required()]}
              fullWidth
            />
            <RichTextInput
              variant="outlined"
              source="content"
              label="내용"
              validate={[required()]}
              fullWidth
            />
          </Grid>
        </Grid>
      </SimpleForm>
    </Edit>
  );
};

export const NoticeList = (props) => {
  return (
    <List
      {...props}
      title="공지사항 관리"
      perPage={20}
      sort={{ field: "createdate", order: "DESC" }}
      pagination={<Pagination rowsPerPageOptions={[20, 40]} />}
      filters={<NoticeFilter />}
    >
      <Datagrid
        bulkActionButtons={false}
        sx={{
          "& *": {
            textAlign: "center !important",
          },
        }}
      >
        <DateField source="createdate" label="등록시간" showTime />
        <SelectField
          source="type"
          choices={[
            { id: 0, name: "일반" },
            { id: 1, name: "이벤트" },
            { id: 2, name: "중요공지" },
          ]}
          label="공지타입"
        />
        <SelectField
          source="isShow"
          choices={[
            { id: 0, name: "노출" },
            { id: 1, name: "비노출" },
          ]}
          label="노출여부"
          showTime
        />
        <TextField label="제목" variant="outlined" source="title" />
        <EditButton basePath={"/notice"} label="수정" />
      </Datagrid>
    </List>
  );
};
