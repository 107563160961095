import {
  Create,
  List,
  Datagrid,
  SimpleForm,
  TextInput,
  required,
  BooleanInput,
  Edit,
  Pagination,
  Filter,
  EditButton,
  DateField,
  SelectArrayInput,
  ReferenceArrayInput,
  TextField,
  NumberInput,
  useNotify,
  useRefresh,
  useRedirect,
  Toolbar,
  SaveButton,
} from "react-admin";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { RichTextInput } from "ra-input-rich-text";
import { app } from "~/App";
import { getFirestore, collection, doc, setDoc } from "firebase/firestore";

const useStyles = makeStyles(theme => ({
  wrapper: {
    position: "relative",
    width: "100%",
    "& > div": { padding: theme.spacing(2) },
  },
  none: {
    display: "none",
    visibility: "hidden",
    width: 0,
    height: 0,
  },
  darkInput: {
    "& *": {
      color: "rgba(0, 0, 0, 0.6) !important",
      textFillColor: "#141414 !important",
    },
  },
}));

export const CommunitySectionCreate = props => {
  const classes = useStyles();
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();

  const onSuccess = async data => {
    try {
      console.log(data);
      const firestore = getFirestore(app[0]);
      const communityRef = doc(
        collection(firestore, "communityRating"),
        data.id
      );
      setDoc(
        communityRef,
        {
          id: data.id, // 카테고리 아이디
          idx: 0, // 개별 게시판 입력 넘버
          view: 0, // 조회수
          like: 0, // 공감
          bad: 0, // 비공감
          follow: 0, // 팔로우 수
          subscribe: 0, // 구독자 수
          created: new Date().getTime(),
        },
        { merge: true }
      );

      notify(`저장 완료`);
      redirect("/communitySection");
      refresh();
    } catch (error) {
      console.log(`${error.message}`);
      notify(`일부데이터 생성 오류 발생`);
      redirect("/communitySection");
      refresh();
    }
  };

  const transform = data => ({
    ...data,
    created: new Date().getTime(),
  });

  return (
    <Create
      title="커뮤니티 카테고리 등록"
      {...props}
      transform={transform}
      mutationOptions={{ onSuccess }}
    >
      <SimpleForm submitOnEnter={false}>
        <Grid container className={classes.wrapper}>
          <Grid item xs={12} sm={7}>
            <Grid container>
              <Grid item xs={12} sm={12}>
                <TextInput
                  fullWidth
                  variant="outlined"
                  source="title"
                  label="카테고리 한글명"
                  validate={[required()]}
                  helperText="유저에게 보이는 하위 카테고리 명칭입니다."
                  defaultValue=""
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <BooleanInput
                  source="isOpen"
                  variant="outlined"
                  validate={[required()]}
                  label="오픈"
                  defaultValue={true}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <BooleanInput
                  source="commentable"
                  variant="outlined"
                  validate={[required()]}
                  label="댓글 작성 가능"
                  defaultValue={true}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <BooleanInput
                  source="isActive"
                  variant="outlined"
                  validate={[required()]}
                  label="강조"
                  defaultValue={false}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <BooleanInput
                  source="isAds"
                  variant="outlined"
                  validate={[required()]}
                  label="광고"
                  defaultValue={false}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <NumberInput
                  fullWidth
                  source="limitNumber"
                  variant="outlined"
                  label="글자 수 제한(byte)"
                  validate={[required()]}
                  defaultValue={30000}
                  min={30000}
                  max={300000}
                  helperText="(최소 3만, 최대 30만 byte)"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <ReferenceArrayInput
                  fullWidth
                  variant="outlined"
                  reference="permission"
                  source="permissions"
                  label="권한"
                >
                  <SelectArrayInput
                    fullWidth
                    variant="outlined"
                    label="권한"
                    validate={[required()]}
                    options={{
                      autoWidth: true,
                    }}
                    optionText="title"
                    optionValue="id"
                    style={{ minWidth: 300 }}
                  />
                </ReferenceArrayInput>
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextInput
                  variant="outlined"
                  source="placeholder"
                  label="플레이스홀더"
                  fullWidth
                  multiline
                  helperText="유저의 텍스트가 모두 지워지면 뜨는 내용입니다."
                  defaultValue=""
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <RichTextInput
                  variant="outlined"
                  source="submitholder"
                  label="입력 양식"
                  helperText="해당 커뮤니티에 입력을 시작할 때 기본적으로 뜨는 내용입니다."
                  fullWidth
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={5}>
            <Grid item xs={12} sm={12}>
              {/* <RichTextInput
                variant="outlined"
                source="headerholder"
                label="상단 입력 양식"
                helperText="커뮤니티 상세 보기 상단에 자동으로 붙는 요소입니다. (복잡한 UI는 개발자 도음 필요)"
                fullWidth
              /> */}
            </Grid>
            <Grid item xs={12} sm={12}>
              {/* <RichTextInput
                variant="outlined"
                source="footerholder"
                label="하단 입력 양식"
                helperText="커뮤니티 상세 보기 하단에 자동으로 붙는 요소입니다. (복잡한 UI는 개발자 도음 필요)"
                fullWidth
              /> */}
            </Grid>
          </Grid>
        </Grid>
      </SimpleForm>
    </Create>
  );
};

export const CommunitySectionEdit = props => {
  const classes = useStyles();

  return (
    <Edit title="커뮤니티 카테고리 수정" {...props}>
      <SimpleForm
        warnWhenUnsavedChanges
        submitOnEnter={false}
        toolbar={
          <Toolbar>
            <SaveButton label="Save" />
          </Toolbar>
        }
      >
        <Grid container className={classes.wrapper}>
          <Grid item xs={12} sm={7}>
            <Grid container>
              <Grid item xs={12} sm={12}>
                <TextInput
                  fullWidth
                  variant="outlined"
                  source="title"
                  label="카테고리 한글명"
                  validate={[required()]}
                  helperText="유저에게 보이는 하위 카테고리 명칭입니다."
                  defaultValue=""
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <BooleanInput
                  source="isOpen"
                  variant="outlined"
                  validate={[required()]}
                  label="오픈"
                  defaultValue={true}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <BooleanInput
                  source="commentable"
                  variant="outlined"
                  validate={[required()]}
                  label="댓글 작성 가능"
                  defaultValue={true}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <BooleanInput
                  source="isActive"
                  variant="outlined"
                  validate={[required()]}
                  label="강조"
                  defaultValue={false}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <BooleanInput
                  source="isAds"
                  variant="outlined"
                  validate={[required()]}
                  label="광고"
                  defaultValue={false}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <NumberInput
                  fullWidth
                  source="limitNumber"
                  variant="outlined"
                  label="글자 수 제한(byte)"
                  validate={[required()]}
                  defaultValue={30000}
                  min={30000}
                  max={300000}
                  helperText="(최소 3만, 최대 30만 byte)"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <ReferenceArrayInput
                  fullWidth
                  variant="outlined"
                  reference="permission"
                  source="permissions"
                  label="권한"
                >
                  <SelectArrayInput
                    fullWidth
                    variant="outlined"
                    label="권한"
                    validate={[required()]}
                    options={{
                      autoWidth: true,
                    }}
                    optionText="title"
                    optionValue="id"
                    style={{ minWidth: 300 }}
                  />
                </ReferenceArrayInput>
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextInput
                  variant="outlined"
                  source="placeholder"
                  label="플레이스홀더"
                  fullWidth
                  multiline
                  helperText="유저의 텍스트가 모두 지워지면 뜨는 내용입니다."
                  defaultValue=""
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <RichTextInput
                  variant="outlined"
                  source="submitholder"
                  label="입력 양식"
                  helperText="해당 커뮤니티에 입력을 시작할 때 기본적으로 뜨는 내용입니다."
                  fullWidth
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={5}>
            <Grid item xs={12} sm={12}>
              {/* <RichTextInput
                variant="outlined"
                source="headerholder"
                label="상단 입력 양식"
                helperText="커뮤니티 상세 보기 상단에 자동으로 붙는 요소입니다. (복잡한 UI는 개발자 도음 필요)"
                fullWidth
              /> */}
            </Grid>
            <Grid item xs={12} sm={12}>
              {/* <RichTextInput
                variant="outlined"
                source="footerholder"
                label="하단 입력 양식"
                helperText="커뮤니티 상세 보기 하단에 자동으로 붙는 요소입니다. (복잡한 UI는 개발자 도음 필요)"
                fullWidth
              /> */}
            </Grid>
          </Grid>
        </Grid>
      </SimpleForm>
    </Edit>
  );
};

const CommunityFilter = props => {
  return (
    <Filter {...props}>
      <TextInput
        alwaysOn
        variant="outlined"
        source="title"
        label="카테고리 명칭으로 검색"
      />
    </Filter>
  );
};

export const CommunitySectionList = props => {
  return (
    <List
      {...props}
      sort={{ field: "created", order: "DESC" }}
      title="커뮤니티 카테고리 관리"
      perPage={20}
      pagination={<Pagination rowsPerPageOptions={[20, 40]} />}
      filters={<CommunityFilter />}
    >
      <Datagrid
        bulkActionButtons={false}
        sx={{ "& *": { textAlign: "center !important" } }}
      >
        <DateField
          variant="outlined"
          source="created"
          label="등록시간"
          showTime
        />
        <TextField source="title" label="카테고리 명칭" />
        <EditButton basePath={"/communitySection"} label="수정" />
      </Datagrid>
    </List>
  );
};
