import "~/styles/index.css";
import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { hydrate, render } from "react-dom";
import App from "~/App";
import dotenv from "dotenv";
dotenv.config();

const rootElement = document.getElementById("root");

if (!!rootElement && rootElement.hasChildNodes()) {
  hydrate(
    <React.StrictMode>
      <Router>
        <App />
      </Router>
    </React.StrictMode>,
    rootElement
  );
} else {
  render(
    <React.StrictMode>
      <Router>
        <App />
      </Router>
    </React.StrictMode>,
    rootElement
  );
}
