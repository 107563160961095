import {
  List,
  Create,
  Edit,
  SimpleForm,
  required,
  Datagrid,
  DateField,
  EditButton,
  Pagination,
  BooleanInput,
  BooleanField,
  TextInput,
  SaveButton,
  Toolbar,
  TextField,
} from "react-admin";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    position: "relative",
    width: "100%",
    "& > div": { padding: theme.spacing(2) },
  },
  none: { display: "none" },
}));

export const SectionCreate = (props) => {
  const classes = useStyles();
  return (
    <Create title="섹션 등록" {...props}>
      <SimpleForm redirect="list">
        <Grid container className={classes.wrapper}>
          <Grid item xs={12} sm={12}>
            <BooleanInput
              source="isSoon"
              label="개강 예정 여부"
              defaultValue={true}
              validate={[required()]}
            />
            <TextInput
              variant="outlined"
              source="title"
              label="내용"
              validate={[required()]}
              fullWidth
            />
          </Grid>
        </Grid>
      </SimpleForm>
    </Create>
  );
};

export const SectionEdit = (props) => {
  const classes = useStyles();
  return (
    <Edit title="섹션 수정" {...props}>
      <SimpleForm redirect="list" toolbar={<SectionToolbar />}>
        <Grid container className={classes.wrapper}>
          <Grid item xs={12} sm={12}>
            <TextInput
              label="고유번호"
              variant="outlined"
              source="id"
              fullWidth
              disabled
            />
            <DateField source="createdate" label="등록시간" showTime />
            <BooleanInput
              source="isSoon"
              label="개강 예정 여부"
              defaultValue={true}
              validate={[required()]}
            />
            <TextInput
              variant="outlined"
              source="title"
              label="내용"
              validate={[required()]}
              fullWidth
            />
          </Grid>
        </Grid>
      </SimpleForm>
    </Edit>
  );
};

export const SectionToolbar = () => (
  <Toolbar>
    <SaveButton label="Save" />
  </Toolbar>
);

export const SectionList = (props) => {
  return (
    <List
      {...props}
      title="섹션 관리"
      perPage={20}
      sort={{ field: "createdate", order: "DESC" }}
      pagination={<Pagination rowsPerPageOptions={[20, 40]} />}
    >
      <Datagrid
        bulkActionButtons={false}
        sx={{
          "& *": {
            textAlign: "center !important",
          },
        }}
      >
        {/* <DateField source="createdate" label="등록시간" showTime /> */}
        <TextField source="title" label="섹션명" />
        <BooleanField source="isSoon" label="개강 예정 여부" />
        <EditButton basePath={"/section"} label="수정" />
      </Datagrid>
    </List>
  );
};
