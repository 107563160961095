import { useInput } from "react-admin";
import ChipInput from "material-ui-chip-input";

const MuiChipInput = (props) => {
  const { onChange, onBlur, ...rest } = props;
  const {
    field,
    fieldState: { isTouched, error },
  } = useInput(props);

  if (field.value && !Array.isArray(field.value)) {
    throw new Error(
      `ChipInput requires an array value but received: ${JSON.stringify(
        field.value
      )}`
    );
  }

  return (
    <ChipInput
      // {...field}
      label={props.label}
      onAdd={(newChip) => {
        field.onChange((field.value || []).concat(newChip));
      }}
      value={field.value || []}
      onDelete={(deletedChip) => {
        field.onChange(
          (field.value || []).filter((item) => item !== deletedChip)
        );
      }}
      error={!!(isTouched && error)}
      helperText={isTouched && error}
      required={false}
      {...rest}
    />
  );
};

export default MuiChipInput;
