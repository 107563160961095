import { Fragment, useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Create,
  List,
  Edit,
  SimpleForm,
  TextInput,
  required,
  BooleanInput,
  ImageInput,
  ImageField,
  Pagination,
  useNotify,
  useRefresh,
  useRedirect,
  Filter,
  RecordContextProvider,
  SimpleListLoading,
  TopToolbar,
  ExportButton,
  CreateButton,
  useListContext,
  EditButton,
  DateField,
  FormDataConsumer,
  useEditController,
} from "react-admin";
import {
  Grid,
  Box,
  Typography,
  Card,
  CardContent,
  CardActions,
} from "@material-ui/core";
import { time } from "~/helpers/util"; // , unit
import { Img } from "react-image";
import getBlobDuration from "get-blob-duration";
import adminStore from "~/libs/store";

const useStyles = makeStyles(theme => ({
  input: {
    display: "inline-block",
  },
  wrapper: {
    position: "relative",
  },
  fullVideoWrapper: {
    position: "relative",
  },
  videoWrapper: {
    position: "absolute",
    top: "55%",
    left: "50%",
  },
  helperText: {},
  none: {
    display: "none",
    visibility: "hidden",
    width: 0,
    height: 0,
  },
}));

const durationFormat = seconds => {
  const date = new Date(seconds * 1000);
  const hh = date.getUTCHours();
  const mm = date.getUTCMinutes();
  const ss = `0${date.getUTCSeconds()}`.slice(-2);
  if (hh) {
    return `${hh}:${`0${mm}`.slice(-2)}:${ss}`;
  }
  return `${mm}:${ss}`;
};

const Duration = ({ duration }) => {
  return (
    <time dateTime={`P${Math.round(duration)}S`}>
      {duration ? durationFormat(duration) : 0}
    </time>
  );
};

// TODO:
// Oracle Object Storage
// https://objectstorage.ap-chuncheon-1.oraclecloud.com/p/FSC1CWkPwZD33BVTYskdGjKtc8Se0VaAZDEXBKh45t8V9xodfP9Z6NDXG10Jc2sl/n/axzghb5effbz/b/impactus-bucket/o/free/{filename}
export const LectureCreate = props => {
  const classes = useStyles();
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();
  const [state, setState] = useState({
    urlByCloudFlare: null,
    urlByOracle: null,
  });
  const duration = adminStore(state => state.duration);

  // useEffect(() => {
  //   adminStore.setState({ duration: 0 });
  //   if (state?.urlByCloudFlare) {
  //     calcVideoInterval(state.urlByCloudFlare);
  //   }
  //   return () => {};
  // }, [state.urlByCloudFlare]);

  useEffect(() => {
    if (state.urlByOracle) {
      calcVideoInterval(state.urlByOracle);
    }
    return () => {};
  }, [state.urlByOracle]);

  const calcVideoInterval = async src => {
    try {
      const duration = await getBlobDuration(src);
      console.log("create duration: ", duration);
      adminStore.setState({ duration });
    } catch (error) {
      console.log("calc interval error: ", error);
    }
  };

  const onSuccess = async data => {
    try {
      notify(`저장 완료`);
      redirect("/lecture");
      refresh();
    } catch (error) {
      console.log(`${error.message}`);
      notify(`강연자 연동 오류 발생`);
      redirect("/lecture");
      refresh();
    }
  };

  const transform = data => ({
    ...data,
    duration,
    created: new Date().getTime(),
    // full: data.full && data.full !== undefined ? { ...data.full } : { src: "", title: "", duration: 0 },
  });

  return (
    <Create
      {...props}
      title="강연 등록"
      redirect="list"
      mutationOptions={{ onSuccess }}
      transform={transform}
    >
      <SimpleForm warnWhenUnsavedChanges submitOnEnter={false}>
        <FormDataConsumer>
          {({
            formData, // The whole form data
            scopedFormData, // The data for this item of the ArrayInput
            getSource, // A function to get the valid source inside an ArrayInput
            ...rest
          }) => {
            return (
              formData && (
                <Box maxWidth="70rem">
                  <Grid container className={classes.wrapper}>
                    <Grid item xs={12} sm={12}>
                      <Typography component="span" variant="caption">
                        (*)는 필수 입력란입니다.
                      </Typography>
                    </Grid>
                    {/* <TextInput source="rating" className={classes.none} defaultValue={'{"view": 0, "review": 0, "order": 0, "like": 0, "share": 0}'}/> */}
                    <Grid item xs={12} sm={5}>
                      <TextInput
                        label="강연 제목"
                        variant="outlined"
                        source="title"
                        fullWidth
                        validate={[required()]}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={2}
                      style={{
                        position: "relative",
                        left: 10,
                      }}
                    >
                      <BooleanInput
                        validate={[required()]}
                        defaultValue={true}
                        label="노출 여부"
                        source="commentable"
                      />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <BooleanInput
                        validate={[required()]}
                        defaultValue={false}
                        label="프리뷰 여부"
                        source="isPreview"
                      />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <TextInput
                        label="강연 간단 소개"
                        variant="outlined"
                        source="intro"
                        validate={[required()]}
                        fullWidth
                        multiline
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <ImageInput
                        source="thumbnail"
                        label="썸네일"
                        accept="image/*"
                        validate={[required()]}
                      >
                        <ImageField source="src" title="title" />
                      </ImageInput>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <TextInput
                        label="영상 URL(오라클 링크)"
                        variant="outlined"
                        source="urlByOracle"
                        validate={[required()]}
                        fullWidth
                        helperText="영상 기본주소: https://objectstorage.ap-chuncheon-1.oraclecloud.com/p/FSC1CWkPwZD33BVTYskdGjKtc8Se0VaAZDEXBKh45t8V9xodfP9Z6NDXG10Jc2sl/n/axzghb5effbz/b/impactus-bucket/o/free/{filename}"
                      />
                      <TextInput
                        label="영상 URL(클라우드플레어 링크) - 백업용"
                        variant="outlined"
                        source="urlByCloudFlare"
                        fullWidth
                        helperText="영상 기본주소: https://pub-938b4fda35054ec188ae974b686f8b53.r2.dev/{filename}"
                        defaultValue=""
                      />
                      {/* <TextInput label="영상 URL(위캔디오 iframe 링크)" variant="outlined" source="urlByWecandeo" validate={[required()]} fullWidth helperText="영상 기본주소: "/> */}
                      {/* <FileInput source="full" label="영상" accept="video/mp4"><FileField source="src" title="title" /></FileInput> */}
                      {duration && duration > 0 && (
                        <Typography
                          component="p"
                          variant="subtitle2"
                          color="primary"
                        >
                          강연시간: <Duration duration={duration} />
                        </Typography>
                      )}
                      <Typography
                        component="p"
                        variant="subtitle2"
                        color="primary"
                        style={{ paddingTop: 5 }}
                      >
                        (주의!) 강연시간이 계산되기 전에 저장하지 마세요.
                      </Typography>
                    </Grid>
                    {setState(formData)}
                  </Grid>
                </Box>
              )
            );
          }}
        </FormDataConsumer>
      </SimpleForm>
    </Create>
  );
};

export const LectureEdit = props => {
  const classes = useStyles();
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();
  const controllerProps = useEditController(props);
  const { record, save, isLoading } = controllerProps;
  const [state, setState] = useState({
    urlByCloudFlare: null,
    urlByOracle: null,
  });
  const duration = adminStore(state => state.duration);

  // useEffect(() => {
  //   if (state.urlByCloudFlare) {
  //     calcVideoInterval(state.urlByCloudFlare);
  //   }
  //   return () => {};
  // }, [state.urlByCloudFlare]);

  useEffect(() => {
    if (state.urlByOracle) {
      calcVideoInterval(state.urlByOracle);
    }
    return () => {};
  }, [state.urlByOracle]);

  const calcVideoInterval = async src => {
    try {
      const duration = await getBlobDuration(src);
      console.log("edit duration: ", duration);
      adminStore.setState({ duration });
    } catch (error) {
      console.log("calc interval error: ", error);
    }
  };

  const onSuccess = async data => {
    notify(`저장 완료`);
    redirect("/lecture");
    refresh();
  };

  const transform = data => ({
    ...data,
    duration,
    // full: data.full && data.full !== undefined ? { ...data.full } : { src: "", title: "" },
  });

  if (isLoading) return null;
  return (
    <Edit
      {...props}
      title="강연 수정"
      mutationOptions={{ onSuccess }}
      transform={transform}
      redirect="list"
      submitOnEnter={false}
    >
      <SimpleForm
        warnWhenUnsavedChanges
        submitOnEnter={false}
        record={record}
        onSubmit={save}
      >
        <FormDataConsumer>
          {({
            formData, // The whole form data
            scopedFormData, // The data for this item of the ArrayInput
            getSource, // A function to get the valid source inside an ArrayInput
            ...rest
          }) => {
            return (
              formData && (
                <Box>
                  <Grid container className={classes.wrapper}>
                    <Grid item xs={12} sm={12}>
                      <Typography component="span" variant="caption">
                        (*)는 필수 입력란입니다.
                      </Typography>
                      <Typography>
                        {"등록시간: "}
                        <DateField source="created" showTime />
                      </Typography>
                      <Grid container style={{ width: "60rem" }}>
                        <Grid
                          item
                          xs={12}
                          sm={2}
                          style={{
                            position: "relative",
                            left: 10,
                          }}
                        >
                          <BooleanInput
                            validate={[required()]}
                            defaultValue={true}
                            label="노출 여부"
                            source="commentable"
                          />
                        </Grid>
                        <Grid item xs={12} sm={2}>
                          <BooleanInput
                            validate={[required()]}
                            defaultValue={false}
                            label="프리뷰 여부"
                            source="isPreview"
                          />
                        </Grid>
                      </Grid>
                      {/* <NumberInput variant="outlined" source="rating.view" label="조회수" disabled defaultValue={0} format={(v) => `${unit.addComma(v)}회`} /> */}
                      {/* <NumberInput variant="outlined" source="rating.like" label="공감수" defaultValue={0} format={(v) => `${unit.addComma(v)}회`} disabled /> */}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Grid container>
                        <Grid item xs={12} sm={12}>
                          <TextInput
                            label="고유번호"
                            variant="outlined"
                            source="id"
                            fullWidth
                            disabled
                          />{" "}
                          <TextInput
                            label="강연 제목"
                            variant="outlined"
                            source="title"
                            fullWidth
                            validate={[required()]}
                          />
                          <TextInput
                            label="강연 간단 소개"
                            variant="outlined"
                            source="intro"
                            validate={[required()]}
                            fullWidth
                            multiline
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <ImageInput
                            source="thumbnail"
                            label="썸네일"
                            accept="image/*"
                            validate={[required()]}
                          >
                            <ImageField source="src" title="title" />
                          </ImageInput>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                          <TextInput
                            label="영상 URL(오라클 링크)"
                            variant="outlined"
                            source="urlByOracle"
                            validate={[required()]}
                            fullWidth
                            helperText="영상 기본주소: https://objectstorage.ap-chuncheon-1.oraclecloud.com/p/FSC1CWkPwZD33BVTYskdGjKtc8Se0VaAZDEXBKh45t8V9xodfP9Z6NDXG10Jc2sl/n/axzghb5effbz/b/impactus-bucket/o/free/{filename}"
                          />
                          <TextInput
                            label="영상 URL(클라우드플레어 링크) - 백업용"
                            variant="outlined"
                            source="urlByCloudFlare"
                            fullWidth
                            helperText="영상 기본주소: https://pub-938b4fda35054ec188ae974b686f8b53.r2.dev/{filename}"
                            defaultValue=""
                          />
                          {/* <TextInput label="영상 URL(위캔디오 iframe 링크)" variant="outlined" source="urlByWecandeo" validate={[required()]} fullWidth helperText="영상 기본주소: "/> */}
                          {/* <div className={classes.fullVideoWrapper}><FileInput source="full" label="영상" accept="video/mp4" validate={[required()]}><FileField source="src" title="title" /></FileInput></div> */}
                          {duration && duration > 0 && (
                            <Typography
                              component="p"
                              variant="subtitle2"
                              color="primary"
                            >
                              강연시간: <Duration duration={duration} />
                            </Typography>
                          )}
                          <Typography
                            component="p"
                            variant="subtitle2"
                            color="primary"
                            style={{ paddingTop: 5 }}
                          >
                            (주의!) 강연시간이 계산되기 전에 저장하지 마세요.
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    {setState(formData)}
                  </Grid>
                </Box>
              )
            );
          }}
        </FormDataConsumer>
      </SimpleForm>
    </Edit>
  );
};

const LectureFilter = props => {
  return (
    <Filter {...props}>
      <TextInput
        alwaysOn
        variant="outlined"
        source="ttitle"
        label="강의명으로 검색"
      />
    </Filter>
  );
};

const LectureGrid = () => {
  const {
    data: lecture,
    isLoading,
    // onToggleItem,
    // selectedIds,
  } = useListContext();

  if (isLoading) {
    return <SimpleListLoading hasLeftAvatarOrIcon hasSecondaryText />;
  }
  return lecture && lecture.length ? (
    <Fragment>
      <Grid container spacing={2}>
        {lecture.map(data => (
          <RecordContextProvider key={data.id} value={data}>
            <Grid key={data.id} item xs={12} sm={3}>
              <Card
                style={{
                  padding: "10px",
                  margin: "10px",
                  background: "#f9f9f9",
                }}
              >
                {data.thumbnail && (
                  <Img
                    src={data.thumbnail.src}
                    alt={""}
                    style={{
                      width: "100%",
                      height: "auto",
                    }}
                  />
                )}
                <CardContent>
                  <Typography
                    variant="body1"
                    component="h5"
                    align="center"
                    style={{ fontWeight: 700 }}
                  >
                    {data.title}
                  </Typography>
                  <Typography variant="body1" component="h6" align="center">
                    등록일: {time.transformKR(data.created)}
                  </Typography>
                </CardContent>
                <CardActions
                  style={{
                    display: "flex",
                    justifyContent: "space-around",
                  }}
                >
                  <EditButton resource="lecture" record={data} />
                </CardActions>
              </Card>
            </Grid>
          </RecordContextProvider>
        ))}
      </Grid>
    </Fragment>
  ) : null;
};

const ListActions = () => (
  <TopToolbar sx={{ minHeight: { sm: 56 } }}>
    <CreateButton />
    <ExportButton />
  </TopToolbar>
);

export const LectureList = props => {
  return (
    <List
      {...props}
      sort={{ field: "created", order: "DESC" }}
      title="강연 관리"
      perPage={20}
      pagination={<Pagination rowsPerPageOptions={[20, 40]} />}
      filters={<LectureFilter />}
      actions={<ListActions />}
    >
      <LectureGrid />
    </List>
  );
};
