import { Fragment, useState } from "react";
import {
  List,
  Datagrid,
  TextField,
  DateField,
  ReferenceField,
  NumberField,
  useRecordContext,
  Filter,
  DateInput,
  TextInput,
  Pagination,
  EditBase,
  SimpleForm,
  Toolbar,
  SaveButton,
  useListContext,
} from "react-admin";
import {
  Typography,
  Grid,
  Card,
  CardContent,
  Button,
  Backdrop,
  CircularProgress,
  Box,
} from "@material-ui/core";
import { unit, swal } from "~/helpers/util";
import { tossCancelApiCall } from "~/helpers/api";
import { app } from "~/App";
import {
  getFirestore,
  collection,
  doc,
  getDocs,
  arrayRemove,
  writeBatch,
} from "firebase/firestore";

const PaymentShow = props => {
  const record = useRecordContext();
  const [state, setState] = useState({
    refundLoading: false,
  });

  const onHandleRefund = async record => {
    try {
      setState(prev => ({ ...prev, refundLoading: true }));
      if (
        !record.paymentKey ||
        record.amount === 0 ||
        !record.type ||
        !record.uid
      ) {
        window.alert("환불이 불가한 항목입니다.");
        return;
      }

      let cancelAmount = 0;
      const confirmAlert = await swal.fire({
        title: "환불 처리하시겠습니까?",
        html: `<p style="line-height: 10px; font-size: 20px;">구매항목: ${
          record.type
        }</p><p style="line-height: 10px; font-size: 20px;">결제금액: ${unit.addComma(
          record.amount
        )}</p>`,
        inputPlaceholder: "환불 금액을 입력해주세요.",
        cancelButtonText: "닫기",
        confirmButtonText: "환불처리",
        showCancelButton: true,
        color: "#eaeaea",
        confirmButtonColor: "#f02222",
        cancelButtonColor: "#000000",
        background: "#141414",
        input: "number",
        inputAttributes: { autocapitalize: "off" },
        preConfirm: amount => {
          cancelAmount = amount;
        },
        footer: `<p style="line-height: 20px; font-size: 16px;">이 작업은 되돌릴 수 없습니다.<br/>
                  환불은 카드사에 따라 최대 3-5일 소요될 수 있습니다.</p>`,
      });
      if (!confirmAlert.isConfirmed) {
        return;
      }
      if (cancelAmount <= 0) {
        window.alert("환불 금액은 0보다 작거나 같을 수 없습니다.");
        return;
      }
      if (record.amount < cancelAmount) {
        window.alert("환불 금액은 결제금액보다 클 수 없습니다.");
        return;
      }
      const detail = await tossCancelApiCall(
        record.paymentKey,
        cancelAmount,
        "관리자 강제 환불"
      );
      // console.log("detail: ", detail);

      if (detail && detail?.status !== "success") {
        window.alert("환불 중에 오류가 있습니다. 잠시 후에 다시 시도해주세요.");
        return;
      }
      if (detail && detail?.status === "success") {
        // > record 항목에 따라 user history 제거 + user classes or subscribe 제거(배치로 처리) + refundDetail 업데이트

        const refundDetail = detail.result;
        let userHistory = null;
        let userSubHistoryRef = null;

        const firestore = getFirestore(app[0]);

        if (!record?.type?.includes("올인원")) {
          // 올인원
          userHistory = await getDocs(
            collection(firestore, "user", record.uid, "history")
          );
        } else {
          // 개별 클래스
          userHistory = record.selected;
        }

        const batch = writeBatch(firestore);
        const userRef = doc(collection(firestore, "user"), record.uid); // 유저
        const paymentRef = doc(collection(firestore, "payment"), record.id); // 전체 결제
        const userSubPaymentRef = doc(
          firestore,
          "user",
          record.uid,
          "payment",
          record.id
        ); // 유저 하위 결제

        if (!record?.type?.includes("올인원")) {
          userSubHistoryRef = doc(
            firestore,
            "user",
            record.uid,
            "history",
            record.selected
          );
        }

        if (record?.type?.includes("올인원")) {
          // 올인원 환불
          batch.update(userRef, { subscribe: "" });
          // 유저 전체 클래스 히스토리 날리기
          if (userHistory && userHistory.length > 0) {
            userHistory.forEach(doc => {
              batch.delete(doc.ref);
            });
          }
        } else {
          // 클래스 환불
          batch.update(userRef, { classes: arrayRemove(record.selected) });

          // 유저 해당 클래스 조회 기록 날리기
          if (userHistory) {
            batch.delete(userSubHistoryRef);
          }
        }
        // 결제 정보 등록
        batch.update(paymentRef, {
          isDone: true,
          isRefund: true,
          refundDetail,
        });
        // 유저 결제 정보 등록
        batch.update(userSubPaymentRef, {
          isRefund: true,
          refunded: new Date().getTime(),
        });

        await batch.commit();
        window.alert(`정상적으로 환불 처리 되었습니다.`);
      }
    } catch (e) {
      console.log("e: ", e?.message || e);
      window.alert(`환불 중에 오류가 있습니다. ${e?.message || e}`);
    } finally {
      setState(prev => ({ ...prev, refundLoading: false }));
    }
  };

  if (!record) return null;
  return (
    <Card style={{ maxWidth: 650, margin: "auto" }}>
      <Backdrop
        open={state.refundLoading}
        onClick={() => {}}
        style={{ zIndex: 999 }}
      >
        <CircularProgress color="secondary" style={{ zIndex: 9999 }} />
      </Backdrop>
      <CardContent>
        <EditBase {...props}>
          <SimpleForm sx={{ pt: 0, pb: 0 }} toolbar={<PaymentToolbar />}>
            <Grid
              container
              spacing={2}
              style={{ borderBottom: "1px solid #eaeaea" }}
            >
              <Grid item xs={6}>
                <Typography variant="subtitle1">
                  <ReferenceField label="유저명" source="uid" reference="user">
                    <TextField source="displayName" />
                  </ReferenceField>
                </Typography>
                <Typography variant="subtitle1">
                  <ReferenceField
                    label="유저 이메일"
                    source="uid"
                    reference="user"
                  >
                    <TextField source="email" />
                  </ReferenceField>
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="subtitle1">고유번호</Typography>
                <Typography variant="subtitle1">{record.id}</Typography>
              </Grid>
            </Grid>
            <Grid
              container
              spacing={2}
              style={{ paddingTop: 10, borderBottom: "1px solid #eaeaea" }}
            >
              <Grid item xs={6}>
                <Typography variant="h6" align="center">
                  결제일{" "}
                </Typography>
                <Typography align="center">
                  {new Date(record.created).toLocaleString()}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="h6" align="center">
                  결제항목
                </Typography>
                <Typography align="center">{record.type}</Typography>
              </Grid>
            </Grid>
            <Grid
              container
              style={{
                marginTop: 15,
                paddingBottom: 10,
                borderBottom: "1px solid #eaeaea",
              }}
            >
              <Grid item xs={6}>
                <Typography component="p" variant="subtitle1">
                  결제금액: {unit.addComma(record.amount)}₩
                </Typography>
                <Typography component="p" variant="subtitle1">
                  할인율: {record.discount}%
                </Typography>
                <Typography component="p" variant="subtitle1">
                  시청만료: {record.expired}
                </Typography>
                {record.detail ? (
                  <Fragment>
                    <Typography component="p" variant="subtitle1">
                      결제방법: {record?.detail?.method}
                    </Typography>
                    {record?.detail?.card && (
                      <Typography component="p" variant="subtitle1">
                        카드정보: {record?.detail?.card.company} /{" "}
                        {record?.detail?.card.cardType}
                      </Typography>
                    )}
                    <Typography component="p" variant="subtitle1">
                      승인시간:{" "}
                      {new Date(record?.detail?.approvedAt).toLocaleString()}
                    </Typography>
                  </Fragment>
                ) : (
                  <Typography component="p" variant="subtitle1">
                    결제방법: 쿠폰
                  </Typography>
                )}
                {record.refundDetail && (
                  <Box
                    style={{
                      border: "1px solid #f02222",
                      borderRadius: 5,
                      padding: 5,
                    }}
                  >
                    <Typography component="p" variant="subtitle1" color="error">
                      환불 처리된 결제건입니다.
                    </Typography>
                    <Typography component="p" variant="subtitle1">
                      환불사유:{" "}
                      {record?.refundDetail?.cancels[0]?.cancelReason ||
                        "Unknown"}
                    </Typography>
                    <Typography component="p" variant="subtitle1">
                      환불비용:{" "}
                      {unit.addComma(
                        record?.refundDetail?.cancels[0]?.cancelAmount
                      )}
                      ₩
                    </Typography>
                    <Typography component="p" variant="subtitle1">
                      환불정보: {record?.refundDetail?.cancelMsg || "Unknown"}
                    </Typography>
                    <Typography component="p" variant="subtitle1">
                      환불시간:{" "}
                      {new Date(
                        record?.refundDetail?.cancels[0]?.canceledAt
                      ).toLocaleString()}
                    </Typography>
                  </Box>
                )}
                {!record.isRefund && record.discount !== 100 && (
                  <Box
                    style={{
                      border: "1px solid #f02222",
                      borderRadius: 5,
                      padding: 5,
                    }}
                  >
                    <Button
                      variant="outlined"
                      style={{ marginTop: 10 }}
                      onClick={() => {
                        if (state.refundLoading) {
                          window.alert("이미 처리 중입니다.");
                          return;
                        }
                        onHandleRefund(record);
                      }}
                    >
                      <Typography
                        component="p"
                        variant="subtitle2"
                        color="primary"
                        style={{ fontWeight: 600 }}
                      >
                        환불처리
                      </Typography>
                    </Button>
                    <Typography
                      component="p"
                      variant="caption"
                      color="primary"
                      style={{ fontWeight: 500 }}
                    >
                      'MY&gt;결제'에서 정책에 따라 유저가 직접할 수 있습니다.
                      <br />
                      강제 환불은 꼭 필요한 경우에만 실행해주세요.
                    </Typography>
                  </Box>
                )}
              </Grid>
              <Grid item xs={6}>
                <Typography variant="subtitle1" style={{ fontWeight: 600 }}>
                  쿠폰 사용 여부
                </Typography>
                {record.couponId ? (
                  <Fragment>
                    <Grid item xs={12}>
                      <Typography variant="subtitle1">
                        <ReferenceField
                          label="쿠폰명"
                          source="couponId"
                          reference="coupon"
                        >
                          <TextField source="title" />
                        </ReferenceField>
                      </Typography>
                    </Grid>
                  </Fragment>
                ) : (
                  <Typography variant="subtitle1">미사용</Typography>
                )}
                <TextInput
                  label="메모"
                  variant="standard"
                  source="메모"
                  helperText="결제관련 메모를 기입해주세요."
                />
              </Grid>
            </Grid>
          </SimpleForm>
        </EditBase>
      </CardContent>
    </Card>
  );
};

const PaymentToolbar = () => (
  <Toolbar>
    <SaveButton label="Save" />
  </Toolbar>
);

const PaymentFilter = props => {
  const { setFilters } = useListContext();
  const resetFilter = () => {
    setFilters({}, []);
  };
  return (
    <Filter {...props}>
      <TextInput
        alwaysOn
        label="유저 이메일로 검색"
        variant="outlined"
        source="email"
      />
      <DateInput
        variant="outlined"
        label="결제일로 검색"
        source="createDate"
        alwaysOn
      />
      <Button
        alwaysOn
        color="primary"
        variant="outlined"
        onClick={() => resetFilter()}
      >
        Reset 필터
      </Button>
    </Filter>
  );
};

export const PaymentList = props => {
  return (
    <List
      {...props}
      optimized
      title="클래스 결제 관리"
      perPage={20}
      sort={{ field: "created", order: "desc" }}
      pagination={<Pagination rowsPerPageOptions={[20, 40]} />}
      filters={<PaymentFilter />}
      // filter={{ collectionQuery: c => c.where('created_gte', '<=', START_DATE).where('created_lte', '>=', END_DATE)}}
    >
      <Datagrid
        bulkActionButtons={false}
        rowClick="expand"
        expand={<PaymentShow />}
        sx={{ "& *": { textAlign: "center !important" } }}
      >
        <TextField source="id" label="고유번호" />
        <DateField source="created" showTime label="DB 생성시간" />
        <NumberField label="결제항목" source="type" />
        <DateField label="만료기간" source="expired" />
        <NumberField label="결제금액" source="amount" />
        <ReferenceField label="닉네임" source="uid" reference="user">
          <TextField source="displayName" />
        </ReferenceField>
        <ReferenceField label="유저 이메일" source="uid" reference="user">
          <TextField source="email" />
        </ReferenceField>
      </Datagrid>
    </List>
  );
};
