import { useEffect } from "react";
import {
  List,
  Edit,
  SimpleForm,
  TextInput,
  required,
  Datagrid,
  DateField,
  EditButton,
  TextField,
  Pagination,
  SelectField,
  SelectInput,
  Filter,
  ImageField,
  useEditController,
  Toolbar,
  SaveButton,
  ReferenceField,
  useNotify,
  useRefresh,
  useRedirect,
} from "react-admin";
import { Grid, Avatar, Typography, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { app } from "~/App";
import { getFirestore, collection, doc, setDoc } from "firebase/firestore";
import { unit } from "~/helpers/util";
// import { RichTextInput } from "ra-input-rich-text";
import MuiChipInput from "~/components/ChipInput";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    position: "relative",
    width: "100%",
    "& > div": { padding: theme.spacing(2) },
  },
  none: { display: "none" },
  darkInput: {
    "& *": {
      color: "rgba(0, 0, 0, 0.6) !important",
      textFillColor: "#141414 !important",
    },
  },
  timelineWrap: {
    maxHeight: "550px",
    overflowY: "auto",
    "& p": { fontWeight: 600 },
    "&::-webkit-scrollbar": { width: "3px" },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 5px grey",
      borderRadius: "3px",
    },
    "&::-webkit-scrollbar-thumb": {
      borderRadius: "3px",
      background: "red",
    },
  },
  timelineClassImg: {
    width: "200px",
    height: "auto",
    margin: theme.spacing(1),
  },
  couponInput: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  avatar: {
    width: "200px",
    height: "auto",
    "& img": {
      objectFit: "fill",
    },
  },
  textWrap: {
    border: "1px solid #141414",
    borderRadius: 5,
    padding: 5,
    "& .ql-align-center": {
      textAlign: "center !important",
      margin: 0,
    },
    "& .ql-align-right": {
      textAlign: "right !important",
      margin: 0,
    },
    "& .ql-align-left": {
      textAlign: "left !important",
      margin: 0,
    },
    "& .ql-align-justify": {
      margin: 0,
    },
    [theme.breakpoints.down("xs")]: {
      "& img": {
        width: "100%",
        height: "auto",
      },
    },
  },
}));

const MentorFilter = (props) => {
  return (
    <Filter {...props}>
      <TextInput
        alwaysOn
        variant="outlined"
        source="displayName"
        label="이름으로 검색"
      />
      <TextInput
        alwaysOn
        variant="outlined"
        source="email"
        label="이메일로 검색"
      />
      <SelectInput
        alwaysOn
        source="gender"
        choices={[
          { id: 0, name: "남성" },
          { id: 1, name: "여성" },
        ]}
        variant="outlined"
        label="성별로 검색"
      />
      <SelectInput
        source="status"
        variant="outlined"
        label="승인상태"
        alwaysOn
        choices={[
          { id: 0, name: "임시저장" },
          { id: 1, name: "승인요청" },
          { id: 2, name: "승인반려" },
          { id: 3, name: "승인완료" },
          { id: 4, name: "승인취소" },
        ]}
      />
    </Filter>
  );
};

export const MentorEdit = (props) => {
  const classes = useStyles();
  const controllerProps = useEditController(props);
  const { record, save, isLoading } = controllerProps;
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();

  useEffect(() => {
    return () => {};
  }, []);

  const onSuccess = async (data) => {
    if (data.status === 3) {
      // 멘토로 전환
      const firestore = getFirestore(app[0]);
      await setDoc(
        doc(collection(firestore, "user"), data.uid),
        {
          mentor: true,
        },
        { merge: true }
      );
    }

    notify(`저장 완료`);
    redirect("/classes");
    refresh();
  };

  const transform = (data) => ({
    ...data,
  });

  if (!record) return null;
  return (
    <Edit
      {...props}
      title="사용자 수정"
      transform={transform}
      mutationOptions={{ onSuccess }}
    >
      <SimpleForm
        record={record}
        onSubmit={save}
        warnWhenUnsavedChanges
        submitOnEnter={false}
        redirect="list"
        toolbar={<MentorToolbar />}
      >
        <Grid container className={classes.wrapper}>
          <Grid item xs={12} sm={4}>
            <Typography
              component="h6"
              variant="h6"
              style={{ fontWeight: 600 }}
              align="center"
            >
              멘토 정보
            </Typography>
            <Grid item xs={12} sm={12} style={{ marginBottom: "15px" }}>
              등록시간: <DateField source="created" label="등록시간" showTime />
            </Grid>
            {record && record.thumbnail && (
              <Grid
                item
                xs={12}
                sm={12}
                style={{
                  marginBottom: "15px",
                }}
              >
                <Avatar
                  src={record?.thumbnail?.src}
                  variant="square"
                  className={classes.avatar}
                />
              </Grid>
            )}
            <Grid item xs={12} sm={12}>
              <TextInput
                label="고유번호"
                variant="outlined"
                source="id"
                fullWidth
                disabled
                className={classes.darkInput}
              />
            </Grid>
            {/* TODO: stat */}
            {/* <Grid container>
              <Grid item xs={12} sm={4}>
                <NumberInput
                  variant="outlined"
                  source="rating.view"
                  label="조회수"
                  defaultValue={0}
                  fullWidth
                  disabled
                  className={classes.darkInput}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <NumberInput
                  variant="outlined"
                  source="rating.like"
                  label="공감수"
                  defaultValue={0}
                  fullWidth
                  disabled
                  className={classes.darkInput}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <NumberInput
                  variant="outlined"
                  source="rating.review"
                  label="댓글수"
                  defaultValue={0}
                  fullWidth
                  disabled
                  className={classes.darkInput}
                />
              </Grid>
            </Grid> */}
            <Grid container>
              <Grid item xs={12} sm={6}>
                <TextInput
                  label="이메일"
                  variant="outlined"
                  source="email"
                  validate={[required()]}
                  fullWidth
                  disabled
                  className={classes.darkInput}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextInput
                  variant="outlined"
                  source="displayName"
                  label="이름"
                  validate={[required()]}
                  fullWidth
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} sm={6}>
                <SelectInput
                  variant="outlined"
                  label="승인상태"
                  fullWidth
                  source="status"
                  defaultValue={0}
                  choices={[
                    { id: 0, name: "임시저장", not_available: true },
                    { id: 1, name: "승인요청", not_available: true },
                    { id: 2, name: "승인반려", not_available: false },
                    { id: 3, name: "승인완료", not_available: false },
                    { id: 4, name: "승인취소", not_available: false },
                  ]}
                  disableValue="not_available"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <SelectInput
                  source="gender"
                  fullWidth
                  variant="outlined"
                  choices={[
                    { id: 0, name: "남성" },
                    { id: 1, name: "여성" },
                  ]}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <ReferenceField source="uid" reference="user">
                  <TextInput
                    variant="outlined"
                    source="fullBirthDay"
                    label="생년월일"
                    fullWidth
                    disabled
                    className={classes.darkInput}
                  />
                </ReferenceField>
              </Grid>
              <Grid item xs={12} sm={6}>
                <ReferenceField source="uid" reference="user">
                  <TextInput
                    variant="outlined"
                    source="age"
                    label="연령대"
                    fullWidth
                    disabled
                    className={classes.darkInput}
                  />
                </ReferenceField>
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextInput
                  variant="outlined"
                  source="statusMsg"
                  label="승인 상태 메시지"
                  fullWidth
                  helperText="'승인반려' 또는 '승인취소' 시 상태 메시지를 함께 입력해주세요!"
                  defaultValue=""
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextInput
                  variant="outlined"
                  source="memo"
                  label="메모"
                  fullWidth
                  helperText="메모는 멘토에게 보이지 않습니다."
                  defaultValue=""
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography
              component="h6"
              variant="h6"
              style={{ fontWeight: 600 }}
              align="center"
            >
              멘토의 역량
            </Typography>
            <Typography
              component="p"
              variant="caption"
              style={{ fontWeight: 600 }}
              align="center"
            >
              실제 유저가 보는 멘토정보 페이지와 일부 디자인이 다르게 노출될 수
              있습니다.
            </Typography>
            <Box className={classes.textWrap}>
              {unit.renderHTML(record.intro)}
            </Box>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography
              component="h6"
              variant="h6"
              style={{ fontWeight: 600 }}
              align="center"
            >
              멘티에게 전하고 싶은말
            </Typography>
            <Typography
              component="p"
              variant="caption"
              style={{ fontWeight: 600 }}
              align="center"
            >
              실제 유저가 보는 멘토정보 페이지와 일부 디자인이 다르게 노출될 수
              있습니다.
            </Typography>
            <Box className={classes.textWrap}>
              {unit.renderHTML(record.message)}
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} style={{ paddingTop: 5 }}>
            <MuiChipInput
              label="멘토 전문 분야"
              disabled
              source="major"
              className={classes.darkInput}
            />
          </Grid>
        </Grid>
      </SimpleForm>
    </Edit>
  );
};

export const MentorToolbar = () => (
  <Toolbar>
    <SaveButton label="Save" />
  </Toolbar>
);

export const MentorList = (props) => {
  return (
    <List
      {...props}
      title="멘토 관리"
      perPage={20}
      sort={{ field: "created", order: "DESC" }}
      pagination={<Pagination rowsPerPageOptions={[20, 40]} />}
      filters={<MentorFilter />}
    >
      <Datagrid
        bulkActionButtons={false}
        sx={{
          "& *": {
            textAlign: "center !important",
          },
        }}
      >
        <ImageField label="대표이미지" source="thumbnail.src" />
        <DateField
          variant="outlined"
          source="created"
          label="등록시간"
          showTime
        />
        <SelectField
          source="status"
          variant="outlined"
          label="승인상태"
          choices={[
            { id: 0, name: "임시저장" },
            { id: 1, name: "승인요청" },
            { id: 2, name: "승인반려" },
            { id: 3, name: "승인완료" },
            { id: 4, name: "승인취소" },
          ]}
        />
        <SelectField
          source="gender"
          variant="outlined"
          label="성별"
          choices={[
            { id: 0, name: "남성" },
            { id: 1, name: "여성" },
          ]}
        />
        <TextField label="이메일" variant="outlined" source="email" />
        <TextField
          label="이름/닉네임"
          variant="outlined"
          source="displayName"
        />
        <EditButton basePath={"/mentor"} label="수정" />
      </Datagrid>
    </List>
  );
};
