import { useState, useEffect } from "react";
import {
  List,
  Edit,
  SimpleForm,
  TextInput,
  required,
  Datagrid,
  DateField,
  EditButton,
  TextField,
  Pagination,
  SelectField,
  SelectInput,
  NumberInput,
  Filter,
  ImageField,
  useEditController,
  Toolbar,
  SaveButton,
  CreateButton,
} from "react-admin";
import {
  Grid,
  Avatar,
  Typography,
  Box,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Button,
  TextField as MuiTextField,
  InputAdornment,
} from "@material-ui/core";
import { Loyalty } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import { app } from "~/App";
import {
  getFirestore,
  collection,
  doc,
  query,
  getDoc,
  getDocs,
  orderBy,
  limit,
  startAfter,
  where,
  writeBatch,
  increment,
} from "firebase/firestore";
import { time } from "~/helpers/util";
import { Alert } from "@material-ui/lab";
// import { Comment } from "@material-ui/icons";

const useStyles = makeStyles(theme => ({
  wrapper: {
    position: "relative",
    width: "100%",
    "& > div": { padding: theme.spacing(2) },
  },
  none: { display: "none" },
  darkInput: {
    "& *": {
      color: "rgba(0, 0, 0, 0.6) !important",
      textFillColor: "#141414 !important",
    },
  },
  timelineWrap: {
    maxHeight: 550,
    overflowY: "auto",
    "& p": { fontWeight: 600 },
    "&::-webkit-scrollbar": { width: 3 },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 5px grey",
      borderRadius: 3,
    },
    "&::-webkit-scrollbar-thumb": {
      borderRadius: 3,
      background: "red",
    },
  },
  timelineClassImg: {
    width: 200,
    height: "auto",
    margin: theme.spacing(1),
  },
  couponInput: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

const UserFilter = props => {
  return (
    <Filter {...props}>
      <TextInput
        alwaysOn
        variant="outlined"
        source="displayName"
        label="이름으로 검색"
      />
      <TextInput
        alwaysOn
        variant="outlined"
        source="email"
        label="이메일로 검색"
      />
      <SelectInput
        alwaysOn
        source="gender"
        choices={[
          { id: 0, name: "남성" },
          { id: 1, name: "여성" },
        ]}
        variant="outlined"
        label="성별로 검색"
      />
      <SelectInput
        alwaysOn
        source="type"
        choices={[
          { id: 0, name: "기본" },
          { id: 1, name: "임팩터스 관리자" }, // > 관리자 계정은 직접 부여
          { id: 2, name: "임팩터스 서포터즈" },
          { id: 3, name: "임팩터스 유관 프로그램 강사" },
          { id: 4, name: "임팩터스 유관 프로그램 참여자" },
          {
            id: 5,
            name: "임팩터스 매거진 구독자(추후 구독 형태 구현 시 사용)",
          },
          { id: 6, name: "임팩터스 마스터(매거진 작성자)" },
          { id: 7, name: "멘토" },
          { id: 8, name: "교사" },
          { id: 9, name: "교수" },
        ]}
        variant="outlined"
        label="타입으로 검색"
      />
    </Filter>
  );
};

const UserCouponList = props => {
  const classes = useStyles();
  const { record } = props;
  const [data, setData] = useState({
    loading: true,
    dataList: [],
    lastId: null,
    isLast: false,
    page: 10,
    done: false,
    couponId: null,
  });

  useEffect(() => {
    getDataList(record.id, "coupon");
    return () => {};
  }, []);

  const onHandleCoupon = event => {
    setData(prev => ({ ...prev, couponId: event?.target?.value }));
  };

  const onSubmitCoupon = async () => {
    // TODO:
    // > 이미 등록된 쿠폰인지 확인
    // > user coupon subcollection에 등록
    // user id: record.id,
    try {
      if (!data.couponId || data.couponId.length !== 20) {
        window.alert("쿠폰 번호를 정확히 입력해주세요.");
        return;
      }
      const q = query(
        collection(getFirestore(app[0]), `user/${record.id}/coupon`),
        orderBy("couponId"),
        where("couponId", "==", `${data.couponId}`),
        limit(1)
      );
      const docsList = await getDocs(q);
      if (!docsList.empty) {
        window.alert("이미 유저에게 등록된 쿠폰번호 입니다.");
        return;
      }
      const batch = writeBatch(getFirestore(app[0]));
      const created = new Date().getTime();

      const userRef = doc(
        getFirestore(app[0]),
        "user",
        record.id,
        "coupon",
        data.couponId
      ); // 유저 컬렉션
      const couponRef = doc(getFirestore(app[0]), "coupon", data.couponId); // 쿠폰 컬렉션(현재 등록한 사용자 확인용)
      const couponSubRef = doc(
        getFirestore(app[0]),
        "coupon",
        data.couponId,
        "user",
        record.id
      ); // 쿠폰 하위 컬렉션

      batch.set(userRef, {
        couponId: data.couponId,
        isUse: false,
        created,
      });
      batch.update(couponRef, {
        currentVolume: increment(1),
        lastupadte: created,
      });
      batch.set(couponSubRef, { isUse: false, uid: record.id, created });

      await batch.commit();

      window.alert(
        "정상적으로 쿠폰이 등록되었습니다. 새로고침 후에 확인 가능합니다."
      );
    } catch (e) {
      console.log("coupon regist e: ", e);
      window.alert("쿠폰 등록중에 알 수 없는 오류가 발생했습니다.");
    }
  };

  const getData = async (id, col) => {
    try {
      return await getDoc(doc(getFirestore(app[0]), col, id));
    } catch (e) {
      return null;
    }
  };

  const getDataList = async (id, col) => {
    try {
      const q = query(
        collection(getFirestore(app[0]), `user/${id}/${col}`),
        orderBy("created", "desc"),
        limit(data.page)
      );

      const docsList = await getDocs(q);
      if (docsList.empty) {
        setData(prev => ({ ...prev, isLast: true }));
        return;
      }

      let dataList = [];
      for (const doc of docsList.docs) {
        if (!doc.exists()) continue;
        const detail = doc.data();
        if (!detail) continue;

        const coupon = await getData(detail.couponId, col);
        if (!coupon) continue;

        dataList.push({ detail, ...coupon.data() });
      }

      setData(prev => ({
        ...prev,
        dataList,
        lastId: docsList.docs[docsList.docs.length - 1],
        isLast: docsList.docs.length <= data.page,
      }));
    } catch (e) {
      console.log(`${col} list e: `, e);
    } finally {
      setData(prev => ({ ...prev, loading: false }));
    }
  };

  const getDataListMore = async (id, col) => {
    try {
      setData(prev => ({ ...prev, loading: true }));
      const q = query(
        collection(getFirestore(app[0]), `user/${id}/${col}`),
        orderBy("created", "desc"),
        startAfter(data.lastId),
        limit(15)
      );
      const docsList = await getDocs(q);
      if (
        docsList.empty ||
        docsList.docs.length <= 0 ||
        data.lastId === docsList.docs[docsList.docs.length - 1]
      ) {
        setData(prev => ({ ...prev, isLast: true }));
        // 데이터 완료 알럿 노출
        window.alert("모든 데이터를 불러왔습니다.");
        return;
      }

      let dataList = [];
      for (const doc of docsList.docs) {
        if (!doc.exists()) continue;
        const detail = doc.data();
        if (!detail) continue;

        const coupon = await getData(detail.couponId, col);
        if (!coupon) continue;

        dataList.push({ detail, ...coupon.data() });
      }
      setData(prev => ({
        ...prev,
        dataList: [...prev.dataList, ...dataList],
        lastId: docsList.docs[docsList.docs.length - 1],
      }));
    } catch (e) {
      window.alert(`리뷰 데이터를 더 불러올 수 없습니다. ${e}`);
    } finally {
      setData(prev => ({ ...prev, loading: false }));
    }
  };

  const onHandleMore = () => {
    if (!data.isLast && !data.loading) getDataListMore();
  };

  return (
    <Box className={classes.timelineWrap}>
      <Grid container>
        {/* TODO: 쿠폰지급, 쿠폰 리스트 */}
        <Grid item xs={12} sm={12}>
          <Typography component="p" variant="subtitle2" color="primary">
            *쿠폰 지급을 위해선 쿠폰을 먼저 등록하고, 유저 선택 후 쿠폰번호를
            입력해야 합니다.
          </Typography>
          <CreateButton label="쿠폰 생성" resource="coupon" />
        </Grid>
        <Grid item xs={12} sm={12}>
          <MuiTextField
            variant="standard"
            value={data.couponId}
            onChange={onHandleCoupon}
            className={classes.couponInput}
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Loyalty style={{ color: "#f02222" }} />
                </InputAdornment>
              ),
            }}
          />
          <Button onClick={onSubmitCoupon} style={{ background: "#f02222" }}>
            <Typography
              component="p"
              variant="subtitle2"
              style={{ color: "#ffffff" }}
            >
              쿠폰 등록하기
            </Typography>
          </Button>
        </Grid>
        <Grid item xs={12} sm={12} style={{ marginTop: "10px" }}>
          {!data.loading && data.dataList.length <= 0 && (
            <Alert severity="info">데이터가 없습니다.</Alert>
          )}
        </Grid>
        <Stepper orientation="vertical" style={{ marginTop: "10px" }}>
          {data.dataList.length > 0 &&
            data.dataList.map(item => {
              return (
                <Step key={item.id} expanded active completed>
                  <StepLabel>
                    {item?.detail?.created &&
                      time.transformKR(item?.detail?.created)}{" "}
                    등록
                  </StepLabel>
                  <StepContent>
                    <EditButton
                      record={item}
                      label={item?.title}
                      resource="coupon"
                    />
                    {item?.detail?.isUse && (
                      <Typography
                        component="p"
                        variant="subtitle2"
                        color="error"
                      >
                        {time.transformKR(item?.detail?.created)} 사용완료
                      </Typography>
                    )}
                  </StepContent>
                </Step>
              );
            })}
        </Stepper>
        {!data.isLast && (
          <Grid item xs={12} sm={12}>
            <Button variant="contained" fullWidth onClick={onHandleMore}>
              더보기
            </Button>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

// const UserPaymentList = (props) => {
//   const classes = useStyles();
//   const { record } = props;
//   const [data, setData] = useState({
//     loading: true,
//     dataList: [],
//     lastId: null,
//     isLast: false,
//     page: 10,
//     done: false,
//   });

//   useEffect(() => {
//     getDataList(record.id, "payment");

//     return () => {};
//   }, []);

//   const getData = async (id, col) => {
//     try {
//       return await getDoc(doc(getFirestore(app[0]), col, id));
//     } catch (e) {
//       return null;
//     }
//   };

//   const getDataList = async (id, col) => {
//     try {
//       const q = query(
//         collection(getFirestore(app[0]), `user/${id}/${col}`),
//         orderBy("created", "desc"),
//         limit(data.page)
//       );

//       const docsList = await getDocs(q);
//       if (docsList.empty) {
//         setData((prev) => ({ ...prev, isLast: true }));
//         return;
//       }

//       let dataList = [];
//       for (const doc of docsList.docs) {
//         if (!doc.exists()) continue;
//         const detail = doc.data();
//         if (!detail) continue;

//         const payment = await getData(detail.paymentId, col);
//         if (!payment.exists()) continue;

//         const paymentData = payment.data();
//         let couponDoc = null;
//         if (paymentData.couponId) {
//           couponDoc = await getData(paymentData.couponId, "coupon");
//         }

//         dataList.push({
//           id: paymentData.id,
//           ...paymentData,
//           coupon: couponDoc ? { ...couponDoc.data() } : null,
//         });
//       }

//       setData((prev) => ({
//         ...prev,
//         dataList,
//         lastId: docsList.docs[docsList.docs.length - 1],
//         isLast: docsList.docs.length <= data.page,
//       }));
//     } catch (e) {
//       console.log(`${col} list e: `, e);
//     } finally {
//       setData((prev) => ({ ...prev, loading: false }));
//     }
//   };

//   const getDataListMore = async (id, col) => {
//     try {
//       setData((prev) => ({ ...prev, loading: true }));
//       const q = query(
//         collection(getFirestore(app[0]), `user/${id}/${col}`),
//         orderBy("created", "desc"),
//         startAfter(data.lastId),
//         limit(15)
//       );
//       const docsList = await getDocs(q);
//       if (
//         docsList.empty ||
//         docsList.docs.length <= 0 ||
//         data.lastId === docsList.docs[docsList.docs.length - 1]
//       ) {
//         setData((prev) => ({ ...prev, isLast: true }));
//         // 데이터 완료 알럿 노출
//         return;
//       }

//       let dataList = [];
//       for (const doc of docsList.docs) {
//         if (!doc.exists()) continue;
//         const detail = doc.data();
//         if (!detail) continue;

//         const payment = await getData(detail.paymentId, col);
//         if (!payment.exists()) continue;

//         const paymentData = payment.data();

//         let couponDoc = null;
//         if (paymentData.couponId) {
//           couponDoc = await getData(paymentData.couponId, "coupon");
//         }

//         dataList.push({
//           id: paymentData.id,
//           ...paymentData,
//           coupon: couponDoc ? { ...couponDoc.data() } : null,
//         });
//       }
//       setData((prev) => ({
//         ...prev,
//         dataList: [...prev.dataList, ...dataList],
//         lastId: docsList.docs[docsList.docs.length - 1],
//       }));
//     } catch (e) {
//       window.alert(`리뷰 데이터를 더 불러올 수 없습니다. ${e}`);
//     } finally {
//       setData((prev) => ({ ...prev, loading: false }));
//     }
//   };

//   const onHandleMore = () => {
//     if (!data.isLast && !data.loading) getDataListMore();
//   };

//   return (
//     <Box className={classes.timelineWrap}>
//       <Grid container>
//         <Grid item xs={12} sm={12}>
//           {!data.loading && data.dataList.length <= 0 && (
//             <Alert severity="info">데이터가 없습니다.</Alert>
//           )}
//           <Stepper orientation="vertical">
//             {data.dataList.length > 0 &&
//               data.dataList.map((item) => {
//                 return (
//                   <Step key={item.id} expanded active completed>
//                     <StepLabel>
//                       {item.created && time.transformKR(item.created)} 승인
//                     </StepLabel>
//                     <StepContent>
//                       <Typography
//                         component="p"
//                         variant="subtitle2"
//                         color="primary"
//                       >
//                         결제금액: {item.amount}
//                       </Typography>
//                       <Typography
//                         component="p"
//                         variant="subtitle2"
//                         color="primary"
//                       >
//                         할인금액: {item.discountPrice}
//                       </Typography>
//                       <Typography
//                         component="p"
//                         variant="subtitle2"
//                         color="primary"
//                       >
//                         할인율: {item.discount}%
//                       </Typography>
//                       <Typography
//                         component="p"
//                         variant="subtitle2"
//                         color="primary"
//                       >
//                         유효기간: {item.expired}까지
//                       </Typography>
//                       {item?.coupon && (
//                         <Box>
//                           <Typography
//                             component="p"
//                             variant="subtitle2"
//                             color="primary"
//                           >
//                             쿠폰사용:{" "}
//                             <EditButton
//                               record={item.coupon}
//                               label={item?.coupon?.title}
//                               resource="coupon"
//                             />
//                           </Typography>
//                         </Box>
//                       )}
//                       {item?.detail && (
//                         <Box>
//                           <Typography
//                             component="p"
//                             variant="subtitle2"
//                             color="primary"
//                           >
//                             결제방법:{" "}
//                             {item?.detail?.card && item?.detail?.card.company} /{" "}
//                             {item?.detail?.method}
//                           </Typography>
//                         </Box>
//                       )}
//                       {item?.refundDetail && (
//                         <Box>
//                           <Typography
//                             component="p"
//                             variant="subtitle2"
//                             color="error"
//                           >
//                             {time.transformKR(
//                               new Date(
//                                 item?.refundDetail?.cancels[0]?.canceledAt || 0
//                               ).getTime()
//                             )}{" "}
//                             환불됨
//                           </Typography>
//                           <Typography
//                             component="p"
//                             variant="subtitle2"
//                             color="error"
//                           >
//                             환불금액:{" "}
//                             {item?.refundDetail?.cancels[0]?.cancelAmount}
//                           </Typography>
//                           <Typography
//                             component="p"
//                             variant="subtitle2"
//                             color="error"
//                           >
//                             환불사유:{" "}
//                             {item?.refundDetail?.cancels[0]?.cancelReason}
//                           </Typography>
//                           <Typography
//                             component="p"
//                             variant="subtitle2"
//                             color="error"
//                           >
//                             환불정보:{" "}
//                             {item?.refundDetail?.cancelMsg || "Unknown"}
//                           </Typography>
//                         </Box>
//                       )}
//                     </StepContent>
//                   </Step>
//                 );
//               })}
//           </Stepper>
//         </Grid>
//         {!data.isLast && (
//           <Grid item xs={12} sm={12}>
//             <Button variant="contained" fullWidth onClick={onHandleMore}>
//               더보기
//             </Button>
//           </Grid>
//         )}
//       </Grid>
//     </Box>
//   );
// };

// const UserReviewList = (props) => {
//   const classes = useStyles();
//   const { record } = props;
//   const [data, setData] = useState({
//     loading: true,
//     dataList: [],
//     lastId: null,
//     isLast: false,
//     page: 10,
//     done: false,
//   });

//   useEffect(() => {
//     getDataList(record.id, "review");

//     return () => {};
//   }, []);

//   const getData = async (id, col) => {
//     try {
//       return await getDoc(doc(getFirestore(app[0]), col, id));
//     } catch (e) {
//       console.log("detail: ", e);
//       return null;
//     }
//   };

//   const getDataList = async (id, col) => {
//     try {
//       const q = query(
//         collection(getFirestore(app[0]), `user/${id}/${col}`),
//         orderBy("created", "desc"),
//         limit(data.page)
//       );

//       const docsList = await getDocs(q);
//       if (docsList.empty) {
//         setData((prev) => ({ ...prev, isLast: true }));
//         return;
//       }

//       let dataList = [];
//       for (const doc of docsList.docs) {
//         if (!doc.exists()) continue;
//         const detail = doc.data();
//         if (!detail) continue;

//         const review = await getData(detail.reviewId, col);
//         if (!review.exists()) continue;

//         let classDoc = null;
//         if (review?.data()?.classId) {
//           classDoc = await getData(review.data().classId, "classes");
//         }

//         dataList.push({
//           id: review.id,
//           review: { ...review.data() },
//           class: classDoc ? { ...classDoc.data() } : null,
//         });
//       }

//       setData((prev) => ({
//         ...prev,
//         dataList,
//         lastId: docsList.docs[docsList.docs.length - 1],
//         isLast: docsList.docs.length <= data.page,
//       }));
//     } catch (e) {
//       console.log(`${col} list e: `, e);
//     } finally {
//       setData((prev) => ({ ...prev, loading: false }));
//     }
//   };

//   const getDataListMore = async (id, col) => {
//     try {
//       setData((prev) => ({ ...prev, loading: true }));
//       const q = query(
//         collection(getFirestore(app[0]), `user/${id}/${col}`),
//         orderBy("created", "desc"),
//         startAfter(data.lastId),
//         limit(15)
//       );
//       const docsList = await getDocs(q);
//       if (
//         docsList.empty ||
//         docsList.docs.length <= 0 ||
//         data.lastId === docsList.docs[docsList.docs.length - 1]
//       ) {
//         setData((prev) => ({ ...prev, isLast: true }));
//         // 데이터 완료 알럿 노출
//         return;
//       }

//       let dataList = [];
//       for (const doc of docsList.docs) {
//         if (!doc.exists()) continue;
//         const detail = doc.data();
//         if (!detail) continue;

//         // const sub = await getData(detail.reviewId, col);
//         // dataList.push({ id: sub.id, ...sub.data() });

//         const review = await getData(detail.reviewId, col);
//         if (!review.exists()) continue;

//         let classDoc = null;
//         if (review?.data()?.classId) {
//           classDoc = await getData(review.data().classId, "classes");
//         }

//         dataList.push({
//           id: review.id,
//           review: { ...review.data() },
//           class: classDoc ? { ...classDoc.data() } : null,
//         });
//       }

//       setData((prev) => ({
//         ...prev,
//         dataList: [...prev.dataList, ...dataList],
//         lastId: docsList.docs[docsList.docs.length - 1],
//       }));
//     } catch (e) {
//       window.alert(`리뷰 데이터를 더 불러올 수 없습니다. ${e}`);
//     } finally {
//       setData((prev) => ({ ...prev, loading: false }));
//     }
//   };

//   const onHandleMore = () => {
//     if (!data.isLast && !data.loading) getDataListMore();
//   };

//   return (
//     <Box className={classes.timelineWrap}>
//       <Grid container>
//         <Grid item xs={12} sm={12}>
//           {!data.loading && data.dataList.length <= 0 && (
//             <Alert severity="info">데이터가 없습니다.</Alert>
//           )}
//           <Stepper orientation="vertical">
//             {data.dataList.length > 0 &&
//               data.dataList.map((item) => {
//                 return (
//                   <Step key={item.id} expanded active completed>
//                     <StepLabel>
//                       {item.review &&
//                         item.review.created &&
//                         time.transformKR(item.review.created)}{" "}
//                       작성
//                     </StepLabel>
//                     <StepContent>
//                       {item.class && (
//                         <Avatar
//                           src={item?.class?.thumbnail?.src}
//                           variant="square"
//                           className={classes.timelineClassImg}
//                         />
//                       )}
//                       <Typography
//                         component="p"
//                         variant="subtitle2"
//                         color="primary"
//                       >
//                         {item.review.content}
//                       </Typography>
//                     </StepContent>
//                   </Step>
//                 );
//               })}
//           </Stepper>
//         </Grid>
//         {!data.isLast && (
//           <Grid item xs={12} sm={12}>
//             <Button variant="contained" fullWidth onClick={onHandleMore}>
//               더보기
//             </Button>
//           </Grid>
//         )}
//       </Grid>
//     </Box>
//   );
// };

export const UserEdit = props => {
  const classes = useStyles();
  const controllerProps = useEditController(props);
  const { record, save, isLoading } = controllerProps;

  useEffect(() => {
    return () => {};
  }, []);

  if (!record) return null;
  return (
    <Edit title="사용자 수정" {...props}>
      <SimpleForm redirect="list" toolbar={<UserToolbar />}>
        <Grid container className={classes.wrapper}>
          <Grid item xs={12} sm={4}>
            <Typography
              component="h6"
              variant="h6"
              style={{ fontWeight: 600 }}
              align="center"
            >
              유저 정보
            </Typography>
            <Grid item xs={12} sm={12} style={{ marginBottom: "15px" }}>
              등록시간:{" "}
              <DateField source="createdate" label="등록시간" showTime />
            </Grid>
            {record && record.profileImage && (
              <Grid
                item
                xs={12}
                sm={12}
                style={{
                  marginBottom: "15px",
                }}
              >
                <Avatar
                  src={record.profileImage}
                  style={{ width: "100px", height: "100px" }}
                  variant="square"
                />
              </Grid>
            )}
            <Grid item xs={12} sm={12}>
              <TextInput
                label="고유번호"
                variant="outlined"
                source="id"
                fullWidth
                disabled
                className={classes.darkInput}
              />
            </Grid>
            <Grid container>
              <Grid item xs={12} sm={4}>
                <NumberInput
                  variant="outlined"
                  source="rating.view"
                  label="조회수"
                  defaultValue={0}
                  fullWidth
                  disabled
                  className={classes.darkInput}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <NumberInput
                  variant="outlined"
                  source="rating.like"
                  label="공감수"
                  defaultValue={0}
                  fullWidth
                  disabled
                  className={classes.darkInput}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <NumberInput
                  variant="outlined"
                  source="rating.review"
                  label="댓글수"
                  defaultValue={0}
                  fullWidth
                  disabled
                  className={classes.darkInput}
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} sm={6}>
                <TextInput
                  label="email"
                  variant="outlined"
                  source="email"
                  validate={[required()]}
                  fullWidth
                  disabled
                  className={classes.darkInput}
                  defaultValue=""
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextInput
                  variant="outlined"
                  source="displayName"
                  label="이름"
                  validate={[required()]}
                  fullWidth
                  defaultValue=""
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} sm={6}>
                <SelectInput
                  fullWidth
                  choices={[
                    { id: 0, name: "기본" },
                    { id: 1, name: "임팩터스 관리자" }, // > 관리자 계정은 직접 부여
                    { id: 2, name: "임팩터스 서포터즈" },
                    { id: 3, name: "임팩터스 유관 프로그램 강사" },
                    { id: 4, name: "임팩터스 유관 프로그램 참여자" },
                    {
                      id: 5,
                      name: "임팩터스 매거진 구독자(추후 구독 형태 구현 시 사용)",
                    },
                    { id: 6, name: "임팩터스 마스터(매거진 작성자)" },
                    { id: 7, name: "멘토" },
                    { id: 8, name: "교사" },
                    { id: 9, name: "교수" },
                  ]}
                  source="type"
                  variant="outlined"
                  label="유저 타입"
                  validate={[required()]}
                  defaultValue={0}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <SelectInput
                  label="성별"
                  source="gender"
                  fullWidth
                  variant="outlined"
                  defaultValue={0}
                  choices={[
                    { id: 0, name: "남성" },
                    { id: 1, name: "여성" },
                  ]}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextInput
                  variant="outlined"
                  source="fullBirthDay"
                  label="생년월일"
                  validate={[required()]}
                  fullWidth
                  defaultValue=""
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextInput
                  variant="outlined"
                  source="age"
                  label="연령대"
                  fullWidth
                  className={classes.darkInput}
                  disabled
                  defaultValue=""
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <SelectInput
                  label="회원 계정 상태"
                  variant="outlined"
                  source="status"
                  fullWidth
                  defaultValue={0}
                  choices={[
                    { id: 0, name: "기본" },
                    { id: 1, name: "정지" },
                    { id: 2, name: "블락(중지)" },
                  ]}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextInput
                  variant="outlined"
                  source="statusMsg"
                  label="계정 정지 시 사유"
                  fullWidth
                  helperText="정지 사유는 유저가 볼 수 있습니다."
                  defaultValue=""
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextInput
                  variant="outlined"
                  source="memo"
                  label="메모"
                  fullWidth
                  helperText="메모는 유저에게 보이지 않습니다."
                  defaultValue=""
                />
              </Grid>
            </Grid>
          </Grid>
          {/* <Grid item xs={12} sm={3}>
            <Typography
              component="h6"
              variant="h6"
              style={{ fontWeight: 600 }}
              align="center"
            >
              리뷰 이력
            </Typography>
            <UserReviewList record={record} />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Typography
              component="h6"
              variant="h6"
              style={{ fontWeight: 600 }}
              align="center"
            >
              결제 이력
            </Typography>
            <UserPaymentList record={record} />
          </Grid> */}
          <Grid item xs={12} sm={3}>
            <Typography
              component="h6"
              variant="h6"
              style={{ fontWeight: 600 }}
              align="center"
            >
              쿠폰 목록
            </Typography>
            <UserCouponList record={record} />
          </Grid>
        </Grid>
      </SimpleForm>
    </Edit>
  );
};

export const UserToolbar = () => (
  <Toolbar>
    <SaveButton label="Save" />
  </Toolbar>
);

export const UserList = props => {
  return (
    <List
      {...props}
      title="사용자 관리"
      perPage={20}
      sort={{ field: "created", order: "DESC" }}
      pagination={<Pagination rowsPerPageOptions={[20, 40]} />}
      filters={<UserFilter />}
    >
      <Datagrid
        bulkActionButtons={false}
        sx={{ "& *": { textAlign: "center !important" } }}
      >
        <ImageField label="대표이미지" source="profileImage" />
        <DateField
          variant="outlined"
          source="created"
          label="등록시간"
          showTime
        />
        <SelectField
          source="gender"
          variant="outlined"
          label="성별"
          choices={[
            { id: 0, name: "남성" },
            { id: 1, name: "여성" },
          ]}
        />
        <TextField label="이메일" variant="outlined" source="email" />
        <TextField
          label="이름/닉네임"
          variant="outlined"
          source="displayName"
        />
        <SelectField
          source="type"
          variant="outlined"
          label="타입"
          choices={[
            { id: 0, name: "기본" },
            { id: 1, name: "임팩터스 관리자" }, // > 관리자 계정은 직접 부여
            { id: 2, name: "임팩터스 서포터즈" },
            { id: 3, name: "임팩터스 유관 프로그램 강사" },
            { id: 4, name: "임팩터스 유관 프로그램 참여자" },
            {
              id: 5,
              name: "임팩터스 매거진 구독자(추후 구독 형태 구현 시 사용)",
            },
            { id: 6, name: "임팩터스 마스터(매거진 작성자)" },
            { id: 7, name: "멘토" },
            { id: 8, name: "교사" },
            { id: 9, name: "교수" },
          ]}
        />
        <EditButton basePath={"/user"} label="수정" />
      </Datagrid>
    </List>
  );
};
