import { Fragment, useState } from "react";
import {
  List,
  Datagrid,
  TextField,
  DateField,
  ReferenceField,
  NumberField,
  useRecordContext,
  Filter,
  DateInput,
  TextInput,
  EditBase,
  SimpleForm,
  Toolbar,
  SaveButton,
  useListContext,
} from "react-admin";
import {
  Typography,
  Grid,
  Card,
  CardContent,
  Backdrop,
  CircularProgress,
  Box,
  Button,
} from "@material-ui/core";
import { unit, swal } from "~/helpers/util";
import { tossCancelApiCall } from "~/helpers/api";
import { app } from "~/App";
import {
  getFirestore,
  collection,
  writeBatch,
  doc,
  getDoc,
  increment,
} from "firebase/firestore";

const PaymentShow = props => {
  const [state, setState] = useState({
    refundLoading: false,
  });
  const record = useRecordContext();

  const onHandleRefund = async record => {
    try {
      setState(prev => ({ ...prev, refundLoading: true }));
      if (
        !record.paymentKey ||
        record.amount === 0 ||
        !record.uid ||
        !record.id ||
        !record.mentoring
      ) {
        window.alert("환불이 불가한 항목입니다.");
        return;
      }

      let cancelAmount = 0;
      const confirmAlert = await swal.fire({
        title: "환불 처리하시겠습니까?",
        html: `<p style="line-height: 10px; font-size: 20px;">구매항목: ${
          record.orderName
        }</p><p style="line-height: 10px; font-size: 20px;">결제금액: ${unit.addComma(
          record.amount
        )}</p>`,
        inputPlaceholder: "환불 금액을 입력해주세요.",
        cancelButtonText: "닫기",
        confirmButtonText: "환불처리",
        showCancelButton: true,
        color: "#eaeaea",
        confirmButtonColor: "#f02222",
        cancelButtonColor: "#000000",
        background: "#141414",
        input: "number",
        inputAttributes: { autocapitalize: "off" },
        preConfirm: amount => {
          cancelAmount = amount;
        },
        footer: `<p style="line-height: 20px; font-size: 16px;">이 작업은 되돌릴 수 없습니다.<br/>
                  환불은 카드사에 따라 최대 3-5일 소요될 수 있습니다.</p>`,
      });
      if (!confirmAlert.isConfirmed) {
        return;
      }
      if (cancelAmount <= 0) {
        window.alert("환불 금액은 0보다 작거나 같을 수 없습니다.");
        return;
      }
      if (record.amount < cancelAmount) {
        window.alert("환불 금액은 결제금액보다 클 수 없습니다.");
        return;
      }

      const detail = await tossCancelApiCall(
        record.paymentKey,
        cancelAmount,
        "관리자 강제 환불"
      );
      if (detail && detail?.status !== "success") {
        window.alert("환불 중에 오류가 있습니다. 잠시 후에 다시 시도해주세요.");
        return;
      }

      if (detail && detail?.status === "success") {
        const refundDetail = detail.result;
        const firestore = getFirestore(app[0]);

        const userDoc = await getDoc(
          doc(collection(firestore, "user"), record.uid)
        );
        const userData = userDoc.data(); // 유저 정보 가져오기

        const mentoringDoc = await getDoc(
          doc(collection(firestore, "mentoring"), record.mentoring)
        );
        const mentoringData = mentoringDoc.data(); // 멘토링 정보 가져오기

        const userMentoring = userData?.mentoring?.filter(
          item => item.id !== record.id
        ); // 유저 현재 멘토링 정보 업데이트용

        const timeline = mentoringData?.timeline?.map(item => {
          if (item.id === record.id) {
            return {
              ...item,
              status: 5,
            };
          } else {
            return item;
          }
        }); // 멘토링 타임라인 업데이트용

        const batch = writeBatch(firestore);
        // 결제 컬렉션
        const paymentRef = doc(
          collection(firestore, "mentoringPayment"),
          record.id
        );
        // 룸 컬렉션
        const mentoringRoomRef = doc(
          collection(firestore, "mentoringRoom"),
          record.id
        );
        const mentoringRef = doc(
          collection(firestore, "mentoring"),
          record.mentoring
        );
        // 유저 컬렉션
        const userRef = doc(collection(firestore, "user"), record.uid);
        // 유저 결제 하위 컬렉션
        const userSubPaymentRef = doc(
          firestore,
          "user",
          record.uid,
          "mentoringPayment",
          record.id
        );
        // 멘토 정보
        const mentorRef = doc(collection(firestore, "mentor"), record.mentor);
        const now = new Date().getTime();

        batch.update(mentorRef, {
          "stat.refund": increment(1), // 유저의 환불
        });
        batch.update(mentoringRoomRef, {
          refunded: now,
        });
        batch.update(userRef, { mentoring: userMentoring });
        batch.update(mentoringRef, { timeline });
        // 결제 정보 등록
        batch.update(paymentRef, {
          isRefund: true,
          refundDetail,
        });
        // 유저 결제 정보 등록
        batch.update(userSubPaymentRef, {
          isRefund: true,
          refunded: now,
        });

        await batch.commit();
        window.alert(`정상적으로 환불 처리 되었습니다.`);
      }
    } catch (e) {
      console.log("e: ", e?.message || e);
      window.alert(`환불 중에 오류가 있습니다. ${e?.message || e}`);
    } finally {
      setState(prev => ({ ...prev, refundLoading: false }));
    }
  };

  if (!record) return null;

  return (
    <Card style={{ maxWidth: 650, margin: "auto" }}>
      <Backdrop
        open={state.refundLoading}
        onClick={() => {}}
        style={{ zIndex: 999 }}
      >
        <CircularProgress color="secondary" style={{ zIndex: 9999 }} />
      </Backdrop>
      <CardContent>
        <EditBase {...props}>
          <SimpleForm sx={{ pt: 0, pb: 0 }} toolbar={<PaymentToolbar />}>
            <Grid
              container
              spacing={2}
              style={{
                borderBottom: "1px solid #eaeaea",
              }}
            >
              <Grid item xs={6}>
                <Typography variant="subtitle1">
                  <ReferenceField label="유저명" source="uid" reference="user">
                    <TextField source="displayName" />
                  </ReferenceField>
                </Typography>
                <Typography variant="subtitle1">
                  <ReferenceField
                    label="유저 이메일"
                    source="uid"
                    reference="user"
                  >
                    <TextField source="email" />
                  </ReferenceField>
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="subtitle1">고유번호</Typography>
                <Typography variant="subtitle1">{record.id}</Typography>
              </Grid>
            </Grid>
            <Grid
              container
              spacing={2}
              style={{
                paddingTop: "10px",
                borderBottom: "1px solid #eaeaea",
              }}
            >
              <Grid item xs={6}>
                <Typography variant="h6" align="center">
                  결제일{" "}
                </Typography>
                <Typography align="center">
                  {new Date(record.created).toLocaleString()}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="h6" align="center">
                  결제항목
                </Typography>
                <Typography align="center">{record.orderName}</Typography>
              </Grid>
            </Grid>
            <Grid
              container
              style={{
                marginTop: "15px",
                paddingBottom: "10px",
                borderBottom: "1px solid #eaeaea",
              }}
            >
              <Grid
                item
                xs={6}
                style={{
                  textAlign: "left !important",
                }}
              >
                <Typography component="p" variant="subtitle1">
                  결제금액: {unit.addComma(record.amount)}₩
                </Typography>
                <Typography component="p" variant="subtitle1">
                  할인율: {record.discount}%
                </Typography>
                {record.detail ? (
                  <Fragment>
                    <Typography component="p" variant="subtitle1">
                      결제방법: {record?.detail?.method}
                    </Typography>
                    {record?.detail?.card && (
                      <Typography component="p" variant="subtitle1">
                        카드정보: {record?.detail?.card.company} /{" "}
                        {record?.detail?.card.cardType}
                      </Typography>
                    )}
                    <Typography component="p" variant="subtitle1">
                      승인시간:{" "}
                      {new Date(record?.detail?.approvedAt).toLocaleString()}
                    </Typography>
                  </Fragment>
                ) : (
                  <Typography component="p" variant="subtitle1">
                    결제방법: 쿠폰
                  </Typography>
                )}
                {record.refundDetail && (
                  <Box
                    style={{
                      border: "1px solid #f02222",
                      borderRadius: 5,
                      padding: 5,
                    }}
                  >
                    <Typography component="p" variant="subtitle1" color="error">
                      환불 처리된 결제건입니다.
                    </Typography>
                    <Typography component="p" variant="subtitle1">
                      환불사유:{" "}
                      {record?.refundDetail?.cancels[0]?.cancelReason ||
                        "Unknown"}
                    </Typography>
                    <Typography component="p" variant="subtitle1">
                      환불비용:{" "}
                      {unit.addComma(
                        record?.refundDetail?.cancels[0]?.cancelAmount
                      )}
                      ₩
                    </Typography>
                    <Typography component="p" variant="subtitle1">
                      환불정보: {record?.refundDetail?.cancelMsg || "Unknown"}
                    </Typography>
                    <Typography component="p" variant="subtitle1">
                      환불시간:{" "}
                      {new Date(
                        record?.refundDetail?.cancels[0]?.canceledAt
                      ).toLocaleString()}
                    </Typography>
                  </Box>
                )}
                {!record.isRefund && record.discount !== 100 && (
                  <Box
                    style={{
                      border: "1px solid #f02222",
                      borderRadius: 5,
                      padding: 5,
                    }}
                  >
                    <Button
                      variant="outlined"
                      style={{ marginTop: 10 }}
                      onClick={() => {
                        if (state.refundLoading) {
                          window.alert("이미 처리 중입니다.");
                          return;
                        }
                        onHandleRefund(record);
                      }}
                    >
                      <Typography
                        component="p"
                        variant="subtitle2"
                        color="primary"
                        style={{ fontWeight: 600 }}
                      >
                        환불처리
                      </Typography>
                    </Button>
                    <Typography
                      component="p"
                      variant="caption"
                      color="primary"
                      style={{ fontWeight: 500 }}
                    >
                      'MY&gt;멘토링&gt;기록보기'에서 정책에 따라 유저가 직접 할
                      수 있습니다.
                      <br />
                      강제 환불은 꼭 필요한 경우에만 실행해주세요.
                    </Typography>
                  </Box>
                )}
              </Grid>
              <Grid item xs={6}>
                <Typography variant="subtitle1" style={{ fontWeight: 600 }}>
                  쿠폰 사용 여부
                </Typography>
                {record.couponId ? (
                  <Fragment>
                    <Grid item xs={12}>
                      <Typography variant="subtitle1">
                        <ReferenceField
                          label="쿠폰명"
                          source="couponId"
                          reference="coupon"
                        >
                          <TextField source="title" />
                        </ReferenceField>
                      </Typography>
                    </Grid>
                  </Fragment>
                ) : (
                  <Typography variant="subtitle1">미사용</Typography>
                )}
                <TextInput
                  label="메모"
                  variant="standard"
                  source="메모"
                  helperText="결제관련 메모를 기입해주세요."
                />
              </Grid>
            </Grid>
          </SimpleForm>
        </EditBase>
      </CardContent>
    </Card>
  );
};

const PaymentToolbar = () => (
  <Toolbar>
    <SaveButton label="Save" />
  </Toolbar>
);

const PaymentFilter = props => {
  const { setFilters } = useListContext();
  const resetFilter = () => {
    setFilters({}, []);
  };
  return (
    <Filter {...props}>
      <TextInput
        alwaysOn
        label="유저 이메일로 검색"
        variant="outlined"
        source="email"
      />
      <DateInput
        variant="outlined"
        label="결제일로 검색"
        source="createDate"
        alwaysOn
      />
      <Button
        alwaysOn
        color="primary"
        variant="outlined"
        onClick={() => resetFilter()}
      >
        Reset 필터
      </Button>
    </Filter>
  );
};

export const MentoringPaymentList = props => {
  return (
    <List
      {...props}
      optimized
      title="멘토링 결제 관리"
      perPage={20}
      sort={{ field: "created", order: "desc" }}
      filters={<PaymentFilter />}
      // filter={{ collectionQuery: c => c.where('created_gte', '<=', START_DATE).where('created_lte', '>=', END_DATE)}}
    >
      <Datagrid
        bulkActionButtons={false}
        rowClick="expand"
        expand={<PaymentShow />}
        sx={{
          "& *": {
            textAlign: "center !important",
          },
        }}
      >
        <TextField source="id" label="고유번호" />
        <DateField source="created" showTime label="DB 생성시간" />
        <NumberField label="결제항목" source="orderName" />
        <ReferenceField label="멘토명" source="mentor" reference="user">
          <TextField source="displayName" />
        </ReferenceField>
        <ReferenceField label="유저명" source="uid" reference="user">
          <TextField source="displayName" />
        </ReferenceField>
        <ReferenceField label="유저 이메일" source="uid" reference="user">
          <TextField source="email" />
        </ReferenceField>
        <NumberField label="결제금액" source="amount" />
      </Datagrid>
    </List>
  );
};
