import {
  List,
  TextInput,
  Pagination,
  Filter,
  DateField,
  SaveButton,
  Toolbar,
  Datagrid,
  ReferenceField,
  TextField,
  EditBase,
  SimpleForm,
  ReferenceInput,
  AutocompleteInput,
  useRecordContext,
} from "react-admin";
import { Grid, Card, CardContent } from "@material-ui/core";
import { Rating } from "@material-ui/lab";

const ReviewEdit = (props) => {
  const record = useRecordContext();
  if (!record) return null;

  return (
    <Card style={{ maxWidth: 600, margin: "auto" }}>
      <CardContent>
        <EditBase {...props}>
          <SimpleForm sx={{ pt: 0, pb: 0 }} toolbar={<ReviewToolbar />}>
            <Grid container>
              <Grid item xs={12} sm={12}>
                <TextInput
                  label="리뷰"
                  variant="standard"
                  source="content"
                  maxRows={15}
                  multiline
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <Rating value={record?.star || 5} disabled />
              </Grid>
            </Grid>
          </SimpleForm>
        </EditBase>
      </CardContent>
    </Card>
  );
};

export const ReviewToolbar = () => (
  <Toolbar>
    <SaveButton label="Save" />
  </Toolbar>
);

const ReviewFilter = (props) => {
  return (
    <Filter {...props}>
      <ReferenceInput
        label="유저 이메일로 검색"
        source="uid"
        reference="user"
        sort={{ field: "name", order: "ASC" }}
        filterToQuery={(searchText) => ({ email: searchText })}
        allowEmpty={true}
        variant="outlined"
        alwaysOn
      >
        <AutocompleteInput
          label="유저 이메일로 검색"
          variant="outlined"
          optionText="email"
          style={{ minWidth: 200 }}
        />
      </ReferenceInput>
      <TextInput
        alwaysOn
        variant="outlined"
        source="content"
        label="내용으로 검색"
      />
    </Filter>
  );
};

export const MentoringReviewList = (props) => {
  return (
    <List
      {...props}
      title="멘토링 리뷰 관리"
      perPage={20}
      sort={{ field: "created", order: "DESC" }}
      pagination={<Pagination rowsPerPageOptions={[20, 40]} />}
      filters={<ReviewFilter />}
    >
      <Datagrid
        rowClick="expand"
        expand={<ReviewEdit />}
        optimized
        bulkActionButtons={false}
        sx={{ "& *": { textAlign: "center !important" } }}
      >
        <DateField
          variant="outlined"
          source="created"
          label="등록시간"
          showTime
        />
        <ReferenceField label="작성자 이메일" source="uid" reference="user">
          <TextField label="작성자 이메일" source="email" />
        </ReferenceField>
        <ReferenceField label="멘토" source="mentor" reference="mentor">
          <TextField label="멘토" source="displayName" />
        </ReferenceField>
        <TextField label="리뷰" source="content" />
      </Datagrid>
    </List>
  );
};
