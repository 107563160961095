import "react-quill/dist/quill.snow.css";
import { useState, useEffect, useRef, Fragment, useMemo } from "react";
import {
  Create,
  List,
  Edit,
  SimpleForm,
  TextInput,
  required,
  BooleanInput,
  ReferenceInput,
  ImageInput,
  ImageField,
  Pagination,
  useNotify,
  useRefresh,
  useRedirect,
  Filter,
  NumberInput,
  DateInput,
  ArrayInput,
  SimpleFormIterator,
  EditButton,
  SimpleListLoading,
  useListContext,
  RecordContextProvider,
  ExportButton,
  CreateButton,
  TopToolbar,
  SortButton,
  useEditController,
  DateField,
  AutocompleteInput,
  SelectArrayInput,
  ReferenceArrayField,
  ChipField,
  SingleFieldList,
  ReferenceArrayInput,
  ReferenceField,
  NumberField,
  TabbedForm,
  FormTab,
  Toolbar,
  SaveButton,
} from "react-admin";
import {
  Typography,
  Box,
  Grid,
  Card,
  CardContent,
  CardActions,
  Button,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { app } from "~/App";
import {
  getFirestore,
  collection,
  doc,
  setDoc,
  arrayUnion,
  writeBatch,
} from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import MuiChipInput from "~/components/ChipInput";
import ReactQuill, { Quill } from "react-quill";
import loading from "~/styles/images/loading.gif";
import ImageResize from "quill-image-resize-module-react";
import imageCompression from "browser-image-compression";
import { time } from "~/helpers/util";
import { Img } from "react-image";

Quill.register("modules/imageResize", ImageResize);

const useStyles = makeStyles(theme => ({
  input: {
    display: "inline-block",
  },
  wrapper: {
    position: "relative",
  },
  fullVideoWrapper: {
    position: "relative",
  },
  videoWrapper: {
    position: "absolute",
    top: "55%",
    left: "50%",
  },
  helperText: {},
  none: {
    display: "none",
    visibility: "hidden",
    width: 0,
    height: 0,
  },
  classList: {
    position: "relative",
    left: "20%",
    textAlign: "center",
    "& p": {
      maxWidth: "230px",
      overflowWrap: "break-word",
      wordBreak: "break-all",
      fontWeight: 600,
    },
  },
  deactive: {
    "& .RaToolbar-desktopToolbar": {
      display: "none",
    },
  },
}));

const toolbarOptions = [
  ["link", "image", "video"],
  [{ header: [1, 2, 3, false] }],
  ["bold", "italic", "underline", "strike"],
  ["blockquote"],
  [{ list: "ordered" }, { list: "bullet" }],
  [{ color: [] }, { background: [] }],
  [{ align: [] }],
];

export const formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "align",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "background",
  "color",
  "link",
  "image",
  "video",
  "width",
];

// TODO:
// 1. 클래스 FAQ, 후기 관리
// 2. 이미 등록된 강의의 경우 클래스 강의목록에서 노출되지 않도록 처리
// -> 초기값 저장 <-> 저장 후 값 onSuccess 레벨에서 체크 후 DB update
// 3. 스페셜 클래스 -> 구독권으로 시청 가능 여부, 유저 레벨에서 확인 필요

export const ClassesCreate = props => {
  const classes = useStyles();
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();

  // const [content, setContent] = useState("");
  // const quillRef = useRef(null);

  // useEffect(() => {
  //   setTimeout(() => {
  //     if (quillRef.current) {
  //       const toolbar = quillRef.current.getEditor().getModule("toolbar");
  //       toolbar.addHandler("image", handleImage);
  //       console.log("handleImage setup done");
  //     }
  //   }, 1500);
  //   return () => {};
  // });

  // const modules = useMemo(
  //   () => ({
  //     toolbar: {
  //       container: toolbarOptions,
  //     },
  //     imageResize: {
  //       // https://www.npmjs.com/package/quill-image-resize-module-react 참고
  //       parchment: Quill.import("parchment"),
  //       modules: ["Resize", "DisplaySize", "Toolbar"],
  //     },
  //   }),
  //   []
  // );

  // const uploadImage = async (file, filePath) => {
  //   // firebase storage upload and return url
  //   try {
  //     const options = {
  //       maxSizeMB: 0.25,
  //       maxWidthOrHeight: 1000,
  //       useWebWorker: true,
  //     };
  //     const compressedFile = await imageCompression(file, options);
  //     const storage = getStorage(app[0]);
  //     const contentRef = ref(storage, `${filePath}`);
  //     const snapshot = await uploadBytes(contentRef, compressedFile);
  //     return await getDownloadURL(snapshot.ref);
  //   } catch (error) {
  //     console.log("uploadImage error: ", error);
  //     return null;
  //   }
  // };

  // const handleImage = async () => {
  //   const { getEditor } = quillRef.current;
  //   const input = document.createElement("input");
  //   input.setAttribute("type", "file");
  //   input.setAttribute("accept", "image/*");
  //   input.click();
  //   input.onchange = async () => {
  //     const file = input.files[0];
  //     // 현재 커서 위치 저장
  //     const range = getEditor().getSelection(true);
  //     getEditor().insertEmbed(range.index, "image", loading);
  //     try {
  //       const filePath = `classes/content/${Date.now()}`;
  //       const url = await uploadImage(file, filePath);

  //       if (!url) {
  //         window.alert(
  //           "사진을 업로드하는데 오류가 있습니다. 잠시 후에 다시 시도해주세요."
  //         );
  //         return;
  //       }
  //       // 정상적으로 업로드 됐다면 로딩 placeholder 삭제
  //       getEditor().deleteText(range.index, 1);
  //       // 받아온 url을 이미지 태그에 삽입
  //       getEditor().insertEmbed(range.index, "image", url);
  //       // 사용자 편의를 위해 커서 이미지 오른쪽으로 이동
  //       getEditor().setSelection(range.index + 1);
  //     } catch (e) {
  //       getEditor().deleteText(range.index, 1);
  //     }
  //   };
  // };

  // 강연자의 강의 목록 array에 추가
  // FIXME: 테스트 필요, 강연 수정에서 content(상세내용) 따로 저장

  const onSuccess = async data => {
    try {
      const firestore = getFirestore(app[0]);
      const batch = writeBatch(firestore);
      // const classesRef = doc(collection(firestore, "classes"), data.id);

      const lecturerRef = doc(collection(firestore, "lecturer"), data.lecturer);
      const ratingRef = doc(collection(firestore, "rating"), data.id);

      batch.update(lecturerRef, {
        classes: arrayUnion(data.id),
        first: false,
      });
      batch.set(ratingRef, {
        type: 0, // 클래스 타입 (추후 멘토링 고려)
        id: data.id, // 클래스 id
        view: 0, // 조회수
        review: 0, // 리뷰수
        payment: 0, // 클래스 개별 결제수
        refund: 0, // 클래스 개별 환불수
        like: 0, // 공감수
        share: 0, // 공유수
      });

      await batch.commit();

      // await setDoc(doc(collection(getFirestore(app[0]), "classes"), data.id), { content },{ merge: true });
      // await setDoc(doc(collection(getFirestore(app[0]), "lecturer"), data.lecturer), { classes: arrayUnion(data.id), first: false }, { merge: true } );

      notify(`저장 완료`);
      redirect("/classes");
      refresh();
    } catch (error) {
      console.log(`${error.message}`);
      notify(`일부데이터 생성 오류 발생`);
      redirect("/classes");
      refresh();
    }
  };

  const transform = data => ({
    ...data,
    // rating: JSON.parse(data.rating), // 별도 컬렉션으로 뺌
    created: new Date().getTime(),
    discountStartDate: new Date(data.discountStartDate).getTime(),
    discountEndDate: new Date(data.discountEndDate).getTime(),
  });

  return (
    <Create
      title="클래스 등록"
      {...props}
      mutationOptions={{ onSuccess }}
      transform={transform}
      // redirect="list"
    >
      <SimpleForm warnWhenUnsavedChanges submitOnEnter={false}>
        <Grid container>
          <Grid item xs={12} sm={7}>
            <Box maxWidth="70rem">
              <Grid container className={classes.wrapper}>
                <Grid item xs={12} sm={12}>
                  <Typography component="span" variant="caption">
                    (*)는 필수 입력란입니다.
                  </Typography>
                  <br />
                  <Typography
                    component="span"
                    variant="caption"
                    style={{
                      fontWeight: 600,
                    }}
                  >
                    *클래스 상세 내용은 강의 등록 후 수정 화면에서 진행해주세요.
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <ReferenceInput
                    variant="outlined"
                    label="섹션 구분"
                    source="section"
                    reference="section"
                    sort={{ field: "createdate", order: "DESC" }}
                  >
                    <AutocompleteInput
                      fullWidth
                      variant="outlined"
                      label="섹션 구분"
                      optionText="title"
                      optionValue="title"
                      validate={[required()]}
                    />
                  </ReferenceInput>
                </Grid>
                <Grid item xs={12} sm={8}>
                  <ReferenceArrayInput
                    variant="outlined"
                    label="강연자"
                    source="lecturer"
                    reference="lecturer"
                    sort={{ field: "createdate", order: "DESC" }}
                  >
                    <SelectArrayInput
                      fullWidth
                      variant="outlined"
                      label="강연자"
                      validate={[required()]}
                      optionText="displayName"
                      options={{ autoWidth: true }}
                    />
                  </ReferenceArrayInput>
                </Grid>
                <Grid item xs={12} sm={2}>
                  <BooleanInput
                    defaultValue={false}
                    label="클래스 오픈"
                    source="isShow"
                  />
                </Grid>
                {/* <Grid item xs={12} sm={2}><BooleanInput validate={[required()]} defaultValue={true} label="구독권" source="isSubscribe"/></Grid> */}
                <Grid item xs={12} sm={2}>
                  <BooleanInput
                    defaultValue={true}
                    label="리뷰 오픈"
                    source="commentable"
                    fullWidth={false}
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <BooleanInput
                    defaultValue={false}
                    label="무료클래스"
                    source="isFree"
                    fullWidth={false}
                  />
                </Grid>
                <Grid container>
                  <Grid item xs={12} sm={8}>
                    <TextInput
                      label="클래스 제목"
                      variant="outlined"
                      source="title"
                      fullWidth
                      validate={[required()]}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <TextInput
                      label="클래스 소개"
                      variant="outlined"
                      source="intro"
                      validate={[required()]}
                      fullWidth
                      multiline
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <ImageInput
                    source="thumbnail"
                    label="클래스 썸네일"
                    accept="image/*"
                    validate={[required()]}
                  >
                    <ImageField source="src" title="title" />
                  </ImageInput>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <ImageInput
                    source="banner"
                    label="클래스 상세소개 배너(gif 권장)"
                    accept="image/*"
                    validate={[required()]}
                  >
                    <ImageField source="src" title="title" />
                  </ImageInput>
                </Grid>
                <Grid container style={{ paddingTop: 25 }}>
                  <Grid item xs={12} sm={3}>
                    <NumberInput
                      variant="outlined"
                      source="salePrice"
                      label="클래스 가격(원)"
                      defaultValue={50000}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <NumberInput
                      variant="outlined"
                      source="discount"
                      label="클래스 할인율(%)"
                      defaultValue={0}
                      min={0}
                      max={100}
                    />
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <DateInput
                      variant="outlined"
                      source="discountStartDate"
                      label="할인시작일"
                      defaultValue={new Date()}
                    />
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <DateInput
                      variant="outlined"
                      source="discountEndDate"
                      label="할인종료일"
                      defaultValue={new Date()}
                    />
                  </Grid>
                </Grid>
                {/* <Grid item xs={12} sm={12}><Typography component="p" variant="subtitle2">클래스 상세내용</Typography><ReactQuill ref={quillRef} placeholder={"내용을 작성해주세요."} value={content || ""} theme="snow" modules={{ ...modules, imageResize: { parchment: Quill.import("parchment"), modules: ["Resize", "DisplaySize", "Toolbar"]} }} formats={formats} preserveWhitespace onChange={(content) => { setContent(content); }} /></Grid> */}
                <Grid item xs={12} sm={12} style={{ paddingTop: 5 }}>
                  <MuiChipInput label="클래스 태그" source="tag" />
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12} sm={5}>
            <Grid container>
              <Grid item xs={12} sm={12}>
                <Typography component="p" variant="h6" align="center">
                  클래스 강의목록
                </Typography>
                <Typography component="p" variant="subtitle2" align="center">
                  *프리뷰 영상을 가장 첫 번째로 위치합니다.
                </Typography>
              </Grid>
              <Grid item xs={12} sm={9} className={classes.classList}>
                <ArrayInput source="lectures" label="강의목록">
                  <SimpleFormIterator>
                    <ReferenceInput
                      fullWidth
                      label="강의명"
                      source="id"
                      reference="lecture"
                      variant="outlined"
                    >
                      <AutocompleteInput
                        fullWidth
                        label="강의명"
                        variant="outlined"
                        filterToQuery={searchText => ({ title: searchText })}
                        optionValue="id"
                        optionText="title"
                        style={{ minWidth: 300 }}
                      />
                    </ReferenceInput>
                  </SimpleFormIterator>
                </ArrayInput>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </SimpleForm>
    </Create>
  );
};

export const ClassesEdit = props => {
  const classes = useStyles();
  const [content, setContent] = useState("");
  const [state, setState] = useState({
    tab: 0,
  });

  const quillRef = useRef(null);
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();
  const controllerProps = useEditController(props);
  const { record, save, isLoading } = controllerProps;

  useEffect(() => {
    if (record && (!content || content === "")) {
      setContent({ content: record.content });
      if (quillRef.current) {
        const { getEditor } = quillRef.current;
        const toolbar = quillRef.current.getEditor().getModule("toolbar");
        toolbar.addHandler("image", handleImage);
        getEditor().clipboard.dangerouslyPasteHTML(record.content);
        // console.log("handleImage setup done");
      }
    }
    return () => {};
  }, [record]);

  const modules = useMemo(
    () => ({
      toolbar: {
        container: toolbarOptions,
      },
      imageResize: {
        // https://www.npmjs.com/package/quill-image-resize-module-react 참고
        parchment: Quill.import("parchment"),
        modules: ["Resize", "DisplaySize", "Toolbar"],
      },
    }),
    []
  );

  /** TODO:
   *
   * - 강연 데이터 불러와서 하나도 없으면 first true로 변경 -> 정산을 위해 필요?
   * - 관리자가 자체적으로 변경 가능하게 할지 고민 -> 기본 Auto
   * - 클래스 삭제 시, 강연자 컬렉션의 클래스 데이터 또한 삭제
   */

  const onSuccess = data => {
    // console.log("onSuccess: ", data);
    notify(`저장 완료`);
    redirect("/classes");
    refresh();
  };

  const onContentSave = async () => {
    try {
      if (window.confirm("저장하시겠습니까? 이 작업은 되돌릴 수 없습니다.")) {
        console.log("content save: ", content);
        await setDoc(
          doc(collection(getFirestore(app[0]), "classes"), record.id),
          {
            content: content,
          },
          {
            merge: true,
          }
        );
        notify(`저장되었습니다.`);
      }
    } catch (error) {
      console.log(error);
      notify(`오류가 있습니다. ${error.message}`);
    }
  };

  const uploadImage = async (file, filePath) => {
    // firebase storage upload and return url
    try {
      const options = {
        maxSizeMB: 0.25,
        maxWidthOrHeight: 1000,
        useWebWorker: true,
      };
      const compressedFile = await imageCompression(file, options);
      const storage = getStorage(app[0]);
      const contentRef = ref(storage, `${filePath}`);
      const snapshot = await uploadBytes(contentRef, compressedFile);
      return await getDownloadURL(snapshot.ref);
    } catch (error) {
      console.log("uploadImage error: ", error);
      return null;
    }
  };

  const handleImage = async () => {
    const { getEditor } = quillRef.current;
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();
    input.onchange = async () => {
      const file = input.files[0];
      // 현재 커서 위치 저장
      const range = getEditor().getSelection(true);
      getEditor().insertEmbed(range.index, "image", loading);
      try {
        const filePath = `classes/content/${Date.now()}`;
        const url = await uploadImage(file, filePath);

        if (!url) {
          window.alert(
            "사진을 업로드하는데 오류가 있습니다. 잠시 후에 다시 시도해주세요."
          );
          return;
        }
        // 정상적으로 업로드 됐다면 로딩 placeholder 삭제
        getEditor().deleteText(range.index, 1);
        // 받아온 url을 이미지 태그에 삽입
        getEditor().insertEmbed(range.index, "image", url);
        // 사용자 편의를 위해 커서 이미지 오른쪽으로 이동
        getEditor().setSelection(range.index + 1);
      } catch (e) {
        getEditor().deleteText(range.index, 1);
      }
    };
  };

  const onHanldeContent = content => {
    setContent(content);
  };

  const transform = data => ({
    ...data,
    discountStartDate: new Date(data.discountStartDate).getTime(),
    discountEndDate: new Date(data.discountEndDate).getTime(),
    section: data.section,
  });

  const onClickTab = tab => {
    setState(prev => ({ ...prev, tab }));
  };

  return (
    <Edit
      {...props}
      title="클래스 수정"
      mutationOptions={{ onSuccess }}
      transform={transform}
      redirect="list"
    >
      <TabbedForm
        warnWhenUnsavedChanges
        submitOnEnter={false}
        record={record}
        onSubmit={save}
        toolbar={
          state.tab === 0 ? (
            <Toolbar>
              <SaveButton label="Save" />
            </Toolbar>
          ) : (
            false
          )
        }
      >
        <FormTab
          label="클래스 정보"
          onClick={() => {
            onClickTab(0);
          }}
        >
          <Grid container>
            <Grid item xs={12} sm={7}>
              <Box maxWidth="70rem">
                <Grid container className={classes.wrapper}>
                  <Grid item xs={12} sm={12} style={{ marginBottom: "10px" }}>
                    <Typography component="span" variant="caption">
                      (*)는 필수 입력란입니다.
                    </Typography>
                    <Typography>
                      {"등록시간: "}
                      <DateField source="created" showTime />
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextInput
                      label="고유번호"
                      variant="outlined"
                      source="id"
                      fullWidth
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <ReferenceInput
                      variant="outlined"
                      label="카테고리"
                      source="section"
                      reference="section"
                      sort={{ field: "createdate", order: "DESC" }}
                    >
                      <AutocompleteInput
                        fullWidth
                        variant="outlined"
                        label="섹션 구분"
                        optionText="title"
                        optionValue="title"
                        validate={[required()]}
                      />
                    </ReferenceInput>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <ReferenceArrayInput
                      variant="outlined"
                      label="강연자"
                      source="lecturer"
                      reference="lecturer"
                      sort={{ field: "createdate", order: "DESC" }}
                    >
                      <SelectArrayInput
                        fullWidth
                        variant="outlined"
                        label="강연자"
                        optionText="displayName"
                        validate={[required()]}
                      />
                    </ReferenceArrayInput>
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <BooleanInput
                      defaultValue={false}
                      label="클래스 오픈"
                      source="isShow"
                    />
                  </Grid>
                  {/* <Grid item xs={12} sm={2}><BooleanInput validate={[required()]} defaultValue={true} label="구독권" source="isSubscribe"/></Grid> */}
                  <Grid item xs={12} sm={2}>
                    <BooleanInput
                      defaultValue={true}
                      label="리뷰 오픈"
                      source="commentable"
                      fullWidth={false}
                    />
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <BooleanInput
                      defaultValue={false}
                      label="무료클래스"
                      source="isFree"
                      fullWidth={false}
                    />
                  </Grid>
                  <Grid item xs={12} sm={8}>
                    <TextInput
                      label="클래스 제목"
                      variant="outlined"
                      source="title"
                      fullWidth
                      validate={[required()]}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <TextInput
                      label="클래스 소개"
                      variant="outlined"
                      source="intro"
                      validate={[required()]}
                      fullWidth
                      multiline
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <ImageInput
                      source="thumbnail"
                      label="클래스 썸네일"
                      accept="image/*"
                      validate={[required()]}
                    >
                      <ImageField source="src" title="title" />
                    </ImageInput>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <ImageInput
                      source="banner"
                      label="클래스 상세소개 배너(gif 권장)"
                      accept="image/*"
                      validate={[required()]}
                    >
                      <ImageField source="src" title="title" />
                    </ImageInput>
                  </Grid>
                  <Grid container style={{ paddingTop: 25 }}>
                    <Grid item xs={12} sm={3}>
                      <NumberInput
                        variant="outlined"
                        source="salePrice"
                        label="클래스 가격(원)"
                        defaultValue={50000}
                      />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <NumberInput
                        variant="outlined"
                        source="discount"
                        label="클래스 할인율(%)"
                        defaultValue={0}
                        min={0}
                        max={100}
                      />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <DateInput
                        variant="outlined"
                        source="discountStartDate"
                        label="할인시작일"
                        defaultValue={new Date()}
                      />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <DateInput
                        variant="outlined"
                        source="discountEndDate"
                        label="할인종료일"
                        defaultValue={new Date()}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={12} style={{ paddingTop: 5 }}>
                    <MuiChipInput label="클래스 태그" source="tag" />
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid item xs={12} sm={5}>
              <Grid container>
                <Grid item xs={12} sm={12}>
                  <Typography component="p" variant="h6" align="center">
                    클래스 강의목록
                  </Typography>
                  <Typography component="p" variant="subtitle2" align="center">
                    *프리뷰 영상을 가장 첫 번째로 위치합니다.
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={9} className={classes.classList}>
                  <ArrayInput source="lectures" label="강의목록" fullWidth>
                    <SimpleFormIterator>
                      <ReferenceInput
                        label="강의명"
                        source="id"
                        reference="lecture"
                        variant="outlined"
                        validate={[required()]}
                        fullWidth
                      >
                        <AutocompleteInput
                          fullWidth
                          label="강의명"
                          variant="outlined"
                          filterToQuery={searchText => ({
                            title: searchText,
                          })}
                          optionValue="id"
                          optionText="title"
                          style={{ minWidth: 300 }}
                        />
                      </ReferenceInput>
                    </SimpleFormIterator>
                  </ArrayInput>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </FormTab>
        <FormTab
          label="클래스 내용"
          onClick={() => {
            onClickTab(1);
          }}
        >
          <Grid container>
            <Grid item xs={12} sm={12}>
              <Typography component="p" variant="subtitle2">
                <Button
                  variant="contained"
                  onClick={onContentSave}
                  style={{
                    color: "#fff",
                    background: "#f02222",
                    marginBottom: "5px",
                  }}
                >
                  수정사항 저장
                </Button>{" "}
                {/* <Typography component="span" variant="subtitle2" gutterBottom>*하단의 'SAVE' 버튼은 동작하지 않습니다.</Typography> */}
              </Typography>
              <Typography
                component="p"
                variant="caption"
                gutterBottom
                style={{ fontWeight: 700 }}
              >
                클래스 상세 내용(텍스트)은 흰색(#ffffff)으로 표현됩니다.
              </Typography>
              <ReactQuill
                ref={quillRef}
                placeholder={"내용을 작성해주세요."}
                value={content || ""}
                theme="snow"
                modules={{
                  ...modules,
                  imageResize: {
                    parchment: Quill.import("parchment"),
                    modules: ["Resize", "DisplaySize", "Toolbar"],
                  },
                }}
                formats={formats}
                preserveWhitespace
                // onChange={(content, delta, source, editor) => setContent(editor.getHTML())}
                onChange={content => {
                  onHanldeContent(content);
                }}
              />
            </Grid>
          </Grid>
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};

const ClassesFilter = props => {
  return (
    <Filter {...props}>
      <TextInput
        alwaysOn
        variant="outlined"
        source="title"
        label="클래스 제목으로 검색"
      />
    </Filter>
  );
};

const ClassesGrid = () => {
  const {
    data: classes,
    isLoading,
    // onToggleItem,
    // selectedIds,
  } = useListContext();

  if (isLoading) {
    return <SimpleListLoading hasLeftAvatarOrIcon hasSecondaryText />;
  }
  return classes && classes.length ? (
    <Fragment>
      <Grid container spacing={2}>
        {classes.map(data => (
          <RecordContextProvider key={data.id} value={data}>
            <Grid key={data.id} item xs={12} sm={3}>
              <Card
                style={{
                  padding: "10px",
                  margin: "10px",
                  background: "#f9f9f9",
                }}
              >
                {data.thumbnail && (
                  <Img
                    src={data.thumbnail.src}
                    alt={""}
                    style={{
                      width: "100%",
                      height: "auto",
                    }}
                  />
                )}
                <CardContent>
                  <Typography
                    variant="body1"
                    component="h5"
                    align="center"
                    style={{ fontWeight: 700 }}
                  >
                    {data.title}
                  </Typography>
                  <Typography
                    variant="body1"
                    component="h6"
                    align="center"
                    gutterBottom
                  >
                    등록일: {time.transformKR(data.created)}
                  </Typography>
                  <Typography
                    variant="body1"
                    component="h5"
                    align="center"
                    gutterBottom
                  >
                    <ReferenceField source="id" reference="rating">
                      조회수:{" "}
                      <NumberField
                        source="view"
                        label="조회수"
                        defaultValue={0}
                      />
                    </ReferenceField>
                    {", "}
                    <ReferenceField source="id" reference="rating">
                      공감수:{" "}
                      <NumberField
                        source="like"
                        label="공감수"
                        defaultValue={0}
                      />
                    </ReferenceField>
                  </Typography>
                  <Typography variant="body1" component="h5" align="center">
                    <ReferenceArrayField
                      label="lecturer"
                      reference="lecturer"
                      source="lecturer"
                    >
                      <SingleFieldList>
                        <ChipField source="displayName" />
                      </SingleFieldList>
                    </ReferenceArrayField>
                  </Typography>
                </CardContent>
                <CardActions
                  style={{
                    display: "flex",
                    justifyContent: "space-around",
                  }}
                >
                  <EditButton resource="classes" record={data} />
                </CardActions>
              </Card>
            </Grid>
          </RecordContextProvider>
        ))}
      </Grid>
    </Fragment>
  ) : null;
};

const ListActions = () => (
  <TopToolbar sx={{ minHeight: { sm: 56 } }}>
    <SortButton label="정렬" fields={["created"]} />
    <CreateButton />
    <ExportButton />
  </TopToolbar>
);

export const ClassesList = props => {
  return (
    <List
      {...props}
      sort={{ field: "created", order: "DESC" }}
      title="클래스 관리"
      perPage={20}
      pagination={<Pagination rowsPerPageOptions={[20, 40]} />}
      filters={<ClassesFilter />}
      actions={<ListActions />}
    >
      <ClassesGrid />
    </List>
  );
};
