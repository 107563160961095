import { Fragment } from "react";
import {
  List,
  Create,
  Edit,
  SimpleForm,
  EditButton,
  ImageInput,
  ImageField,
  ArrayInput,
  SimpleFormIterator,
  required,
  TextInput,
  Toolbar,
  SaveButton,
  SimpleListLoading,
  TopToolbar,
  RecordContextProvider,
  useListContext,
  BooleanInput,
} from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import {
  Typography,
  Grid,
  Card,
  CardContent,
  CardActions,
} from "@material-ui/core";
import { time } from "~/helpers/util";
// design 관리

const useStyles = makeStyles(theme => ({
  wrapper: {
    position: "relative",
    width: "100%",
    "&>div": {
      padding: theme.spacing(2),
    },
  },
  none: {
    display: "none",
  },
}));

// home banner, bgvideo, mentoring banner image
export const DesignCreate = props => {
  const classes = useStyles();

  const transform = data => ({
    ...data,
    created: new Date().getTime(),
  });

  return (
    <Create {...props} title="메인 등록" transform={transform}>
      <SimpleForm>
        <Grid container className={classes.wrapper}>
          <Grid item xs={12} sm={4}>
            <Grid container>
              <Grid
                item
                xs={12}
                sm={12}
                style={{
                  marginBottom: "15px",
                }}
              >
                <Typography component="span" variant="caption">
                  *메인에 노출되는 배너입니다.
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12}>
                <ArrayInput
                  source="main"
                  label="메인배너"
                  required={[required()]}
                >
                  <SimpleFormIterator>
                    <ImageInput
                      source="banner"
                      label="메인배너"
                      accept="image/*"
                      fullWidth
                      defaultValue={""}
                    >
                      <ImageField
                        source="src"
                        title="title"
                        defaultValue={""}
                      />
                    </ImageInput>
                    <ImageInput
                      source="moBanner"
                      label="모바일 메인배너"
                      accept="image/*"
                      fullWidth
                      defaultValue={""}
                    >
                      <ImageField
                        source="src"
                        title="title"
                        defaultValue={""}
                      />
                    </ImageInput>
                    <TextInput
                      variant="outlined"
                      source="title"
                      label="타이틀"
                    />
                    <TextInput
                      variant="outlined"
                      source="content"
                      label="소개"
                    />
                    <TextInput
                      require={[required()]}
                      variant="outlined"
                      source="url"
                      label="링크"
                    />
                    <BooleanInput
                      require={[required()]}
                      variant="outlined"
                      source="type"
                      label="외부 링크 여부"
                      defaultValue={false}
                    />
                    <BooleanInput
                      require={[required()]}
                      variant="outlined"
                      source="isShow"
                      label="노출 여부"
                      defaultValue={true}
                    />
                  </SimpleFormIterator>
                </ArrayInput>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={4}></Grid>
        </Grid>
      </SimpleForm>
    </Create>
  );
};

export const DesignToolbar = () => (
  <Toolbar>
    <SaveButton label="Save" />
  </Toolbar>
);

export const DesignEdit = props => {
  const classes = useStyles();
  return (
    <Edit {...props} title="메인 수정">
      <SimpleForm redirect="list" toolbar={<DesignToolbar />}>
        <Grid container className={classes.wrapper}>
          <Grid item xs={12} sm={4}>
            <Grid container>
              <Grid
                item
                xs={12}
                sm={12}
                style={{
                  marginBottom: "15px",
                }}
              >
                <Typography component="span" variant="subtitle2">
                  *메인에 노출되는 배너입니다.
                </Typography>
                <br />
                <Typography component="span" variant="subtitle2">
                  *링크는 클래스의 고유번호를 입력합니다.
                </Typography>
                <br />
                <Typography component="span" variant="subtitle2">
                  *이미지 크기 (w: 1080px x h: 607px)
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12}>
                <ArrayInput
                  source="main"
                  label="메인배너"
                  required={[required()]}
                >
                  <SimpleFormIterator>
                    <ImageInput
                      source="banner"
                      label="메인배너"
                      accept="image/*"
                      fullWidth
                      defaultValue={""}
                      required={[required()]}
                    >
                      <ImageField
                        source="src"
                        title="title"
                        defaultValue={""}
                      />
                    </ImageInput>
                    <ImageInput
                      source="moBanner"
                      label="모바일 메인배너"
                      accept="image/*"
                      fullWidth
                      defaultValue={""}
                    >
                      <ImageField
                        source="src"
                        title="title"
                        defaultValue={""}
                      />
                    </ImageInput>
                    <TextInput
                      variant="outlined"
                      source="title"
                      label="타이틀"
                      defaultValue=""
                    />
                    <TextInput
                      variant="outlined"
                      source="content"
                      label="소개"
                      defaultValue=""
                    />
                    <TextInput
                      require={[required()]}
                      variant="outlined"
                      source="url"
                      label="링크"
                      defaultValue=""
                    />
                    <BooleanInput
                      require={[required()]}
                      variant="outlined"
                      source="type"
                      label="외부 링크 여부"
                      defaultValue={false}
                    />
                    <BooleanInput
                      require={[required()]}
                      variant="outlined"
                      source="isShow"
                      label="노출 여부"
                      defaultValue={true}
                    />
                  </SimpleFormIterator>
                </ArrayInput>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Grid container>
              <Grid item xs={12} sm={12}>
                <Typography component="span" variant="subtitle2">
                  *메인에 노출되는 멘토링 섬네일 이미지 입니다.
                </Typography>
                <br />
                <Typography component="span" variant="subtitle2">
                  *이미지 크기 (w: 600px x h: 812px)
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12}>
                <ArrayInput
                  source="mentor"
                  label="멘토링 이미지"
                  required={[required()]}
                >
                  <SimpleFormIterator>
                    <ImageInput
                      source="thumbnail"
                      label="멘토링 섬네일 이미지"
                      accept="image/*"
                      fullWidth
                      defaultValue={""}
                      required={[required()]}
                    >
                      <ImageField
                        source="src"
                        title="title"
                        defaultValue={""}
                      />
                    </ImageInput>
                  </SimpleFormIterator>
                </ArrayInput>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </SimpleForm>
    </Edit>
  );
};

const DesignGrid = () => {
  const { data: design, isLoading } = useListContext();

  if (isLoading) {
    return <SimpleListLoading hasLeftAvatarOrIcon hasSecondaryText />;
  }
  return design && design.length ? (
    <Fragment>
      <Grid container spacing={2}>
        {design.map(data => (
          <RecordContextProvider key={data.id} value={data}>
            <Grid key={data.id} item xs={12} sm={3}>
              <Card
                style={{
                  padding: "10px",
                  margin: "10px",
                  background: "#f9f9f9",
                }}
              >
                <CardContent>
                  <Typography
                    variant="body1"
                    component="h5"
                    align="center"
                    style={{ fontWeight: 700 }}
                  >
                    메인 콘텐츠 관리
                  </Typography>
                  <Typography variant="body1" component="h6" align="center">
                    등록일: {time.transformKR(data.created)}
                  </Typography>
                </CardContent>
                <CardActions
                  style={{
                    display: "flex",
                    justifyContent: "space-around",
                  }}
                >
                  <EditButton resource="design" record={data} />
                </CardActions>
              </Card>
            </Grid>
          </RecordContextProvider>
        ))}
      </Grid>
    </Fragment>
  ) : null;
};

const ListActions = () => (
  <TopToolbar sx={{ minHeight: { sm: 56 } }}></TopToolbar>
);

export const DesignList = props => {
  return (
    <List
      {...props}
      title="메인 관리"
      perPage={20}
      sort={{ field: "createdate", order: "DESC" }}
      actions={<ListActions />}
    >
      <DesignGrid />
    </List>
  );
};
