import {
  Create,
  List,
  Edit,
  SimpleForm,
  TextInput,
  required,
  Datagrid,
  DateField,
  EditButton,
  TextField,
  Pagination,
  DateInput,
  Filter,
  NumberInput,
  SelectInput,
} from "react-admin";
import { Typography, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

// 쿠폰 관리
const useStyles = makeStyles(theme => ({
  wrapper: {
    position: "relative",
    width: "100%",
    "&>div": {
      padding: theme.spacing(2),
    },
  },
  none: {
    display: "none",
    visibility: "hidden",
    width: 0,
    height: 0,
  },
  input: {
    "& *": {
      marginTop: theme.spacing(0.5),
      padding: "12.5px !important",
    },
  },
  darkInput: {
    "& *": {
      color: "rgba(0, 0, 0, 0.6) !important",
      textFillColor: "#141414 !important",
    },
  },
}));

// TODO:
// > 쿠폰으로 단체 사용자 적용하는 방법 명시
export const CouponCreate = props => {
  const classes = useStyles();

  return (
    <Create title="쿠폰 등록" {...props}>
      <SimpleForm warnWhenUnsavedChanges redirect="list">
        <Grid container className={classes.wrapper}>
          <Grid item xs={12} sm={12}>
            <Typography component="span" variant="caption">
              (*)는 필수 입력란입니다.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <NumberInput
              label="현재사용인원"
              defaultValue={0}
              source="currentVolume"
              className={classes.none}
              fullWidth
            />
            <TextInput
              label="쿠폰이름"
              variant="outlined"
              source="title"
              validate={[required()]}
              helperText="*가시성을 위해 쿠폰 명칭에 '올인원', '클래스' 포함"
              fullWidth
            />
            <NumberInput
              label="할인율(%)"
              defaultValue={0}
              variant="outlined"
              source="discount"
              validate={[required()]}
              fullWidth
              max={100}
              min={0}
              style={{ marginTop: "10px" }}
            />
            <NumberInput
              label="해당 쿠폰 사용 가능 인원"
              defaultValue={0}
              variant="outlined"
              source="volume"
              validate={[required()]}
              fullWidth
              helperText="*0은 기본값(제한없음)"
              min={0}
            />
            <SelectInput
              source="type"
              fullWidth
              variant="outlined"
              label="사용 가능 범위"
              choices={[
                {
                  id: 0,
                  title: "올인원 전용",
                },
                {
                  id: 1,
                  title: "클래스 전용",
                },
                {
                  id: 2,
                  title: "멘토링 전용",
                },
              ]}
              optionText="title"
              validate={[required()]}
            />
            <DateInput
              variant="outlined"
              source="expired"
              label="쿠폰유효기간"
              validate={[required()]}
              defaultValue={new Date(9999, 0, 1)}
              helperText="*9999-01-01은 기본값"
            />
            <TextInput
              multiline
              label="메모"
              variant="outlined"
              source="memo"
              fullWidth
              defaultValue={""}
              style={{ marginTop: "10px" }}
            />
          </Grid>
        </Grid>
      </SimpleForm>
    </Create>
  );
};

export const CouponEdit = props => {
  const classes = useStyles();

  return (
    <Edit title="쿠폰 등록" {...props}>
      <SimpleForm warnWhenUnsavedChanges redirect="list">
        <Grid container className={classes.wrapper}>
          <Grid item xs={12} sm={12}>
            <Typography component="span" variant="caption">
              (*)는 필수 입력란입니다.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextInput
              label="쿠폰번호"
              variant="outlined"
              source="id"
              disabled
              fullWidth
              className={classes.darkInput}
            />
            <TextInput
              label="쿠폰이름"
              variant="outlined"
              source="title"
              validate={[required()]}
              helperText="*가시성을 위해 쿠폰 명칭에 '올인원', '클래스' 포함"
              fullWidth
            />
            <NumberInput
              label="할인율(%)"
              defaultValue={0}
              variant="outlined"
              source="discount"
              validate={[required()]}
              fullWidth
              max={100}
              min={0}
              style={{ marginTop: "10px" }}
            />
            <NumberInput
              label="해당 쿠폰 사용 가능 인원"
              defaultValue={0}
              variant="outlined"
              source="volume"
              validate={[required()]}
              fullWidth
              helperText="*0은 기본값(제한없음)"
              min={0}
            />
            <SelectInput
              source="type"
              fullWidth
              variant="outlined"
              label="사용 가능 범위"
              choices={[
                {
                  id: 0,
                  title: "올인원 전용",
                },
                {
                  id: 1,
                  title: "클래스 전용",
                },
                {
                  id: 2,
                  title: "멘토링 전용",
                },
              ]}
              optionText="title"
              validate={[required()]}
            />
            <DateInput
              variant="outlined"
              source="expired"
              label="쿠폰 만료일"
              validate={[required()]}
              defaultValue={new Date(9999, 0, 1)}
              helperText="*9999-01-01은 기본값(만료기간 없음)"
            />
            <TextInput
              multiline
              label="메모"
              variant="outlined"
              source="memo"
              fullWidth
              defaultValue={""}
              style={{ marginTop: 10 }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            {/* TODO: 
             // > 쿠폰 사용 이력 및 쿠폰 사용 인원 노출
             // > formConsumer 사용 */}
          </Grid>
        </Grid>
      </SimpleForm>
    </Edit>
  );
};

const CouponFilter = props => {
  return (
    <Filter {...props}>
      <TextInput
        alwaysOn
        variant="outlined"
        source="title"
        label="쿠폰 이름으로 검색"
      />
    </Filter>
  );
};

export const CouponList = props => {
  return (
    <List
      {...props}
      title="쿠폰 관리"
      perPage={20}
      sort={{ field: "created", order: "DESC" }}
      pagination={<Pagination rowsPerPageOptions={[20, 40]} />}
      filters={<CouponFilter />}
    >
      <Datagrid
        bulkActionButtons={false}
        sx={{
          "& *": {
            textAlign: "center !important",
          },
        }}
      >
        <DateField source="createdate" label="등록시간" showTime />
        <TextField label="쿠폰번호" variant="outlined" source="id" />
        <TextField label="쿠폰이름" variant="outlined" source="title" />
        <TextField label="할인율" variant="outlined" source="discount" />
        <EditButton basePath={"/coupon"} label="수정" />
      </Datagrid>
    </List>
  );
};
