import { AppBar, ToggleThemeButton, defaultTheme } from "react-admin";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  title: {
    flex: 1,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  spacer: {
    flex: 1,
  },
});

const lightTheme = {
  palette: {
    type: "light",
    primary: {
      main: "#3f51b5",
    },
    secondary: {
      main: "#f02222",
    },
    disabled: {
      main: "#141414",
    },
  },
};

const darkTheme = {
  palette: { mode: "light" },
};

const Header = (props) => {
  const classes = useStyles();
  return (
    <AppBar {...props}>
      <Typography
        variant="h6"
        color="inherit"
        className={classes.title}
        id="react-admin-title"
      />
      {/* darkTheme={darkTheme}  */}
      <ToggleThemeButton lightTheme={lightTheme} />
    </AppBar>
  );
};

export default Header;
