import { useEffect } from "react";
import {
  List,
  Create,
  Edit,
  SimpleForm,
  required,
  Datagrid,
  DateField,
  EditButton,
  Pagination,
  BooleanInput,
  BooleanField,
  TextInput,
  ImageField,
  useRefresh,
  useNotify,
  useRedirect,
  ImageInput,
  UrlField,
  NumberInput,
  NumberField,
} from "react-admin";
import { Typography, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    position: "relative",
    width: "100%",
    "&>div": {
      padding: theme.spacing(2),
    },
  },
  none: {
    display: "none",
  },
}));

export const ProductCreate = (props) => {
  const classes = useStyles();
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();

  useEffect(() => {
    return () => {};
  }, []);

  const onSuccess = (data) => {
    notify(`저장 완료`);
    redirect("/product");
    refresh();
  };

  const transform = (data) => ({
    ...data,
  });

  return (
    <Create
      title="상품 등록"
      {...props}
      mutationOptions={{ onSuccess }}
      transform={transform}
    >
      <SimpleForm warnWhenUnsavedChanges redirect="list">
        <Grid container className={classes.wrapper}>
          <Grid item xs={12} sm={12}>
            <Typography component="span" variant="caption">
              (*)는 필수 입력란입니다.
            </Typography>
          </Grid>
          <Grid container>
            <Grid item xs={12} sm={4}>
              <BooleanInput
                label="노출여부"
                source="isShow"
                defaultValue={true}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} sm={4}>
            <ImageInput
              source="thumbnail"
              label="썸네일"
              accept="image/*"
              validate={[required()]}
              fullWidth
            >
              <ImageField source="src" title="title" />
            </ImageInput>
            <TextInput
              label="제품몀"
              variant="outlined"
              source="title"
              validate={[required()]}
              fullWidth
            />
            <NumberInput
              label="노출금액"
              variant="outlined"
              source="salePrice"
              validate={[required()]}
              fullWidth
              min={0}
              defaultValue={0}
            />
            <TextInput
              label="외부링크"
              variant="outlined"
              source="link"
              validate={[required()]}
              fullWidth
              helperText={"*현재는 외부 링크로 연결만 지원함"}
            />
          </Grid>
        </Grid>
      </SimpleForm>
    </Create>
  );
};

export const ProductEdit = (props) => {
  const classes = useStyles();

  return (
    <Edit title="상품 수정" {...props}>
      <SimpleForm warnWhenUnsavedChanges redirect="list">
        <Grid container className={classes.wrapper}>
          <Grid item xs={12} sm={12}>
            <Typography component="span" variant="caption">
              (*)는 필수 입력란입니다.
            </Typography>
          </Grid>
          <Grid container>
            <Grid item xs={12} sm={4}>
              <BooleanInput
                label="노출여부"
                source="isShow"
                defaultValue={true}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6}>
            <ImageInput
              source="thumbnail"
              label="썸네일"
              accept="image/*"
              validate={[required()]}
              fullWidth
            >
              <ImageField source="src" title="title" />
            </ImageInput>
            <TextInput
              label="제품몀"
              variant="outlined"
              source="title"
              validate={[required()]}
              fullWidth
            />
            <NumberInput
              label="노출금액"
              variant="outlined"
              source="salePrice"
              validate={[required()]}
              fullWidth
              min={0}
              defaultValue={0}
            />
            <TextInput
              label="외부링크"
              variant="outlined"
              source="link"
              validate={[required()]}
              fullWidth
              helperText={"*현재는 외부 링크로 연결만 지원함"}
            />
          </Grid>
        </Grid>
      </SimpleForm>
    </Edit>
  );
};

export const ProductList = (props) => {
  return (
    <List
      {...props}
      title="상품 관리"
      perPage={20}
      sort={{ field: "createdate", order: "DESC" }}
      pagination={<Pagination rowsPerPageOptions={[20, 40]} />}
    >
      <Datagrid bulkActionButtons={false}>
        <ImageField label="섬네일" source="thumbnail.src" title="title" />
        <DateField source="createdate" label="등록시간" showTime />
        <BooleanField source="isShow" label="노출여부" />
        <UrlField source="link" label="외부링크" />
        <NumberField source="salePrice" label="노출금액" />
        <EditButton basePath={"/product"} label="수정" />
      </Datagrid>
    </List>
  );
};
