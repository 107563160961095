import { useState, useEffect } from "react";
import {
  List,
  Datagrid,
  TextField,
  DateField,
  ReferenceField,
  NumberField,
  Filter,
  Pagination,
  ReferenceInput,
  AutocompleteInput,
  useRecordContext,
  EditBase,
  SimpleForm,
  Toolbar,
  SaveButton,
  DateInput,
  ArrayInput,
  SimpleFormIterator,
  TimeInput,
  SelectInput,
  TextInput,
  useListContext,
} from "react-admin";
import { Typography, Grid, Card, CardContent, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  wrapper: {
    position: "relative",
    width: "100%",
    "&>div": {
      padding: theme.spacing(2),
    },
  },
  none: {
    display: "none",
    visibility: "hidden",
    width: 0,
    height: 0,
  },
  input: {
    "& *": {
      marginTop: theme.spacing(0.5),
      padding: "12.5px !important",
    },
  },
  darkInput: {
    "& *": {
      color: "rgba(0, 0, 0, 0.6) !important",
      textFillColor: "#141414 !important",
    },
  },
}));

const MentoringShow = props => {
  const record = useRecordContext();
  const classes = useStyles();
  const [state, setState] = useState({
    invoicePrice: 0,
    accountLoading: true,
    account: null,
  });

  useEffect(() => {
    if (record) {
    }
    return () => {};
  }, [record]);

  if (!record) return null;
  return (
    <Card style={{ maxWidth: 650, margin: "auto" }}>
      <CardContent>
        <EditBase {...props}>
          <SimpleForm sx={{ pt: 0, pb: 0 }} toolbar={false}>
            <Grid container style={{ paddingTop: 5, paddingBottom: 5 }}>
              <Grid item xs={12} sm={12}>
                <Typography
                  component="p"
                  variant="subtitle1"
                  style={{ fontWeight: 700 }}
                >
                  멘토링 진행 정보
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} className={classes.darkInput}>
                <ArrayInput source="timeline" label="" disabled>
                  <SimpleFormIterator>
                    <TimeInput
                      variant="standard"
                      source="startTime"
                      label="멘토링 시작 시간"
                      style={{ minWidth: 200, marginTop: 15 }}
                    />
                    <TimeInput
                      variant="standard"
                      source="endTime"
                      label="멘토링 종료 시간"
                      style={{ minWidth: 200 }}
                    />
                    <SelectInput
                      source="status"
                      variant="standard"
                      label="현재상태"
                      choices={[
                        { id: 0, name: "신청가능" },
                        { id: 1, name: "진행예정" },
                        { id: 2, name: "진행중" },
                        { id: 3, name: "완료" },
                        { id: 4, name: "취소" },
                        { id: 5, name: "환불" },
                        { id: 6, name: "연기" },
                      ]}
                      disabled
                      style={{ minWidth: 200 }}
                    />
                    {/* <ReferenceField label="결제 고유번호" source="id" reference="mentoringPayment"><TextInput source="id" variant="standard" label="결제 고유번호" style={{ minWidth: 200 }}/></ReferenceField> */}
                    <ReferenceField
                      label="유저정보"
                      source="uid"
                      reference="user"
                    >
                      <TextInput
                        source="displayName"
                        variant="standard"
                        label="유저정보"
                        style={{ minWidth: 200 }}
                      />
                    </ReferenceField>
                  </SimpleFormIterator>
                </ArrayInput>
              </Grid>
            </Grid>
          </SimpleForm>
        </EditBase>
      </CardContent>
    </Card>
  );
};

const MentoringToolbar = () => (
  <Toolbar>
    <SaveButton label="Save" />
  </Toolbar>
);

const MentoringFilter = props => {
  const { setFilters } = useListContext();
  const resetFilter = () => {
    setFilters({}, []);
  };
  return (
    <Filter {...props}>
      <ReferenceInput
        label="멘토 이메일로 검색"
        source="uid"
        reference="mentor"
        sort={{ field: "name", order: "ASC" }}
        filterToQuery={searchText => ({ email: searchText })}
        allowEmpty={true}
        variant="outlined"
        alwaysOn
      >
        <AutocompleteInput
          label="멘토 이메일로 검색"
          variant="outlined"
          optionText="email"
          style={{ minWidth: 200 }}
        />
      </ReferenceInput>
      <DateInput
        variant="outlined"
        label="멘토링 일자로 검색"
        source="day"
        alwaysOn
      />
      <Button
        alwaysOn
        color="primary"
        variant="outlined"
        onClick={() => resetFilter()}
      >
        Reset 필터
      </Button>
    </Filter>
  );
};

export const MentoringList = props => {
  return (
    <List
      {...props}
      optimized
      title="멘토링 일정 관리"
      perPage={20}
      sort={{ field: "created", order: "desc" }}
      pagination={<Pagination rowsPerPageOptions={[20, 40]} />}
      filters={<MentoringFilter />}
    >
      <Datagrid
        bulkActionButtons={false}
        rowClick="expand"
        expand={<MentoringShow />}
        sx={{ "& *": { textAlign: "center !important" } }}
      >
        <TextField source="id" label="고유번호" />
        <DateField source="day" label="멘토링 진행 일자" />
        <NumberField source="salePrice" label="15분당 판매금액" />
        <ReferenceField label="멘토명" source="mentor" reference="mentor">
          <TextField source="displayName" />
        </ReferenceField>
        <ReferenceField label="멘토 이메일" source="mentor" reference="mentor">
          <TextField source="email" />
        </ReferenceField>
      </Datagrid>
    </List>
  );
};
