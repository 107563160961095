import {
  List,
  TextInput,
  Pagination,
  Filter,
  DateField,
  SaveButton,
  Toolbar,
  Datagrid,
  ReferenceField,
  TextField,
  SelectField,
  SelectInput,
  EditBase,
  SimpleForm,
  ImageField,
} from "react-admin";
import { Grid, Card, CardContent, Typography } from "@material-ui/core";

const ReviewEdit = (props) => {
  return (
    <Card style={{ maxWidth: 600, margin: "auto" }}>
      <CardContent>
        <EditBase {...props}>
          <SimpleForm sx={{ pt: 0, pb: 0 }} toolbar={<ReviewToolbar />}>
            <Grid container>
              <Grid item xs={12} sm={6}>
                <ReferenceField
                  label="클래스"
                  source="classId"
                  reference="classes"
                >
                  <ImageField source="thumbnail.src" />
                </ReferenceField>
              </Grid>
            </Grid>
            <Grid container style={{ paddingTop: 10 }}>
              <Grid item xs={12} sm={12}>
                <TextInput
                  label="리뷰"
                  variant="standard"
                  source="content"
                  maxRows={15}
                  multiline
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <SelectInput
                  label="표시여부"
                  variant="standard"
                  source="isShow"
                  choices={[
                    { id: 0, name: "기본" },
                    { id: 2, name: "미표시" },
                  ]}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <Typography component="p" variant="subtitle2" color="primary">
                  *미표시로 전환 시, "시스템에 의해 비공개 처리되었습니다."로
                  표시됩니다.
                </Typography>
              </Grid>
            </Grid>
          </SimpleForm>
        </EditBase>
      </CardContent>
    </Card>
  );
};

export const ReviewToolbar = () => (
  <Toolbar>
    <SaveButton label="Save" />
  </Toolbar>
);

const ReviewFilter = (props) => {
  return (
    <Filter {...props}>
      <TextInput
        alwaysOn
        variant="outlined"
        source="displayName"
        label="이름으로 검색"
      />
      <TextInput
        alwaysOn
        variant="outlined"
        source="email"
        label="이메일로 검색"
      />
      <TextInput
        alwaysOn
        variant="outlined"
        source="content"
        label="내용으로 검색"
      />
      <SelectInput
        alwaysOn
        label="표시여부"
        variant="outlined"
        source="isShow"
        choices={[
          { id: 0, name: "기본" },
          { id: 2, name: "미표시" },
        ]}
      />
    </Filter>
  );
};

export const ReviewList = (props) => {
  return (
    <List
      {...props}
      title="클래스 리뷰 관리"
      perPage={20}
      sort={{ field: "created", order: "DESC" }}
      pagination={<Pagination rowsPerPageOptions={[20, 40]} />}
      filters={<ReviewFilter />}
    >
      <Datagrid
        optimized
        rowClick="expand"
        bulkActionButtons={false}
        sx={{ "& *": { textAlign: "center !important" } }}
        expand={<ReviewEdit />}
      >
        <DateField
          variant="outlined"
          source="created"
          label="등록시간"
          showTime
        />
        <ReferenceField label="작성자 이메일" source="uid" reference="user">
          <TextField label="작성자 이메일" source="email" />
        </ReferenceField>
        <ReferenceField label="클래스" source="classId" reference="classes">
          <TextField label="클래스" source="title" />
        </ReferenceField>
        <TextField label="리뷰" source="content" />
        <SelectField
          label="표시여부"
          source="isShow"
          choices={[
            { id: 0, name: "기본" },
            { id: 2, name: "미표시" },
          ]}
        />
      </Datagrid>
    </List>
  );
};
