import { useState } from "react";
import { useSidebarState } from "react-admin";
import { MenuItemLink } from "react-admin"; // DashboardMenuItem
import SubMenu from "~/layouts/SubMenu";
import {
  AssignmentInd,
  VideoLibrary,
  Videocam,
  AccountCircle,
  // Info,
  Help,
  Subject,
  Receipt,
  Payment,
  LocalAtm,
  Policy,
  PlayCircleFilled,
  List,
  Person,
  Loyalty,
  Web,
  WebAsset,
  EventAvailable,
  Schedule,
  AccountBalance,
  FormatListBulleted,
  RateReview,
  Store, // 스토어
  LocalGroceryStore, // 상품
  Assignment, // FAQ
  Edit,
  Security, // 권한
  Category, // 카테고리(섹션)
  ListAlt, // 커뮤니티
  // LocalShipping, // 주문
  // Notifications, // 공지
  // NewReleases, // 실험적인 기능
} from "@material-ui/icons";

const Menu = ({ onMenuClick, dense }) => {
  const [state, setState] = useState({
    menuLecture: false,
    menuUser: false,
    menuPayment: false,
    menuMentoring: false,
    menuDesign: false,
    menuPolicy: false,
    menuBoard: false,
    menuStore: false,
    menuCommunity: false,
  });
  const [open] = useSidebarState();
  const handleToggle = menu => {
    setState(state => ({ ...state, [menu]: !state[menu] }));
  };

  // const onDashboardClick = () => {};

  return (
    <div>
      {" "}
      {/* <DashboardMenuItem primaryText={"대시보드"} onClick={onDashboardClick} /> */}
      <SubMenu
        handleToggle={() => handleToggle("menuUser")}
        isOpen={state.menuUser}
        sidebarIsOpen={open}
        name="사용자관리"
        dense={dense}
        icon={<Person color="secondary" />}
      >
        <MenuItemLink
          to={"/user"}
          primaryText={"유저"}
          onClick={onMenuClick}
          dense={dense}
          leftIcon={<AccountCircle />}
        />
      </SubMenu>
      <SubMenu
        handleToggle={() => handleToggle("menuLecture")}
        isOpen={state.menuLecture}
        sidebarIsOpen={open}
        name="클래스관리"
        dense={dense}
        icon={<PlayCircleFilled color="secondary" />}
      >
        <MenuItemLink
          to={"/lecturer"}
          primaryText={"강연자"}
          onClick={onMenuClick}
          dense={dense}
          leftIcon={<AssignmentInd />}
        />
        <MenuItemLink
          to={"/account"}
          primaryText={"강연자계좌"}
          onClick={onMenuClick}
          dense={dense}
          leftIcon={<AccountBalance />}
        />
        <MenuItemLink
          to={"/classes"}
          primaryText={"클래스"}
          onClick={onMenuClick}
          dense={dense}
          leftIcon={<VideoLibrary />}
        />
        <MenuItemLink
          to={"/lecture"}
          primaryText={"강연"}
          onClick={onMenuClick}
          dense={dense}
          leftIcon={<Videocam />}
        />
        <MenuItemLink
          to={"/review"}
          primaryText={"리뷰"}
          onClick={onMenuClick}
          dense={dense}
          leftIcon={<RateReview />}
        />
        <MenuItemLink
          to={"/payment"}
          primaryText={"결제"}
          onClick={onMenuClick}
          dense={dense}
          leftIcon={<Payment />}
        />
        {/* <MenuItemLink to={"/total"} primaryText={"오픈된 강연수"} onClick={onMenuClick} dense={dense} leftIcon={<NewReleases />}/> */}
      </SubMenu>
      <SubMenu
        handleToggle={() => handleToggle("menuMentoring")}
        isOpen={state.menuMentoring}
        sidebarIsOpen={open}
        name="멘토링관리"
        dense={dense}
        icon={<EventAvailable color="secondary" />}
      >
        <MenuItemLink
          to={"/mentor"}
          primaryText={"멘토"}
          onClick={onMenuClick}
          dense={dense}
          leftIcon={<AssignmentInd />}
        />
        <MenuItemLink
          to={"/mentorAccount"}
          primaryText={"멘토계좌"}
          onClick={onMenuClick}
          dense={dense}
          leftIcon={<AccountBalance />}
        />
        <MenuItemLink
          to={"/mentoring"}
          primaryText={"일정"}
          onClick={onMenuClick}
          dense={dense}
          leftIcon={<Schedule />}
        />
        <MenuItemLink
          to={"/mentoringReview"}
          primaryText={"리뷰"}
          onClick={onMenuClick}
          dense={dense}
          leftIcon={<RateReview />}
        />
        <MenuItemLink
          to={"/mentoringPayment"}
          primaryText={"결제"}
          onClick={onMenuClick}
          dense={dense}
          leftIcon={<Payment />}
        />
      </SubMenu>
      <SubMenu
        handleToggle={() => handleToggle("menuStore")}
        isOpen={state.menuStore}
        sidebarIsOpen={open}
        name="스토어관리"
        dense={dense}
        icon={<Store color="secondary" />}
      >
        <MenuItemLink
          to={"/product"}
          primaryText={"상품"}
          onClick={onMenuClick}
          dense={dense}
          leftIcon={<LocalGroceryStore />}
        />
        {/* FIXME: 추후에 상품 개발하게 되면 사용 */}
        {/* <MenuItemLink to={"/category"} primaryText={"카테고리"} onClick={onMenuClick} dense={dense} leftIcon={<FormatListBulleted />}/> */}
        {/* <MenuItemLink to={"/order"} primaryText={"주문"} onClick={onMenuClick} dense={dense} leftIcon={<LocalShipping />} /> */}
        {/* <MenuItemLink to={"/orderReview"} primaryText={"상품리뷰"} onClick={onMenuClick} dense={dense} leftIcon={<RateReview />} /> */}
      </SubMenu>
      <SubMenu
        handleToggle={() => handleToggle("menuPayment")}
        isOpen={state.menuPayment}
        sidebarIsOpen={open}
        name="인보이스관리"
        dense={dense}
        icon={<LocalAtm color="secondary" />}
      >
        {/* TODO: 강의료 비용 지급 정보 관리 > Google Data Studio로 대체 가능 */}
        {/* <MenuItemLink to={"/classInvoice"} primaryText={"클래스"} onClick={onMenuClick} dense={dense} leftIcon={<Receipt />}/> */}
        <MenuItemLink
          to={"/mentoringInvoice"}
          primaryText={"멘토링"}
          onClick={onMenuClick}
          dense={dense}
          leftIcon={<Receipt />}
        />
        <MenuItemLink
          to={"/coupon"}
          primaryText={"쿠폰정보"}
          onClick={onMenuClick}
          dense={dense}
          leftIcon={<Loyalty />}
        />
      </SubMenu>
      <SubMenu
        handleToggle={() => handleToggle("menuCommunity")}
        isOpen={state.menuCommunity}
        sidebarIsOpen={open}
        name="커뮤니티관리"
        dense={dense}
        icon={<Edit color="secondary" />}
      >
        <MenuItemLink
          to={"/permission"}
          primaryText={"권한"}
          onClick={onMenuClick}
          dense={dense}
          leftIcon={<Security />}
        />
        <MenuItemLink
          to={"/communitySectionTitle"}
          primaryText={"카테고리 상위제목"}
          onClick={onMenuClick}
          dense={dense}
          leftIcon={<Category />}
        />
        <MenuItemLink
          to={"/communitySection"}
          primaryText={"카테고리"}
          onClick={onMenuClick}
          dense={dense}
          leftIcon={<Category />}
        />
        <MenuItemLink
          to={"/community"}
          primaryText={"커뮤니티"}
          onClick={onMenuClick}
          dense={dense}
          leftIcon={<ListAlt />}
        />
        {/* TODO: 권한코드 삽입 */}
        {/* <MenuItemLink to={"/code"} primaryText={"권한 코드"} onClick={onMenuClick} dense={dense} leftIcon={<Loyalty />}/> */}
      </SubMenu>
      <SubMenu
        handleToggle={() => handleToggle("menuDesign")}
        isOpen={state.menuDesign}
        sidebarIsOpen={open}
        name="디자인관리"
        dense={dense}
        icon={<Web color="secondary" />}
      >
        <MenuItemLink
          to={"/design"}
          primaryText={"메인"}
          onClick={onMenuClick}
          dense={dense}
          leftIcon={<WebAsset />}
        />
        <MenuItemLink
          to={"/section"}
          primaryText={"섹션"}
          onClick={onMenuClick}
          dense={dense}
          leftIcon={<FormatListBulleted />}
        />
      </SubMenu>
      <SubMenu
        handleToggle={() => handleToggle("menuBoard")}
        isOpen={state.menuBoard}
        sidebarIsOpen={open}
        name="게시판 관리"
        dense={dense}
        icon={<List color="secondary" />}
      >
        <MenuItemLink
          to={"/faq"}
          primaryText={"FAQ"}
          onClick={onMenuClick}
          dense={dense}
          leftIcon={<Assignment />}
        />
        <MenuItemLink
          to={"/help"}
          primaryText={"고객센터"}
          onClick={onMenuClick}
          dense={dense}
          leftIcon={<Help />}
        />
        {/* <MenuItemLink to={"/notice"} primaryText={"공지사항"} onClick={onMenuClick} dense={dense} leftIcon={<Help />}/> */}
        {/* <MenuItemLink to={"/popup"} primaryText={"공지팝업"} onClick={onMenuClick} dense={dense} leftIcon={<Notifications />} /> */}
      </SubMenu>
      <SubMenu
        handleToggle={() => handleToggle("menuPolicy")}
        isOpen={state.menuPolicy}
        sidebarIsOpen={open}
        name="약관 관리"
        dense={dense}
        icon={<Policy color="secondary" />}
      >
        <MenuItemLink
          to={"/policy"}
          primaryText={"이용약관"}
          onClick={onMenuClick}
          dense={dense}
          leftIcon={<Subject />}
        />
        <MenuItemLink
          to={"/privacy"}
          primaryText={"개인정보처리방침"}
          onClick={onMenuClick}
          dense={dense}
          leftIcon={<Subject />}
        />
        <MenuItemLink
          to={"/license"}
          primaryText={"오픈소스라이선스"}
          onClick={onMenuClick}
          dense={dense}
          leftIcon={<Subject />}
        />
      </SubMenu>
    </div>
  );
};

export default Menu;
