import { Fragment } from "react";
import {
  List,
  Edit,
  SimpleForm,
  EditButton,
  NumberInput,
  Toolbar,
  SaveButton,
  SimpleListLoading,
  TopToolbar,
  RecordContextProvider,
  useListContext,
} from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import {
  Typography,
  Grid,
  Card,
  CardContent,
  CardActions,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    position: "relative",
    width: "100%",
    "&>div": {
      padding: theme.spacing(2),
    },
  },
  none: {
    display: "none",
  },
}));

export const TotalToolbar = () => (
  <Toolbar>
    <SaveButton label="Save" />
  </Toolbar>
);

export const TotalEdit = (props) => {
  const classes = useStyles();
  return (
    <Edit {...props} title="강연 갯수 수정">
      <SimpleForm redirect="list" toolbar={<TotalToolbar />}>
        <Grid container className={classes.wrapper}>
          <Grid item xs={12} sm={4}>
            <Grid container>
              <Grid
                item
                xs={12}
                sm={12}
                style={{
                  marginBottom: "15px",
                }}
              >
                <Typography component="span" variant="subtitle2">
                  구독권 환불 시 연산에 적용되는 "오픈 강연수"에 영향을
                  미칩니다.
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12}>
                <NumberInput
                  label="강연수"
                  source="lecture"
                  variant="outlined"
                  min={1}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </SimpleForm>
    </Edit>
  );
};

const TotalGrid = () => {
  const { data: total, isLoading } = useListContext();

  if (isLoading) {
    return <SimpleListLoading hasLeftAvatarOrIcon hasSecondaryText />;
  }
  return total && total.length ? (
    <Fragment>
      <Grid container spacing={2}>
        {total.map((data) => {
          if (data.id === "total") {
            return (
              <RecordContextProvider key={data.id} value={data}>
                <Grid key={data.id} item xs={12} sm={3}>
                  <Card
                    style={{
                      padding: "10px",
                      margin: "10px",
                      background: "#f9f9f9",
                    }}
                  >
                    <CardContent>
                      <Typography
                        variant="body1"
                        component="h5"
                        align="center"
                        style={{ fontWeight: 700 }}
                      >
                        오픈된 강연수 관리(experimental)
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        component="p"
                        align="center"
                        style={{ fontWeight: 700 }}
                      >
                        *주의, 정확한 오픈 강연수 체크 후 기입 필요
                      </Typography>
                    </CardContent>
                    <CardActions
                      style={{
                        display: "flex",
                        justifyContent: "space-around",
                      }}
                    >
                      <EditButton resource="total" record={data} />
                    </CardActions>
                  </Card>
                </Grid>
              </RecordContextProvider>
            );
          }
          return null;
        })}
      </Grid>
    </Fragment>
  ) : null;
};

const ListActions = () => (
  <TopToolbar sx={{ minHeight: { sm: 56 } }}></TopToolbar>
);

export const TotalList = (props) => {
  return (
    <List
      {...props}
      title="오픈된 강연수 관리"
      perPage={20}
      sort={{ field: "createdate", order: "DESC" }}
      actions={<ListActions />}
    >
      <TotalGrid />
    </List>
  );
};
