import { makeStyles } from "@material-ui/core/styles";
import {
  List,
  Edit,
  SimpleForm,
  TextInput,
  required,
  Datagrid,
  DateField,
  EditButton,
  TextField,
  Pagination,
  BooleanInput,
  NumberInput,
  NumberField,
  ReferenceField,
  Filter,
} from "react-admin";
import { Grid } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    position: "relative",
    width: "100%",
    "&>div": {
      padding: theme.spacing(2),
    },
  },
  none: {
    display: "none",
  },
}));

const BoardFilter = (props) => {
  return (
    <Filter {...props}>
      <TextInput
        alwaysOn
        variant="outlined"
        source="createdby"
        label="작성자"
      />
      <BooleanInput label="노출여부" variant="outlined" source="isShow" />
    </Filter>
  );
};

export const BoardEdit = (props) => {
  const classes = useStyles();
  return (
    <Edit title="게시판 수정" {...props}>
      <SimpleForm redirect="list">
        <Grid container className={classes.wrapper}>
          <Grid item xs={12} sm={8}>
            <TextInput
              label="고유번호"
              variant="outlined"
              source="id"
              fullWidth
              disabled
            />
            <BooleanInput
              label="노출여부"
              variant="outlined"
              source="isShow"
              defaultValue={true}
              fullWidth
            />
            <NumberInput
              label="조회수"
              variant="outlined"
              source="view"
              fullWidth
            />
            <ReferenceField
              label="작성자"
              source="user"
              reference="user"
              variant="outlined"
            >
              <TextField source="displayName" disabled />
            </ReferenceField>
            <ReferenceField
              label="작성자 이메일"
              source="user"
              reference="user"
              variant="outlined"
            >
              <TextField source="email" disabled />
            </ReferenceField>
            <TextInput
              label="제목"
              variant="outlined"
              source="title"
              validate={[required()]}
              fullWidth
            />
            <TextInput
              variant="outlined"
              source="content"
              label="내용"
              validate={[required()]}
              fullWidth
            />
          </Grid>
        </Grid>
      </SimpleForm>
    </Edit>
  );
};

export const BoardList = (props) => {
  return (
    <List
      {...props}
      title="공지사항 관리"
      perPage={20}
      sort={{ field: "createdate", order: "DESC" }}
      pagination={<Pagination rowsPerPageOptions={[20, 40]} />}
      filters={<BoardFilter />}
    >
      <Datagrid>
        <DateField source="createdate" label="등록시간" showTime />
        <ReferenceField label="작성자" source="user" reference="user">
          <TextField source="displayName" />
        </ReferenceField>
        <ReferenceField label="작성자 이메일" source="user" reference="user">
          <TextField source="email" />
        </ReferenceField>
        <NumberField label="조회수" variant="outlined" source="view" />
        <TextField label="제목" variant="outlined" source="title" />
        <EditButton basePath={"/board"} label="수정" />
      </Datagrid>
    </List>
  );
};
