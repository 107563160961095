import {
  Create,
  List,
  Edit,
  SimpleForm,
  TextInput,
  required,
  Datagrid,
  DateField,
  Pagination,
  Filter,
  SelectInput,
  NumberInput,
  TextField,
  SelectArrayInput,
  EditButton,
  useListContext,
  // BooleanInput,
  // BooleanField,
} from "react-admin";
import { Grid, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  wrapper: {
    position: "relative",
    width: "100%",
    "& > div": { padding: theme.spacing(2) },
  },
  none: {
    display: "none",
    visibility: "hidden",
    width: 0,
    height: 0,
  },
  darkInput: {
    "& *": {
      color: "rgba(0, 0, 0, 0.6) !important",
      textFillColor: "#141414 !important",
    },
  },
}));

export const PermissionCreate = props => {
  const classes = useStyles();

  const transform = data => ({
    ...data,
    created: new Date().getTime(),
    // permissions: {
    //   type: data.userType,
    //   title: data.title,
    //   permission: data.permission,
    // },
  });

  return (
    <Create {...props} title="권한 등록" transform={transform} redirect="list">
      <SimpleForm warnWhenUnsavedChanges submitOnEnter={false}>
        <Grid container className={classes.wrapper}>
          <Grid item xs={7}>
            <Grid container>
              <Grid item xs={12} sm={12}>
                <NumberInput
                  className={classes.none}
                  source="permissionType"
                  fullWidth
                  value={0}
                  defaultValue={0}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextInput
                  label="타이틀"
                  variant="outlined"
                  source="title"
                  fullWidth
                  validate={[required()]}
                  defaultValue=""
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <SelectArrayInput
                  source="permission"
                  variant="outlined"
                  label="권한"
                  validate={[required()]}
                  choices={[
                    { id: "read", name: "읽기권한" },
                    { id: "write", name: "쓰기권한" },
                  ]}
                  optionText="name"
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <SelectInput
                  choices={[
                    { id: 0, name: "기본" },
                    { id: 1, name: "임팩터스 관리자" }, // > 관리자 계정은 직접 부여
                    { id: 2, name: "임팩터스 서포터즈" },
                    { id: 3, name: "임팩터스 유관 프로그램 강사" },
                    { id: 4, name: "임팩터스 유관 프로그램 참여자" },
                    {
                      id: 5,
                      name: "임팩터스 매거진 구독자(추후 구독 형태 구현 시 사용)",
                    },
                    { id: 6, name: "임팩터스 마스터(매거진 작성자)" },
                    { id: 7, name: "멘토" },
                    { id: 8, name: "교사" },
                    { id: 9, name: "교수" },
                  ]}
                  source="userType"
                  variant="outlined"
                  label="유저 타입"
                  validate={[required()]}
                  defaultValue={0}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextInput
                  label="메모"
                  variant="outlined"
                  source="memo"
                  fullWidth
                  defaultValue=""
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </SimpleForm>
    </Create>
  );
};

export const PermissionEdit = props => {
  const classes = useStyles();

  const transform = data => ({
    ...data,
    created: new Date().getTime(),
    permissions: {
      title: data.title,
      type: data.userType,
      permission: data.permission,
    },
  });

  return (
    <Edit title="권한 수정" {...props} transform={transform}>
      <SimpleForm redirect="list">
        <Grid container className={classes.wrapper}>
          <Grid item xs={7}>
            <Grid container>
              <Grid item xs={12} sm={12}>
                <NumberInput
                  className={classes.none}
                  source="permissionType"
                  fullWidth
                  value={0}
                  defaultValue={0}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextInput
                  label="타이틀"
                  variant="outlined"
                  source="title"
                  fullWidth
                  validate={[required()]}
                  defaultValue=""
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <SelectArrayInput
                  source="permission"
                  variant="outlined"
                  label="권한"
                  validate={[required()]}
                  choices={[
                    { id: "read", name: "읽기권한" },
                    { id: "write", name: "쓰기권한" },
                  ]}
                  optionText="name"
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <SelectInput
                  choices={[
                    { id: 0, name: "기본" },
                    { id: 1, name: "임팩터스 관리자" }, // > 관리자 계정은 직접 부여
                    { id: 2, name: "임팩터스 서포터즈" },
                    { id: 3, name: "임팩터스 유관 프로그램 강사" },
                    { id: 4, name: "임팩터스 유관 프로그램 참여자" },
                    {
                      id: 5,
                      name: "임팩터스 매거진 구독자(추후 구독 형태 구현 시 사용)",
                    },
                    { id: 6, name: "임팩터스 마스터(매거진 작성자)" },
                    { id: 7, name: "멘토" },
                    { id: 8, name: "교사" },
                    { id: 9, name: "교수" },
                  ]}
                  source="userType"
                  variant="outlined"
                  label="유저 타입"
                  validate={[required()]}
                  defaultValue={0}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextInput
                  label="메모"
                  variant="outlined"
                  source="memo"
                  fullWidth
                  defaultValue=""
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </SimpleForm>
    </Edit>
  );
};

const PermissionFilter = props => {
  const { setFilters } = useListContext();
  const resetFilter = () => {
    setFilters({}, []);
  };

  return (
    <Filter {...props}>
      <TextInput
        alwaysOn
        variant="outlined"
        source="title"
        label="권한 이름으로 검색"
      />
      <TextInput
        alwaysOn
        variant="outlined"
        source="memo"
        label="메모로 검색"
      />
      <Button
        alwaysOn
        color="primary"
        variant="outlined"
        onClick={() => resetFilter()}
      >
        Reset 필터
      </Button>
      {/* <BooleanInput alwaysOn source="read" variant="outlined" label="읽기 권한 허용으로 검색"/><BooleanInput alwaysOn source="write" variant="outlined" label="쓰기 권한 허용으로 검색" /> */}
    </Filter>
  );
};

export const PermissionList = props => {
  return (
    <List
      {...props}
      title="커뮤니티 권한 관리"
      perPage={20}
      sort={{ field: "created", order: "DESC" }}
      pagination={<Pagination rowsPerPageOptions={[20, 40]} />}
      filters={<PermissionFilter />}
    >
      <Datagrid
        bulkActionButtons={false}
        sx={{ "& *": { textAlign: "center !important" } }}
      >
        <DateField
          variant="outlined"
          source="created"
          label="등록시간"
          showTime
        />
        <TextField variant="outlined" source="title" label="권한 이름" />
        {/* <BooleanField variant="outlined" source="read" label="읽기 권한" /> */}
        {/* <BooleanField variant="outlined" source="write" label="쓰기 권한" /> */}
        {/* <TextField variant="outlined" source="memo" label="메모" /> */}
        <EditButton basePath={"/permission"} label="수정" />
      </Datagrid>
    </List>
  );
};
