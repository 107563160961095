import { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import ListIcon from "@material-ui/icons/List";
import {
  List,
  Edit,
  SimpleForm,
  EditButton,
  Datagrid,
  DateField,
  TextField,
  Pagination,
  ReferenceField,
  TextInput,
  Filter,
  SelectInput,
  SelectField,
  useNotify,
  useRefresh,
  useRedirect,
  useEditController,
} from "react-admin";
import { getFirestore, collection, doc, setDoc } from "firebase/firestore";
import { Box, Grid, Typography } from "@material-ui/core";
import { app } from "~/App";

const useStyles = makeStyles(theme => ({
  wrapper: {
    position: "relative",
    width: "100%",
    "&>div": {
      padding: theme.spacing(2),
    },
  },
  none: {
    display: "none",
  },
  darkInput: {
    "& *": {
      color: "rgba(0, 0, 0, 0.6) !important",
      textFillColor: "#141414 !important",
    },
  },
}));

const HelpFilter = props => {
  return (
    <Filter {...props}>
      <TextInput
        alwaysOn
        variant="outlined"
        source="displayName"
        label="작성자"
      />
      <SelectInput
        alwaysOn
        source="type"
        choices={[
          { id: 0, name: "영상 관련 문의" },
          { id: 1, name: "강사 관련 문의" },
          { id: 2, name: "회원 관련 문의" },
          { id: 3, name: "결제 관련 문의" },
          { id: 4, name: "기타 문의" },
        ]}
        variant="outlined"
        label="문의항목"
      />
      <SelectInput
        alwaysOn
        source="status"
        choices={[
          { id: 0, name: "미답변" },
          { id: 1, name: "답변" },
        ]}
        variant="outlined"
        label="답변상태"
      />
      <TextInput
        alwaysOn
        variant="outlined"
        source="email"
        label="이메일로 검색"
      />
    </Filter>
  );
};

export const HelpShow = props => {
  const classes = useStyles();
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();

  const controllerProps = useEditController(props);
  const { record, save, isLoading } = controllerProps;

  useEffect(() => {
    return () => {};
  }, []);

  const onSuccess = async data => {
    console.log("HelpShow onSuccess: ", data);
    try {
      if (data.reply && data.reply !== "") {
        await setDoc(
          doc(collection(getFirestore(app[0]), "help"), data.id),
          {
            status: 1,
          },
          {
            merge: true,
          }
        );
      }
      notify(`저장 완료`);
      redirect("/help");
      refresh();
    } catch (error) {
      console.log(`${error.message}`);
      notify(`일부데이터 생성 오류 발생`);
      redirect("/help");
      refresh();
    }
  };

  return (
    <Edit title="고객센터 문의 보기" {...props} mutationOptions={{ onSuccess }}>
      <SimpleForm
        warnWhenUnsavedChanges
        submitOnEnter={false}
        record={record}
        onSubmit={save}
      >
        <Box style={{ maxWidth: "60rem" }}>
          <Grid container>
            <Grid item xs={12} sm={12}>
              <Typography component="p" variant="subtitle2" color="primary">
                *참고: 구글 메일 정책으로 하루 고객센터 답변 자동 메일은 500건
                내외로 제한됩니다.(추후에 mailchamp 등의 메일 전송 전용 서비스로
                구현이 필요합니다.)
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextInput
                label="고유번호"
                variant="outlined"
                source="id"
                fullWidth
                disabled
                className={classes.darkInput}
              />
              <Typography
                variant="subtitle2"
                component="p"
                style={{ marginBottom: "10px" }}
              >
                등록시간:
                <DateField
                  label="등록시간"
                  source="created"
                  showTime
                  disabled
                  variant="outlined"
                />
              </Typography>
              <SelectInput
                disabled
                source="status"
                choices={[
                  { id: 0, name: "미답변" },
                  { id: 1, name: "답변" },
                ]}
                variant="outlined"
                label="답변상태"
                className={classes.darkInput}
              />
              <br />
              <SelectInput
                disabled
                source="type"
                choices={[
                  { id: 0, name: "영상 관련 문의" },
                  { id: 1, name: "강사 관련 문의" },
                  { id: 2, name: "회원 관련 문의" },
                  { id: 3, name: "결제 관련 문의" },
                  { id: 4, name: "기타 문의" },
                ]}
                variant="outlined"
                label="문의항목"
                className={classes.darkInput}
              />
              <br />
              <ReferenceField
                label="작성자"
                source="user"
                reference="user"
                variant="outlined"
              >
                <TextInput
                  variant="outlined"
                  source="displayName"
                  disabled
                  label="작성자"
                  className={classes.darkInput}
                />
              </ReferenceField>
              <br />
              <ReferenceField
                label="작성자 이메일"
                source="user"
                reference="user"
                variant="outlined"
              >
                <TextInput
                  variant="outlined"
                  source="email"
                  disabled
                  label="작성자 이메일"
                  className={classes.darkInput}
                />
              </ReferenceField>
              <TextInput
                label="제목"
                variant="outlined"
                source="title"
                fullWidth
                disabled
                className={classes.darkInput}
              />
              <TextInput
                label="내용"
                variant="outlined"
                source="content"
                fullWidth
                disabled
                className={classes.darkInput}
              />
              <TextInput
                label="답변(답변 등록 시 작성자 이메일로 메일이 전송됩니다.)"
                variant="outlined"
                source="reply"
                multiline
                fullWidth
                className={classes.darkInput}
              />
            </Grid>
          </Grid>
        </Box>
      </SimpleForm>
    </Edit>
  );
};

export const HelpList = props => {
  return (
    <List
      {...props}
      title="고객센터 관리"
      perPage={20}
      sort={{ field: "created", order: "DESC" }}
      pagination={<Pagination rowsPerPageOptions={[20, 40]} />}
      filters={<HelpFilter />}
    >
      <Datagrid
        optimized
        bulkActionButtons={false}
        sx={{ "& *": { textAlign: "center !important" } }}
      >
        <DateField source="created" label="등록시간" showTime />
        <SelectField
          disabled
          source="status"
          choices={[
            { id: 0, name: "미답변" },
            { id: 1, name: "답변" },
          ]}
          variant="outlined"
          label="답변상태"
        />
        <SelectField
          disabled
          source="type"
          choices={[
            { id: 0, name: "영상 관련" },
            { id: 1, name: "강사 관련" },
            { id: 2, name: "회원 관련" },
            { id: 3, name: "기타" },
          ]}
          variant="outlined"
          label="문의항목"
        />
        <ReferenceField label="작성자" source="user" reference="user">
          <TextField source="displayName" />
        </ReferenceField>
        <ReferenceField label="작성자 이메일" source="user" reference="user">
          <TextField source="email" />
        </ReferenceField>
        <TextField label="제목" variant="outlined" source="title" />
        <EditButton basePath={"/help"} label="보기" />
      </Datagrid>
    </List>
  );
};

export default {
  icon: ListIcon,
};
