import axios from "axios";

const paymentAPIUri =
  process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_API_TEST_URL
    : process.env.REACT_APP_API_URL;

export const tossCancelApiCall = async (
  paymentKey,
  cancelAmount,
  cancelReason
) => {
  try {
    const ret = await axios.post(
      `${paymentAPIUri}/payment/cancel`,
      {
        paymentKey,
        cancelAmount,
        cancelReason,
      },
      {
        headers: {
          "X-IMPACTUS-CLIENT": process.env.REACT_APP_SECRET_KEY,
          "Content-Type": "application/json",
        },
        timeout: 10000,
      }
    );
    if (ret?.data?.status !== "success") {
      return {
        status: "failure",
        msg: ret?.data?.msg?.message,
      };
    }
    return {
      status: "success",
      result: ret?.data?.result,
    };
  } catch (error) {
    console.log("cancel e: ", error?.message || error);
    return {
      status: "failure",
      msg: "error",
    };
  }
};
