export const firebaseConfig = {
  apiKey: `AIzaSyC5y39aI8kFYtatrujI8hulBQ8o2m3QjOY`,
  authDomain: `impactus-app.firebaseapp.com`,
  databaseURL: `https://impactus-app.firebaseio.com`,
  projectId: `impactus-app`,
  storageBucket: `impactus-app.appspot.com`,
  messagingSenderId: `484465524277`,
  appId: `1:484465524277:web:99827aa6f8b3dcd1f17b7c`,
  measurementId: `G-X1NFSVM7B7`,
};
