import {
  Create,
  List,
  Datagrid,
  SimpleForm,
  TextInput,
  required,
  Edit,
  Pagination,
  Filter,
  EditButton,
  DateField,
  TextField,
  Toolbar,
  SaveButton,
  SimpleFormIterator,
  ArrayInput,
  ReferenceInput,
  AutocompleteInput,
} from "react-admin";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  wrapper: {
    position: "relative",
    width: "100%",
    "& > div": { padding: theme.spacing(2) },
  },
  none: {
    display: "none",
    visibility: "hidden",
    width: 0,
    height: 0,
  },
  darkInput: {
    "& *": {
      color: "rgba(0, 0, 0, 0.6) !important",
      textFillColor: "#141414 !important",
    },
  },
}));

export const CommunitySectionTitleCreate = props => {
  const classes = useStyles();

  const transform = data => ({
    ...data,
    created: new Date().getTime(),
  });

  return (
    <Create
      title="커뮤니티 카테고리 제목 등록"
      {...props}
      transform={transform}
    >
      <SimpleForm submitOnEnter={false}>
        <Grid container className={classes.wrapper}>
          <Grid item xs={12} sm={3}>
            <Grid container>
              <Grid item xs={12} sm={12}>
                <TextInput
                  fullWidth
                  variant="outlined"
                  source="title"
                  label="상위 카테고리 한글명"
                  validate={[required()]}
                  helperText="*(주의) 임팩터스는 기본 고유 값입니다. (추가로 사용불가)"
                  defaultValue=""
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={9}>
            <ArrayInput source="section" label="하위 카테고리 목록">
              <SimpleFormIterator>
                <ReferenceInput
                  fullWidth
                  label="카테고리 명칭"
                  source="id"
                  reference="communitySection"
                  variant="outlined"
                >
                  <AutocompleteInput
                    fullWidth
                    label="카테고리명"
                    variant="outlined"
                    filterToQuery={searchText => ({ title: searchText })}
                    optionValue="id"
                    optionText="title"
                    style={{ minWidth: 300 }}
                  />
                </ReferenceInput>
              </SimpleFormIterator>
            </ArrayInput>
          </Grid>
        </Grid>
      </SimpleForm>
    </Create>
  );
};

export const CommunitySectionTitleEdit = props => {
  const classes = useStyles();

  return (
    <Edit title="커뮤니티 카테고리 제목 수정" {...props}>
      <SimpleForm
        warnWhenUnsavedChanges
        submitOnEnter={false}
        toolbar={
          <Toolbar>
            <SaveButton label="Save" />
          </Toolbar>
        }
      >
        <Grid container className={classes.wrapper}>
          <Grid item xs={12} sm={3}>
            <Grid container>
              <Grid item xs={12} sm={12}>
                <TextInput
                  fullWidth
                  variant="outlined"
                  source="title"
                  label="상위 카테고리 한글명"
                  validate={[required()]}
                  helperText="*(주의) 임팩터스는 기본 고유 값입니다. (추가로 사용불가)"
                  defaultValue=""
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={9}>
            <ArrayInput source="section" label="하위 카테고리 목록">
              <SimpleFormIterator>
                <ReferenceInput
                  fullWidth
                  label="카테고리 명칭"
                  source="id"
                  reference="communitySection"
                  variant="outlined"
                  validate={[required()]}
                >
                  <AutocompleteInput
                    fullWidth
                    label="카테고리명"
                    variant="outlined"
                    filterToQuery={searchText => ({ title: searchText })}
                    optionValue="id"
                    optionText="title"
                    style={{ minWidth: 300 }}
                  />
                </ReferenceInput>
              </SimpleFormIterator>
            </ArrayInput>
          </Grid>
        </Grid>
      </SimpleForm>
    </Edit>
  );
};

const CommunityFilter = props => {
  return (
    <Filter {...props}>
      <TextInput
        alwaysOn
        variant="outlined"
        source="title"
        label="카테고리 명칭으로 검색"
      />
    </Filter>
  );
};

export const CommunitySectionTitleList = props => {
  return (
    <List
      {...props}
      sort={{ field: "created", order: "DESC" }}
      title="커뮤니티 상위 카테고리 관리"
      perPage={20}
      pagination={<Pagination rowsPerPageOptions={[20, 40]} />}
      filters={<CommunityFilter />}
    >
      <Datagrid
        bulkActionButtons={false}
        sx={{ "& *": { textAlign: "center !important" } }}
      >
        <DateField
          variant="outlined"
          source="created"
          label="등록시간"
          showTime
        />
        <TextField source="title" label="상위 카테고리 명칭" />
        <EditButton basePath={"/communitySectionTitle"} label="수정" />
      </Datagrid>
    </List>
  );
};
