import AppleSDGothicNeoTttf from "~/styles/fonts/AppleSDGothicNeoT.woff2";
import AppleSDGothicNeoLttf from "~/styles/fonts/AppleSDGothicNeoL.woff2";
import AppleSDGothicNeoULttf from "~/styles/fonts/AppleSDGothicNeoUL.woff2";
import AppleSDGothicNeoMttf from "~/styles/fonts/AppleSDGothicNeoM.woff2";
import AppleSDGothicNeoRttf from "~/styles/fonts/AppleSDGothicNeoR.woff2";
import AppleSDGothicNeoSBttf from "~/styles/fonts/AppleSDGothicNeoSB.woff2";
import AppleSDGothicNeoBttf from "~/styles/fonts/AppleSDGothicNeoB.woff2";
import AppleSDGothicNeoEBttf from "~/styles/fonts/AppleSDGothicNeoEB.woff2";
import AppleSDGothicNeoHttf from "~/styles/fonts/AppleSDGothicNeoH.woff2";

const AppleSDGothic = [
  {
    fontFamily: "AppleSDGothicNeo",
    fontStyle: "normal",
    fontWeight: 100,
    fontDisplay: "swap",
    src: `
        local('AppleSDGothicNeo-Thin'),
        url(${AppleSDGothicNeoTttf}) format('truetype')
      `,
  },
  {
    fontFamily: "AppleSDGothicNeo",
    fontStyle: "normal",
    fontWeight: 200,
    fontDisplay: "swap",
    src: `
        local('AppleSDGothicNeo-Ultra-Light'),
        url(${AppleSDGothicNeoULttf}) format('truetype')
      `,
  },
  {
    fontFamily: "AppleSDGothicNeo",
    fontStyle: "normal",
    fontWeight: 300,
    fontDisplay: "swap",
    src: `
        local('AppleSDGothicNeo-Light'),
        url(${AppleSDGothicNeoLttf}) format('truetype')
      `,
  },
  {
    fontFamily: "AppleSDGothicNeo",
    fontStyle: "normal",
    fontWeight: 400,
    fontDisplay: "swap",
    src: `
        local('AppleSDGothicNeo-Regular'),
        url(${AppleSDGothicNeoRttf}) format('truetype')
      `,
  },
  {
    fontFamily: "AppleSDGothicNeo",
    fontStyle: "normal",
    fontWeight: 500,
    fontDisplay: "swap",
    src: `
        local('AppleSDGothicNeo-Medium'),
        url(${AppleSDGothicNeoMttf}) format('truetype')
      `,
  },
  {
    fontFamily: "AppleSDGothicNeo",
    fontStyle: "normal",
    fontWeight: 600,
    fontDisplay: "swap",
    src: `
        local('AppleSDGothicNeo-Semi-Bold'),
        url(${AppleSDGothicNeoSBttf}) format('truetype')
      `,
  },
  {
    fontFamily: "AppleSDGothicNeo",
    fontStyle: "normal",
    fontWeight: 700,
    fontDisplay: "swap",
    src: `
        local('AppleSDGothicNeo-Bold'),
        url(${AppleSDGothicNeoBttf}) format('truetype')
      `,
  },
  {
    fontFamily: "AppleSDGothicNeo",
    fontStyle: "normal",
    fontWeight: 800,
    fontDisplay: "swap",
    src: `
        local('AppleSDGothicNeo-Extra-Bold'),
        url(${AppleSDGothicNeoEBttf}) format('truetype')
      `,
  },
  {
    fontFamily: "AppleSDGothicNeo",
    fontStyle: "normal",
    fontWeight: 900,
    fontDisplay: "swap",
    src: `
        local('AppleSDGothicNeo-Heavy'),
        url(${AppleSDGothicNeoHttf}) format('truetype')
      `,
  },
];

export default AppleSDGothic;
