import {
  List,
  Datagrid,
  TextField,
  DateField,
  ReferenceField,
  NumberField,
  useRecordContext,
  Filter,
  TextInput,
  Pagination,
  EditBase,
  SimpleForm,
  Toolbar,
  SaveButton,
  BooleanInput,
  BooleanField,
  useListContext,
} from "react-admin";
import {
  Typography,
  Grid,
  Card,
  CardContent,
  Box,
  Button,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { unit } from "~/helpers/util";

// 잠금 해제 등의 관리자 기능을 위해 개발 필요
const useStyles = makeStyles(theme => ({
  contentWrap: {
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: 5,
    "& *": {
      overflowWrap: "break-word",
      margin: 0,
    },
    "& .ql-align-center": {
      textAlign: "center",
      margin: 0,
    },
    "& .ql-align-right": {
      textAlign: "right",
      margin: 0,
    },
    "& .ql-align-left": {
      textAlign: "left",
      margin: 0,
    },
    "& .ql-align-justify": {
      margin: 0,
    },
    "& td": {
      border: `1px solid ${theme.palette.secondary.light}`,
    },
    "& h1": {
      display: "none !important",
    },
    "& a": {
      color: `#78a9ed`,
    },
    "& .ql-font-impact": { fontFamily: "Impact" },
    "& .ql-font-courier": { fontFamily: "Courier" },
    "& .ql-font-comic": { fontFamily: "Comic Sans MS" },
    [theme.breakpoints.down("xs")]: {
      "& img": {
        width: "100%",
        height: "auto",
      },
    },
  },
}));

const openNewWindow = id => {
  const isDev = process.env.NODE_ENV.trim() === "development";
  const prefix = isDev
    ? "http://192.168.1.219:3600/community/thread/"
    : "https://impactus.kr/community/thread/";

  window.open(`${prefix}${id}`);
};

const CommunityShow = props => {
  const record = useRecordContext();
  const classes = useStyles();

  if (!record) return null;
  return (
    <Card style={{ maxWidth: 650, margin: "auto" }}>
      <CardContent>
        <EditBase {...props}>
          <SimpleForm sx={{ pt: 0, pb: 0 }} toolbar={<CommunityToolbar />}>
            <Grid
              container
              spacing={2}
              style={{ borderBottom: "1px solid #eaeaea" }}
            >
              <Grid item xs={6}>
                <Typography
                  component="p"
                  variant="subtitle1"
                  style={{ fontWeight: 600 }}
                >
                  작성자 정보
                </Typography>
                <Typography variant="subtitle1">
                  <ReferenceField label="유저명" source="uid" reference="user">
                    <TextField source="displayName" />
                  </ReferenceField>
                </Typography>
                <Typography variant="subtitle1">
                  <ReferenceField
                    label="유저 이메일"
                    source="uid"
                    reference="user"
                  >
                    <TextField source="email" />
                  </ReferenceField>
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="subtitle1">고유번호</Typography>
                <Typography variant="subtitle1">{record.id}</Typography>
                <Button
                  color="primary"
                  size="small"
                  variant="outlined"
                  onClick={() => {
                    openNewWindow(record.id);
                  }}
                >
                  사이트에서 보기
                </Button>
              </Grid>
            </Grid>
            <Grid
              container
              spacing={2}
              style={{
                marginTop: 20,
                borderBottom: "1px solid #eaeaea",
              }}
            >
              <Grid item xs={6}>
                <BooleanInput
                  label="잠금 상태"
                  source="isLock"
                  helperText="잠김 상태가 on인 경우 검색 및 노출되지 않으며, 접근이 불가합니다."
                />
              </Grid>
              <Grid item xs={6}>
                <BooleanInput
                  label="공개 상태"
                  source="isLock"
                  helperText="공개 상태가 off인 경우 검색 및 노출되지 않으며, 접근은 가능합니다."
                />
              </Grid>
            </Grid>
            <Grid
              container
              spacing={2}
              style={{ marginTop: 20, borderBottom: "1px solid #eaeaea" }}
            >
              <Grid item xs={12}>
                <Typography
                  component="h6"
                  variant="subtitle1"
                  color="primary"
                  style={{ fontWeight: 600 }}
                >
                  <Typography
                    component="span"
                    variant="subtitle1"
                    color="primary"
                  >
                    제목:
                  </Typography>
                  {record?.title}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  component="p"
                  variant="subtitle2"
                  style={{
                    padding: 3,
                    border: "1px solid #eaeaea",
                  }}
                  gutterBottom
                >
                  내용은 사이트 커뮤니티에서 보는 것과 다르게 보일 수 있습니다.
                </Typography>
                <Box className={classes.contentWrap}>
                  {unit.renderHTML(record?.content)}
                </Box>
              </Grid>
            </Grid>
          </SimpleForm>
        </EditBase>
      </CardContent>
    </Card>
  );
};

const CommunityToolbar = () => (
  <Toolbar>
    <SaveButton label="Save" />
  </Toolbar>
);

const CommunityFilter = props => {
  const { setFilters } = useListContext();
  const resetFilter = () => {
    setFilters({}, []);
  };

  return (
    <Filter {...props}>
      <TextInput
        alwaysOn
        label="유저 이메일로 검색"
        variant="outlined"
        source="email"
      />
      <TextInput
        alwaysOn
        label="제목으로 검색"
        variant="outlined"
        source="title"
      />
      <TextInput
        alwaysOn
        label="작성자 이름으로 검색"
        variant="outlined"
        source="createdby"
      />
      <BooleanInput
        alwaysOn
        label="잠금 상태"
        variant="outlined"
        source="isLock"
      />
      <BooleanInput
        alwaysOn
        label="공개 상태"
        variant="outlined"
        source="isShow"
      />
      <Button
        alwaysOn
        color="primary"
        variant="outlined"
        onClick={() => resetFilter()}
      >
        Reset 필터
      </Button>
    </Filter>
  );
};

export const CommunityList = props => {
  return (
    <List
      {...props}
      optimized
      title="커뮤니티 관리"
      perPage={20}
      sort={{ field: "created", order: "desc" }}
      pagination={<Pagination rowsPerPageOptions={[20, 40]} />}
      filters={<CommunityFilter />}
      // filter={{ collectionQuery: c => c.where('created_gte', '<=', START_DATE).where('created_lte', '>=', END_DATE)}}
    >
      <Datagrid
        bulkActionButtons={false}
        rowClick="expand"
        expand={<CommunityShow />}
        sx={{ "& *": { textAlign: "center !important" } }}
      >
        <TextField source="id" label="고유번호" />
        <DateField source="created" showTime label="작성일자" />
        <BooleanField label="잠금상태" source="isLock" />
        <BooleanField label="공개상태" source="isShow" />
        <NumberField label="공감수" source="stat.like" />
        <NumberField label="댓글수" source="stat.reply" />
        <NumberField label="조회수" source="stat.view" />
        <ReferenceField label="작성자 닉네임" source="uid" reference="user">
          <TextField source="displayName" />
        </ReferenceField>
        <ReferenceField label="작성자 이메일" source="uid" reference="user">
          <TextField source="email" />
        </ReferenceField>
      </Datagrid>
    </List>
  );
};
