import { ThemeProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import { Admin, Resource } from "react-admin";
import {
  FirebaseDataProvider,
  FirebaseAuthProvider,
} from "react-admin-firebase";
import Layout from "~/layouts";
import SignInIndex from "~/pages/signin";
import { LecturerList, LecturerCreate, LecturerEdit } from "~/pages/lecturer";
import { ClassesList, ClassesCreate, ClassesEdit } from "~/pages/classes";
import { LectureList, LectureCreate, LectureEdit } from "~/pages/lecture";
import { NoticeList, NoticeCreate, NoticeEdit } from "~/pages/notice";
import { BoardList, BoardEdit } from "~/pages/board";
import { HelpList, HelpShow } from "~/pages/help";
import { UserList, UserEdit } from "~/pages/user";
import { MentorList, MentorEdit } from "~/pages/mentor";
import { MentoringPaymentList } from "~/pages/mentoring-payment";
import { MentoringInvoiceList } from "~/pages/mentoring-invoice";
import { MentoringReviewList } from "~/pages/mentoring-review";
import { MentoringList } from "~/pages/mentoring";
import {
  PolicyList,
  PolicyCreate,
  PolicyEdit,
  PrivacyList,
  PrivacyCreate,
  PrivacyEdit,
  LicenseList,
  LicenseCreate,
  LicenseEdit,
  PopupList,
  PopupCreate,
  PopupEdit,
} from "~/pages/policy";
import { DesignCreate, DesignEdit, DesignList } from "~/pages/design";
import { ReviewList } from "~/pages/review";
import { CouponCreate, CouponEdit, CouponList } from "~/pages/coupon";
import { AccountCreate, AccountEdit, AccountList } from "~/pages/account";
import { MentorAccountList, MentorAccountEdit } from "~/pages/mentor-account";
import { SectionCreate, SectionEdit, SectionList } from "~/pages/section";
import { PaymentList } from "~/pages/payment";
import { ProductList, ProductCreate, ProductEdit } from "~/pages/product";
import {
  PermissionList,
  PermissionCreate,
  PermissionEdit,
} from "~/pages/permission";
import {
  CommunitySectionList,
  CommunitySectionCreate,
  CommunitySectionEdit,
} from "~/pages/community-section";
import {
  CommunitySectionTitleList,
  CommunitySectionTitleCreate,
  CommunitySectionTitleEdit,
} from "~/pages/community-section-title";
import { CommunityList } from "~/pages/community";
import { FAQList, FAQEdit, FAQCreate } from "~/pages/faq";
import { TotalList, TotalEdit } from "~/pages/total";

import customKoreanMessages from "~/locales/kr";
import polyglotI18nProvider from "ra-i18n-polyglot";
import { getApps } from "firebase/app";
import { getAuth, connectAuthEmulator } from "firebase/auth";
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";
import { getStorage, connectStorageEmulator } from "firebase/storage";

// import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";

import theme from "~/styles/theme";
import EventMonitor from "./components/EventMonitor";
import { encrytion } from "~/helpers/util";
import adminStore from "~/libs/store";
import { firebaseConfig as config } from "./FIREBASE";

const dataOptions = {
  transformToDb: (resourceName, documentData, documentId) => {
    // TODO: IP 주소 등록? - https://get.geojs.io/v1/ip/geo.json
    if (resourceName === "account") {
      return {
        ...documentData,
        bankName: encrytion.encryptionText(documentData.bankName),
        bankNumber: encrytion.encryptionText(documentData.bankNumber),
      };
    }
    if (resourceName === "mentorAccount") {
      return {
        ...documentData,
        owner: encrytion.encryptionText(documentData.owner),
        bankName: encrytion.encryptionText(documentData.bankName),
        bankNumber: encrytion.encryptionText(documentData.bankNumber),
      };
    }
    if (resourceName === "lecture") {
      const duration = adminStore.getState().duration;
      adminStore.setState({ duration: 0 });
      return {
        ...documentData,
        duration,
      };
    }
    return {
      ...documentData,
    };
  },
};

const dataProvider = FirebaseDataProvider(config, dataOptions);
const authProvider = FirebaseAuthProvider(config, {});
const i18nProvider = polyglotI18nProvider(() => customKoreanMessages, "kr");

const impactusAuthProvider = {
  ...authProvider,
  login: async params => {
    const user = await authProvider.login(params);
    const claims = await authProvider.getPermissions();
    const isAdmin = claims.role < 0;

    if (isAdmin) {
      return user;
    }

    await authProvider.logout();
    throw new Error("회원 정보를 확인해주세요.");
  },
};

export const app = getApps();

if (app.length) {
  // initializeAppCheck(app, { provider: new ReCaptchaV3Provider(process.env.REACT_APP_SITE_RECAPTCHA_KEY), isTokenAutoRefreshEnabled: true });
  if (process.env.NODE_ENV === "development") {
    // const hostPrefix = "192.168.1.182"; // windows
    const hostPrefix = "192.168.1.219"; // mac
    const auth = getAuth(app[0]);
    const firestore = getFirestore(app[0]);
    const storage = getStorage(app[0]);
    connectAuthEmulator(auth, `http://${hostPrefix}:9099`);
    connectFirestoreEmulator(firestore, hostPrefix, 8030);
    connectStorageEmulator(storage, hostPrefix, 9199);
  }
}

// TODO:
// > disabled input도 black 컬러로 수정(가시성)

// FIXME:
// > Firebase local test error when updated after 9.9.4 version.
// > TODO: react-admin-firebase issue updated
const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Admin
        locale="kr"
        title="임팩터스 관리자 모드"
        dataProvider={dataProvider}
        authProvider={impactusAuthProvider}
        layout={Layout}
        loginPage={SignInIndex}
        i18nProvider={i18nProvider}
        // dashboard={Dashboard}
      >
        <Resource name={"user"} list={UserList} edit={UserEdit} />
        <Resource
          name={"lecturer"}
          list={LecturerList}
          create={LecturerCreate}
          edit={LecturerEdit}
        />
        <Resource
          name={"classes"}
          list={ClassesList}
          create={ClassesCreate}
          edit={ClassesEdit}
        />
        <Resource
          name={"lecture"}
          list={LectureList}
          create={LectureCreate}
          edit={LectureEdit}
        />
        <Resource name={"board"} list={BoardList} edit={BoardEdit} />
        <Resource
          name={"notice"}
          list={NoticeList}
          create={NoticeCreate}
          edit={NoticeEdit}
        />
        <Resource name={"help"} list={HelpList} edit={HelpShow} />
        <Resource
          name={"policy"}
          list={PolicyList}
          create={PolicyCreate}
          edit={PolicyEdit}
        />
        <Resource
          name={"privacy"}
          list={PrivacyList}
          create={PrivacyCreate}
          edit={PrivacyEdit}
        />
        <Resource
          name={"license"}
          list={LicenseList}
          create={LicenseCreate}
          edit={LicenseEdit}
        />
        <Resource
          name={"popup"}
          list={PopupList}
          create={PopupCreate}
          edit={PopupEdit}
        />
        <Resource
          name={"design"}
          list={DesignList}
          create={DesignCreate}
          edit={DesignEdit}
        />
        <Resource name={"review"} list={ReviewList} />
        <Resource
          name={"coupon"}
          list={CouponList}
          create={CouponCreate}
          edit={CouponEdit}
        />
        <Resource
          name={"account"}
          list={AccountList}
          create={AccountCreate}
          edit={AccountEdit}
        />
        <Resource name={"mentor"} list={MentorList} edit={MentorEdit} />
        <Resource
          name={"mentorAccount"}
          list={MentorAccountList}
          edit={MentorAccountEdit}
        />
        <Resource name={"mentoring"} list={MentoringList} />
        <Resource name={"mentoringPayment"} list={MentoringPaymentList} />
        <Resource name={"mentoringRoom"} />
        <Resource name={"mentoringReview"} list={MentoringReviewList} />
        <Resource name={"rating"} />
        <Resource name={"mentoringInvoice"} list={MentoringInvoiceList} />
        <Resource name={"classInvoice"} />
        <Resource
          name={"product"}
          list={ProductList}
          create={ProductCreate}
          edit={ProductEdit}
        />
        <Resource
          name={"faq"}
          list={FAQList}
          create={FAQCreate}
          edit={FAQEdit}
        />
        <Resource name={"payment"} list={PaymentList} />
        <Resource
          name={"section"}
          list={SectionList}
          create={SectionCreate}
          edit={SectionEdit}
        />
        <Resource
          name={"permission"}
          list={PermissionList}
          create={PermissionCreate}
          edit={PermissionEdit}
        />
        <Resource name={"community"} list={CommunityList} />
        <Resource
          name={"communitySectionTitle"}
          list={CommunitySectionTitleList}
          create={CommunitySectionTitleCreate}
          edit={CommunitySectionTitleEdit}
        />
        <Resource
          name={"communitySection"}
          list={CommunitySectionList}
          create={CommunitySectionCreate}
          edit={CommunitySectionEdit}
        />
        <Resource name={"total"} list={TotalList} edit={TotalEdit} />
        <Resource name={"live"} />
        <Resource name={"order"} />
        <Resource name={"orderReview"} />
      </Admin>
      <EventMonitor />
    </ThemeProvider>
  );
};

export default App;
