import React from "react";
import CryptoJS from "crypto-js";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

export const swal = withReactContent(Swal); // Alert

export const time = {
  transform: (militime) => {
    const regdate = new Date(militime);
    const ret = `${regdate.getFullYear()}-${regdate.getMonth() + 1}-${
      parseInt(regdate.getDate()) < 10
        ? "0" + regdate.getDate()
        : regdate.getDate()
    }`;
    return ret;
  },
  transformKR: (militime) => {
    const regdate = new Date(militime);
    const ret = `${regdate.toLocaleDateString(
      "ko-KR"
    )} ${regdate.toLocaleTimeString("ko-KR")}`;
    return ret;
  },
  transformAge: (fullBirthDay) => {
    const years =
      new Date().getFullYear() - new Date(fullBirthDay).getFullYear() + 1;
    if (years >= 10 && years < 20) {
      return "10~19";
    } else if (years >= 20 && years < 30) {
      return "20~29";
    } else if (years >= 30 && years < 40) {
      return "30~39";
    } else if (years >= 40 && years < 50) {
      return "40~49";
    } else if (years >= 50 && years < 60) {
      return "50~59";
    } else if (years > 60) {
      return "60~";
    } else {
      return "~10";
    }
  },
  transformBirthDay: (fullBirthDay) => {
    const birthDay = fullBirthDay.split("-");
    return `${birthDay[1]}/${birthDay[2]}`;
  },
};

export const unit = {
  addComma: (x) => {
    const ret = Math.round(x);
    return ret.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  },
  cutString: (x) => {
    const length = 20;
    let ret = x;
    if (x.length >= length) ret = `${x.substr(0, length)}...`;
    return ret;
  },
  loadScriptByURL: (id, url, callback) => {
    const isScriptExist = document.getElementById(id);
    if (!isScriptExist) {
      var script = document.createElement("script");
      script.type = "text/javascript";
      script.src = url;
      script.id = id;
      script.onload = function () {
        if (callback) callback();
      };
      document.body.appendChild(script);
    }
    if (isScriptExist && callback) callback();
  },
  isIosCheck: () => {
    return /iPhone|iPod|iPad/.test(navigator.platform);
  },
  renderHTML: (rawHTML) => {
    return React.createElement("div", {
      dangerouslySetInnerHTML: { __html: rawHTML },
    });
  },
  chagnePWLength: (length) => {
    let ret = "";
    for (let i = 0; i < length; ++i) {
      ret += "*";
    }
    return ret;
  },
  openNewWindow: (url) => {
    window.open(url);
  },
  changeNumberUnit: (x) => {
    if (x < 1000) {
      return `${x}`;
    } else if (x > 1000 && x < 10000) {
      return `+1K`;
    } else if (x > 10000 && x < 100000) {
      return `+10K`;
    } else if (x > 100000 && x < 1000000) {
      return `+100K`;
    } else if (x > 1000000 && x < 10000000) {
      return `+1M`;
    } else if (x > 1000000 && x < 10000000) {
      return `+10M`;
    } else if (x > 10000000 && x < 100000000) {
      return `+100M`;
    } else if (x > 100000000 && x < 1000000000) {
      return `+1B`;
    }
  },
  changeSizeUnit: (x) => {
    const ret = x / 1024 / 1024; // MB
    return `${unit.addComma(ret)}MB`;
  },
  readableBytes: (bytes) => {
    const i = Math.floor(Math.log(bytes) / Math.log(1024)),
      sizes = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    return (bytes / Math.pow(1024, i)).toFixed(2) + " " + sizes[i];
  },
  priceCutting: (aprice, stype = "F", n = 100) => {
    /**NOTE:
     * @param 금액, 타입(원 단위 처리(R:반올림, C:올림, F:버림)), 절삭금액 단위
     */
    let remove_price = 0;
    stype = stype ? stype : "R";
    remove_price = aprice / n;

    if (stype == "F") {
      remove_price = Math.floor(remove_price);
    } else if (stype == "R") {
      remove_price = Math.round(remove_price);
    } else if (stype == "C") {
      remove_price = Math.ceil(remove_price);
    }
    remove_price = remove_price * n;
    return remove_price;
  },
  percentUnit: (percent, value) => {
    /**NOTE:
     * @param 퍼센트, 값
     * @return value의 percent에 해당하는 값을 반환
     *
     * @ex percentUnit(30, 1000) = 300
     */
    return (percent / 100) * value;
  },
};

export const validation = {
  emailValidation: (email) => {
    const emailRule =
      /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i;
    return emailRule.test(email);
  },
  passwordValidation: (password) => {
    const passwordRule = /^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{6,20}$/;
    const numberRule = /[0-9]/i;
    const formatRule = /[a-z]/i;

    if (!passwordRule.test(password)) {
      return "숫자+영문자+특수문자 6~20자리 조합으로 사용해야 합니다.";
    }
    if (!numberRule.test(password) || !formatRule.test(password)) {
      return "숫자와 영문자를 혼용하여야 합니다.";
    }

    return "ok";
  },
};

export const runWithCatch = (block) => {
  return async () => {
    try {
      await block();
    } catch (e) {
      console.error("Caught: " + e.message || e);
    }
  };
};

export const makeIndex = {
  createIndexes: (x) => {
    const arrName = [];
    let curName = "";
    x.split("").forEach((letter) => {
      curName += letter;
      arrName.push(curName);
    });
    return arrName;
  },
  generateIndexes: (x) => {
    const { first, middle, last, sfx } = x;
    const suffix = sfx.length > 0 ? ` ${sfx}.` : "";
    const keywordNameWidthoutMiddleName = makeIndex.createIndexes(
      `${first} ${last}${suffix}`
    );
    const keywordFullName = makeIndex.createIndexes(
      `${first} ${middle} ${last}${suffix}`
    );
    const keywordLastNameFirst = makeIndex.createIndexes(
      `${last}, ${first} ${middle}${suffix}`
    );

    const middleInitial = middle.length > 0 ? ` ${middle[0]}.` : "";
    const keywordFullNameMiddleInitial = makeIndex.createIndexes(
      `${first}${middleInitial} ${last}${suffix}`
    );
    const keywordLastNameFirstMiddleInitial = makeIndex.createIndexes(
      `${last}, ${first}${middleInitial}${suffix}`
    );
    return [
      ...new Set([
        "",
        ...keywordFullName,
        ...keywordLastNameFirst,
        ...keywordFullNameMiddleInitial,
        ...keywordLastNameFirstMiddleInitial,
        ...keywordNameWidthoutMiddleName,
      ]),
    ];
  },
};

export const encrytion = {
  encryptionText: (value) => {
    const ciphertext = CryptoJS.AES.encrypt(
      value,
      process.env.REACT_APP_SECRET_KEY
    ).toString();
    return ciphertext;
  },
  decryptionText: (value) => {
    const bytes = CryptoJS.AES.decrypt(value, process.env.REACT_APP_SECRET_KEY);
    const originalText = bytes.toString(CryptoJS.enc.Utf8);
    return originalText;
  },
};
