import { Layout, Sidebar } from "react-admin";
import Header from "~/layouts/Header";
import Menu from "~/layouts/Menu";

const CustomSidebar = (props) => <Sidebar {...props} size={200} />;

export default (props) => {
  return (
    <Layout {...props} appBar={Header} sidebar={CustomSidebar} menu={Menu} />
  );
};
