import { makeStyles } from "@material-ui/core/styles";
import {
  List,
  SimpleForm,
  TextInput,
  required,
  Datagrid,
  DateField,
  TextField,
  Pagination,
  Filter,
  useInput,
  Edit,
  EditButton,
  useNotify,
  useRefresh,
  useRedirect,
  useEditController,
  SaveButton,
  Toolbar,
} from "react-admin";
import {
  Typography,
  Grid,
  TextField as MuiTextField,
  Card,
  CardContent,
} from "@material-ui/core";
import { encrytion } from "~/helpers/util";
import useEffectOnce from "react-use/lib/useEffectOnce";

// TODO:
// > 보안을 위해서 강연자 은행명, 계좌번호는 별도 DB에 암호화해서 저장하도록 구현함
// > 은행 계좌정보 조회 API 구현 필요 (ref. https://ws-pace.tistory.com/231.)
// > 은행 계좌자동이체 API 구현 필요

const useStyles = makeStyles((theme) => ({
  wrapper: {
    position: "relative",
    width: "100%",
    "&>div": {
      padding: theme.spacing(2),
    },
  },
  none: {
    display: "none",
    visibility: "hidden",
    width: 0,
    height: 0,
  },
  input: {
    "& *": {
      marginTop: theme.spacing(0.5),
      padding: "12.5px !important",
    },
  },
}));

const DecryptTextField = (props) => {
  const { onChange, onBlur, ...rest } = props;
  const {
    field,
    fieldState: { isTouched, error },
    formState: { isSubmitted },
  } = useInput({
    onChange,
    onBlur,
    ...props,
  });

  useEffectOnce(() => {
    setTimeout(() => {
      field.onChange(encrytion.decryptionText(field.value));
    }, 500);
  });

  return (
    <MuiTextField
      {...field}
      {...rest}
      label={props.label}
      error={isTouched || isSubmitted}
      helperText={isTouched || isSubmitted ? error : ""}
      required={true}
      fullWidth
    />
  );
};

export const MentorAccountEdit = (props) => {
  const classes = useStyles();
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();

  const controllerProps = useEditController(props);
  const { record, save, isLoading } = controllerProps;

  const onSuccess = (data) => {
    notify(`저장 완료`);
    redirect("/mentorAccount");
    refresh();
  };

  return (
    <Edit
      title="멘토 계좌정보 수정"
      {...props}
      mutationOptions={{ onSuccess }}
    >
      <SimpleForm
        redirect="list"
        record={record}
        onSubmit={save}
        // warnWhenUnsavedChanges
      >
        <Card>
          <CardContent>
            <Grid container className={classes.wrapper}>
              <Grid item xs={12} sm={12}>
                <Typography component="span" variant="caption">
                  *계좌정보가 없거나 유효하지 않은 계좌인 경우 정상적으로
                  지급처리 되지 않습니다.
                  <br />
                  *보안에 각별히 신경써주세요.
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextInput
                  fullWidth
                  variant="standard"
                  source="email"
                  label="이메일"
                  validate={[required()]}
                />
              </Grid>
              <Grid item xs={12} sm={8}>
                <TextInput
                  fullWidth
                  variant="standard"
                  source="addr1"
                  label="기본주소"
                  validate={[required()]}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextInput
                  fullWidth
                  variant="standard"
                  source="addr2"
                  label="상세주소"
                  validate={[required()]}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <DecryptTextField
                  id="owner"
                  source="owner"
                  label="예금주"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <DecryptTextField
                  id="bankName"
                  source="bankName"
                  label="은행명"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <DecryptTextField
                  id="bankNumber"
                  source="bankNumber"
                  label="계좌번호"
                  variant="outlined"
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </SimpleForm>
    </Edit>
  );
};

export const AccountToolbar = () => (
  <Toolbar>
    <SaveButton label="Save" />
  </Toolbar>
);

const AccountFilter = (props) => {
  return (
    <Filter {...props}>
      <TextInput
        alwaysOn
        variant="outlined"
        source="email"
        label="메일로 검색"
      />
      <TextInput
        alwaysOn
        variant="outlined"
        source="phone"
        label="연락처로 검색"
      />
    </Filter>
  );
};

export const MentorAccountList = (props) => {
  return (
    <List
      {...props}
      title="멘토 계좌정보 관리"
      perPage={20}
      sort={{ field: "created", order: "DESC" }}
      pagination={<Pagination rowsPerPageOptions={[20, 40]} />}
      filters={<AccountFilter />}
    >
      <Datagrid
        optimized
        bulkActionButtons={false}
        rowClick="expand"
        sx={{ "& *": { textAlign: "center !important" } }}
        // expand={<MentorAccountEdit />}
      >
        <DateField source="created" label="등록시간" showTime />
        <TextField label="이메일" variant="outlined" source="email" />
        <TextField label="연락처" variant="outlined" source="phone" />
        <EditButton basePath={"/mentorAccount"} label="수정" />
      </Datagrid>
    </List>
  );
};
